/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import arrayUtils from './arrayUtils.js';
import filterObjectManipulator from './filterObjectManipulator.js';


/** @typedef {object} FilterObject */

/**
 * Container for filters. It is verified by schema #filters. It is send to
 * the server as-is, thus it should at all times be a plain old JS object.
 *
 * @typedef {object} FilterContainer
 * @property {FilterObject[]} filters - an array of filters
 */

const filterContainerManipulator = {};

/**
 * Creates an empty filterContainer object.
 *
 * @memberof filterContainerManipulator
 * @returns {FilterContainer} a new filterContainer
 */
filterContainerManipulator.createNewFilterContainer = function() {
    return {
        filters: []
    };
};

/**
 * Copies filter to another container.
 *
 * @memberof filterContainerManipulator
 * @param {FilterObject} filterObject
 * @param {FilterContainer} toFilterContainer
 */
filterContainerManipulator.copyFilterToAnotherFilterContainer = function(
    filterObject, toFilterContainer
) {
    toFilterContainer.filters.push(JSON.parse(JSON.stringify(
        filterObject
    )));
};

/**
 * Appends all filterObjects from a filterContainer or an array thereof to a
 * filterContainer
 *
 * @memberof filterContainerManipulator
 * @private
 * @param {FilterContainer} resultContainer
 * @param {FilterContainer|FilterContainer[]|FilterContainer[][]} temp
 */
const appendFilterContainer = function(resultContainer, temp) {
    if (!temp) {
        return;
    }
    if (Array.isArray(temp)) {
        temp.forEach(function(temp) {
            appendFilterContainer(resultContainer, temp);
        });
    } else {
        temp.filters.forEach(function(filterObject) {
            if (!filterObject) {
                return;
            }
            resultContainer.filters.push(filterObject);
        });
    }
};

/**
 * Concatenates type of this filter to type of given column.
 *
 * Parameters are filterContainers or an n-dimensional array of filterContainers (e.g. an
 * array of arrays of arrays of filterContainers)
 *
 * @memberof filterContainerManipulator
 * @returns {JSON} filters
 */
filterContainerManipulator.concatenateFilterContainers = (...filterObjects) => {
    const result = filterContainerManipulator.createNewFilterContainer();
    for (const filterObject of filterObjects) {
        appendFilterContainer(result, filterObject);
    }
    return result;
};

const identity = item => item;

/**
 * Returns parameters for report generation by Reporter.
 * The result should be valid according to #filters-serialized.
 *
 * @memberof filterObjectManipulator
 * @param customFilterStorage
 * @param reporterTemplates
 * @param filterContainer
 * @returns {JSON[]} array of objects with parameters
 */
filterContainerManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, filterContainer
) {
    const result = filterContainer.filters.map(function(filterObject) {
        return filterObjectManipulator.getReporterParams(
            customFilterStorage,
            reporterTemplates,
            filterContainerManipulator,
            filterObject
        );
    }).filter(identity);
    return result.length ? result : undefined;
};

/**
 * Adds filter object to given filter container.
 *
 * @memberof filterContainerManipulator
 * @param {FilterContainer} filterContainer
 * @param {FilterObject} filterObject
 */
filterContainerManipulator.addFilterObject = function(filterContainer, filterObject) {
    filterContainer.filters.push(filterObject);
};

/**
 * Merges filter object to given filter container. The current
 * implementation is equal to filterContainerManipulator.addFilterObject(),
 * but it can be desired to change it so the filter is merged with already
 * existing filter object in the container.
 *
 * @memberof filterContainerManipulator
 * @param {FilterContainer} filterContainer
 * @param {FilterObject} filterObject
 */
filterContainerManipulator.mergeFilterObject = function(filterContainer, filterObject) {
    filterContainer.filters.push(JSON.parse(JSON.stringify(filterObject)));
};

/**
 * Removes a single filterObject.
 *
 * @memberof filterContainerManipulator
 * @param {FilterContainer} filterContainer
 * @param {FilterObject} filterObject
 */
filterContainerManipulator.removeFilterObject = function(filterContainer, filterObject) {
    arrayUtils.removeItem(filterContainer.filters, filterObject);
};

/**
 * Removes all filters.
 *
 * @memberof filterContainerManipulator
 * @param {FilterContainer} filterContainer
 */
filterContainerManipulator.removeAllFilterObjects = function(filterContainer) {
    filterContainer.filters.splice(0);
};

/**
 * Sets the filter column to a different value. Currently it replaces the
 * filter with a new one.
 *
 * @memberof filterContainerManipulator
 * @param reporterTemplates
 * @param {FilterContainer} filterContainer
 * @param {FilterObject} filterObject
 * @param {string} columnName
 */
filterContainerManipulator.setFilterObjectColumn =
    function(reporterTemplates, filterContainer, filterObject, columnName) {
        arrayUtils.replaceItem(filterContainer.filters, filterObject,
            filterObjectManipulator.createNewFilterObject(
                reporterTemplates, columnName
            ));
    };

/**
 * Makes the filterObjects' value configurable for filterObjects whose
 * editors would not be too big.
 *
 * @memberof filterContainerManipulator
 * @param {FilterContainer} filterContainer
 */
filterContainerManipulator.expandAllFiltersIfSmall = function(filterContainer) {
    filterContainer.filters.forEach(function(filterObject) {
        filterObjectManipulator.expandFilterIfSmall(filterObject);
    });
};

/**
 * Fixes the filterContainer upon loading.
 *
 * @memberof filterContainerManipulator
 * @param reporterTemplates
 * @param {FilterContainer} filterContainer
 */
filterContainerManipulator.fix = function(reporterTemplates, filterContainer) {
    filterContainer.filters.forEach(function(filterObject) {
        filterObjectManipulator.fix(reporterTemplates, filterObject);
    });
};

export default filterContainerManipulator;
