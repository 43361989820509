/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';

import { call, put, select, takeEvery, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';

import { PAGE_SIZE_OPTIONS } from '../../constants/index.js';
import { getApiError } from '../../lib/apiUtils.ts';
import { cfgActivationClose } from '../cfgActivation/index.js';


// actions
export const GET_CFG_REVISION_LIST_REQUEST = 'ak/cfgRevisionList/GET_CFG_REVISION_LIST_REQUEST';
const GET_CFG_REVISION_LIST_SUCCESS = 'ak/cfgRevisionList/GET_CFG_REVISION_LIST_SUCCESS';
const GET_CFG_REVISION_LIST_FAILURE = 'ak/cfgRevisionList/GET_CFG_REVISION_LIST_FAILURE';
const SET_CURRENT_PAGE = 'ak/cfgRevisionList/SET_CURRENT_PAGE';
const SET_ROWS_PER_PAGE = 'ak/cfgRevisionList/SET_ROWS_PER_PAGE';


// initial state
export const initialState = {
    data: null,
    error: null,
    isLoading: false,
    currentPage: 0,
    rowsPerPage: Number(PAGE_SIZE_OPTIONS[0]),
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_CFG_REVISION_LIST_REQUEST:
        return { ...state, isLoading: true, error: null };
    case GET_CFG_REVISION_LIST_SUCCESS:
        return { ...state, isLoading: false, data: action.payload };
    case GET_CFG_REVISION_LIST_FAILURE:
        return { ...state, isLoading: false, error: action.payload };
    case SET_CURRENT_PAGE:
        return { ...state, isLoading: true, currentPage: action.currentPage };
    case SET_ROWS_PER_PAGE:
        return { ...state, isLoading: true,
            rowsPerPage: action.rowsPerPage,
            currentPage: 0
        };
    default:
        return state;
    }
};


// data accessors
const getState = rootState => rootState.cfgRevisionList;

export const getIsLoading = rootState => getState(rootState).isLoading;

export const getError = rootState => getState(rootState).error;

export const getData = rootState => getState(rootState).data;

export const getRows = rootState => {
    const data = getData(rootState);
    return (data && data.rows) || EMPTY_IMMUTABLE_ARR;
};

export const getLength = rootState => {
    const data = getData(rootState);
    return data && data.length;
};

export const getCurrentPage = rootState => getState(rootState).currentPage;

export const getRowsPerPage = rootState => getState(rootState).rowsPerPage;


// action creators
export const cfgRevisionListRequest = () =>
    ({ type: GET_CFG_REVISION_LIST_REQUEST });

const cfgRevisionListSuccess = (data) =>
    ({ type: GET_CFG_REVISION_LIST_SUCCESS, payload: data });

const cfgRevisionListFailure = (error) =>
    ({ type: GET_CFG_REVISION_LIST_FAILURE, payload: error });

export const setCurrentPage = (currentPage) =>
    ({ type: SET_CURRENT_PAGE, currentPage });

export const setRowsPerPage  = (rowsPerPage) =>
    ({ type: SET_ROWS_PER_PAGE, rowsPerPage });

// API endpoints
const httpGetCfgRevisionList = async (currentPage, itemsPerPage) =>
    Axios.get(
        '/api/cfg/revisionLog',
        {
            params: {
                limit: itemsPerPage,
                offset: currentPage * itemsPerPage,
            },
        }
    );


export default reducer;


// side effects
const workerGetList = function* () {
    try {
        const currentPage = yield select(getCurrentPage);
        const itemsPerPage = yield select(getRowsPerPage);
        const { data } = yield call(httpGetCfgRevisionList, currentPage, itemsPerPage);
        yield put(cfgRevisionListSuccess(data));
    } catch (error) {
        yield put(cfgRevisionListFailure(getApiError(error)));
    }
};

const workerReloadList = function* () {
    const firstGlcfgTree = yield select(getData);
    if (!firstGlcfgTree) {
        return;
    }
    yield* workerGetList();
};

export const sagas = [
    takeEvery(GET_CFG_REVISION_LIST_REQUEST, workerGetList),
    takeLatest(SET_CURRENT_PAGE, workerGetList),
    takeLatest(SET_ROWS_PER_PAGE, workerGetList),
    takeEvery(cfgActivationClose.type, workerReloadList),
];
