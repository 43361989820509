/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';

import moment, { TIME_FORMAT } from '~commonLib/moment.ts';

import Highcharts from '../Highcharts.js';
import cwCategories from './cwCategories.js';
import tFilter from './tFilter.js';
import valueFormatter from './valueFormatter.js';


const tempColValue = function(reporterTemplates, value, type, options, extraData) {
    const column = (typeof type === 'object' && type.type ? type : null) ||
        (getValue(reporterTemplates, 'columns.byName') || {})[type];
    if (!column) {
        throw new Error('Column  ' + column + ' not found:');
    }
    if (value === null) {
        return tFilter('report:chart.value.others');
    }
    if (value === '<NULL>' && column.nullval) {
        return column.nullval;
    }
    if (column.variant === 'ratio') {
        const num = Number(value);
        return isNaN(num) ?
            value :
            valueFormatter.formatPercent(100 * num);
    }
    switch (column.type) {
    case 'percent': {
        const number = Number(value);
        return isNaN(number) ?
            value :
            valueFormatter.formatPercent(number);
    }
    case 'time_minute':
        return moment(value).format('ll HH:mm');
    case 'time_hour': return moment(value).format('ll HH:00 – HH:59');
    case 'time_day': return moment(value).format('ll');
    case 'time_week': {
        const from = moment(value).startOf('week');
        const to = moment(value).endOf('week');
        if (from.format('YYYY') === to.format('YYYY')) {
            if (from.format('MM') === to.format('MM')) {
                return from.format('DD.') + ' – ' + to.format('DD. MMM YYYY');
            }
            return from.format('DD. MMM') + ' – ' + to.format('DD. MMM YYYY');
        }
        return from.format('ll') + ' – ' + to.format('ll');
    }
    case 'time_month': return moment(value).format('MMM YYYY');
    case 'time_year': return moment(value).format('YYYY');
    case 'hour_of_day':
        if (options && options.inFilter) {
            return value;
        }
        return value + ':00 – ' + value + ':59';
    case 'day_of_month': return value + '.';
    case 'day_of_week': return moment.weekdays()[value];
    case 'double': return Number(value);
    case 'count_bytes':
        return valueFormatter.formatBytes(value, 1);
    case 'count_float':
        return valueFormatter.formatCount(value, 3);
    case 'count_seconds':
        return valueFormatter.formatSeconds(value);
    case 'duration_ms':  //TODO: Change reporter to have seconds instead
    case 'count':
    case 'sum':
        if (options && options.isShort) {
            return valueFormatter.formatCount(value, options && options.precision);
        }
        // used for big numbers to be displayd with spaces (eg. 1 000 000)
        return Highcharts.numberFormat(value, 0);
    case 'kcw_category':
        return cwCategories.getTitle(value);
    case 'kcw_categories':
        return value.split(',').map(cwCategories.getTitle).join(', ');
    case 'accepted_rejected':
    case 'boolean':
    case 'event_result':
    case 'network_protocol':
    case 'http_proxy_action':
    case 'http_status_code_class':
    case 'smtp_at_proxy_action':
    case 'smtp_proxy_action':
    case 'titles':
    case 'category':
        try {
            switch (value) {
            case 'allowed': return tFilter('reporter:values.alert_actions.allowed');
            case 'blocked': return tFilter('reporter:values.alert_actions.blocked');
            case 'allowedNft': return tFilter('reporter:values.alert_actions.allowedNft');
            case 'blockedNft': return tFilter('reporter:values.alert_actions.blockedNft');
            case 'allowedProxy': return tFilter('reporter:values.alert_actions.allowedProxy');
            case 'blockedProxy': return tFilter('reporter:values.alert_actions.blockedProxy');
            case 'blockedIps': return tFilter('reporter:values.alert_actions.blockedIps');
            case 'allowedIps': return tFilter('reporter:values.alert_actions.allowedIps');
            case 'blockedAf': return tFilter('reporter:values.alert_actions.blockedAf');
            case 'allowedAf': return tFilter('reporter:values.alert_actions.allowedAf');
            case 'alertedAf': return tFilter('reporter:values.alert_actions.alertedAf');
            case 'alertedIps': return tFilter('reporter:values.alert_actions.alertedIps');
            case 'alerted': return tFilter('reporter:values.alert_actions.alerted');
            case 'cpu_busy_pct': return tFilter('reporter:values.cpu_busy_pct');
            case 'used_memory_pct': return tFilter('reporter:values.used_memory_pct');
            case 'used_swap_pct': return tFilter('reporter:values.used_swap_pct');
            case 'disk_used_pct': return tFilter('reporter:values.disk_used_pct');
            case 'cpu_idle_pct': return tFilter('reporter:values.cpu_idle_pct');
            case 'used_memory': return tFilter('reporter:values.used_memory');
            case 'tot_memory': return tFilter('reporter:values.tot_memory');
            case 'used_swap': return tFilter('reporter:values.used_swap');
            case 'tot_swap': return tFilter('reporter:values.tot_swap');
            case 'disk_reads': return tFilter('reporter:values.disk_reads');
            case 'disk_writes': return tFilter('reporter:values.disk_writes');
            case 'threads': return tFilter('reporter:values.threads');
            case 'proc': return tFilter('reporter:values.proc');
            case 'load5': return tFilter('reporter:values.load5');
            case 'disk_mem': return tFilter('reporter:values.disk_mem');
            case 'bytes_in': return tFilter('reporter:values.bytes_in');
            case 'bytes_out': return tFilter('reporter:values.bytes_out');
            case 'iface': return tFilter('reporter:values.iface');
            case 'unspecified': return tFilter('reporter:values.unspecified');
            default: return value;
            }
        } catch (error) {
            return value;
        }
    case 'virus_status':
        return tFilter(
            'reporter:values.' + column.type + '.' + value,
            {},
            value || tFilter('reporter:none-selected')
        );
    case 'alert_severity':
        try {
            switch (value) {
            case '["Major"]':
                return tFilter('reporter:values.alert_severity.major');
            case '["Minor"]':
                return tFilter('reporter:values.alert_severity.minor');
            case '["Informational"]':
                return tFilter('reporter:values.alert_severity.informational');
            case '["Audit"]':
                return tFilter('reporter:values.alert_severity.audit');
            case '["Critical"]':
                return tFilter('reporter:values.alert_severity.critical');
            case '<NULL>':
                return tFilter('reporter:values.alert_severity.null');
            default:
                return value;
            }
        } catch (error) {
            return value;
        }
    case 'iface_name':
        return extraData['interfaces'][value] || value;
    case 'iface_name_with_direction': {
        const iface = value.split('-');
        return tFilter(
            `reporter:iface.withDirection.${iface[1]}`,
            { iface: extraData['interfaces'][iface[0]] || value }
        );
    }
    case 'trendPeriod':
        switch (value) {
        case 'older':
            return tFilter('report:Tooltip.trendChart.period.older.title');
        case 'newer':
            return tFilter('report:Tooltip.trendChart.period.newer.title');
        default:
            return value;
        }
    // no default
    }


    if ('time' in column.types) {
        return moment(value).format(TIME_FORMAT.userDateTimeShort);
    }
    if ('bytes' in column.types) {
        return valueFormatter.formatBytes(value, options && options.precision);
    }
    if ('duration' in column.types) {
        return valueFormatter.formatSeconds(value);
    }
    return value;
};

const colValue = (reporterTemplates, value, type, options, extraData) =>
    tempColValue(reporterTemplates, value, type, options, extraData);

export default colValue;
