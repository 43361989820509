/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Service for manipulation with chart configuration objects.
 *
 * @name chartManipulator
 */
const chartManipulator = {};

chartManipulator.createNewChartObject = function(chartType) {
    return {
        config: {
            type: chartType
        }
    };
};

chartManipulator.createNewChartContainer = function(chartType) {
    return [
        chartManipulator.createNewChartObject(chartType)
    ];
};

chartManipulator.createNewChartContainerForReportDefinition = function() {
    return chartManipulator.createNewChartContainer('table');
};

chartManipulator.createNewChartContainerForReportUsage = function() {
    return chartManipulator.createNewChartContainer(null);
};

chartManipulator.getChartObject = function(
    chartContainerReportDefinition, chartContainerReportUsage
) {
    return chartContainerReportUsage[0].config.type ?
        chartContainerReportUsage[0].config :
        chartContainerReportDefinition[0].config;
};

chartManipulator.getChartType = function(
    chartContainerReportDefinition, chartContainerReportUsage
) {
    return chartManipulator.getChartObject(
        chartContainerReportDefinition,
        chartContainerReportUsage
    ).type;
};

chartManipulator.copyChartContainer = function(
    chartContainerReportDefinition, chartContainerReportUsage
) {
    return JSON.parse(JSON.stringify(
        chartContainerReportUsage &&
        chartContainerReportUsage[0].config.type ?
            chartContainerReportUsage :
            chartContainerReportDefinition
    ));
};

export default chartManipulator;
