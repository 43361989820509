/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard,  MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import axios from 'axios';
import { store } from 'react-notifications-component';

import { promiseSetTimeout } from '~frontendLib/timeUtils.ts';
import BackgroundSvg from '~frontendComponents/BackgroundSvg/index.ts';
import Logo from '~frontendComponents/Logo/index.js';
import ShuttingDownLogo from '~frontendRoot/img/svg/shuttingDownLogo.svg';
import SvgInliner from '~frontendComponents/Generic/SvgInliner/index.ts';
import tFilter from '~sharedLib/reporterLibrary/tFilter.js';

import Message from '../components/Message/index.js';


/** Returns the element that wraps the entire frontend application. */
export const getReactWrapper = () =>
    document.getElementById('react-wrapper');

/** Returns the element that wraps the FE content. */
export const getReactContentWrapper = () =>
    document.getElementById('selectPortalDestination');

/**
 * Replaces the entire frontend application irreversibly with given React element.
 *
 * @param {React.Component} rootElement
 */
const replaceFrontendWithComponent = rootElement => {
    ReactDOM.render(rootElement, getReactWrapper());
};

let registeredInstanceI18n;

export const registerInstanceI18n = instanceI18n => {
    registeredInstanceI18n = instanceI18n;
};

export const getInstanceI18n = () =>
    registeredInstanceI18n;


const Description = ({ newFrontendAddresses }) => (
    <>
        <p>
            <Message message="navigation:frontendReplaced.disconnected.desc1" />
        </p>
        <p>
            <Message message="navigation:frontendReplaced.disconnected.desc2" />
        </p>
        <p>
            <Message message="navigation:frontendReplaced.disconnected.desc3" />
            {newFrontendAddresses.map((address, iAddress) => (
                <React.Fragment key={iAddress}>
                    {iAddress ?
                        iAddress === newFrontendAddresses.length - 1 ?
                            <Message message="navigation:frontendReplaced.disconnected.descOr" /> :
                            <Message message="navigation:frontendReplaced.disconnected.descComma" /> :
                        null
                    }
                    <a href={address}>
                        <b>{address}</b>
                    </a>
                </React.Fragment>
            ))}
            <Message message="navigation:frontendReplaced.disconnected.descDot" />
        </p>
    </>
);

Description.propTypes = {
    newFrontendAddresses: PropTypes.array.isRequired,
};

const MAXIMUM_IS_UP_INTERVAL_SECONDS = 5;
export const frontendReplace = async (messages, shouldReload) => {
    replaceFrontendWithUpgradeMessage(messages);
    document.body.classList.remove('loggedIn');
    let timeoutPrev = 0;
    let timeoutCurr = 1;
    let wasDead = false;
    while (shouldReload) {
        await promiseSetTimeout({ waitTime: timeoutCurr * 1000 });
        if (timeoutCurr < MAXIMUM_IS_UP_INTERVAL_SECONDS) {
            const timeoutNext = Math.min(timeoutPrev + timeoutCurr, MAXIMUM_IS_UP_INTERVAL_SECONDS);
            timeoutPrev = timeoutCurr;
            timeoutCurr = timeoutNext;
        }
        try {
            const res = await axios.get(`${window.location.origin}/are-you-alive`);
            if (res.status === 200 && wasDead) {
                location.reload();
                return;
            }
        } catch (error) {
            wasDead = true;
            continue;
        }
    }
};

export const replaceFrontendWithUpgradeMessage = ({ first, second, third, shuttingDown = false,
    reload = false, addrs }) =>
    replaceFrontendWithComponent(
        <I18nextProvider i18n={registeredInstanceI18n}>
            <>
                <div className="page page--replaceFE loginPage">
                    <main className={'loginPage__main'}>
                        <MDBRow className="upgradeChange__row">
                            <Logo isLogin />
                            <MDBCol className="mb-2">
                                <MDBCard  className="upgradeChange__window">
                                    <h2>
                                        <Message {...first}  />
                                    </h2>
                                    <p>
                                        <Message {...second}  />
                                    </p>
                                    {shuttingDown ?
                                        <SvgInliner
                                            className="logo__svg"
                                            src={ShuttingDownLogo}
                                        /> :
                                        <>
                                            <h2 />
                                            {addrs ?
                                                <div>
                                                    {addrs.map(item => (
                                                        <a
                                                            className={'possibleAddrs'}
                                                            href={`https://${item}`}
                                                            key={item}
                                                        >{item}
                                                        </a>
                                                    ))}
                                                </div> :
                                                <p><Message {...third} /> </p>

                                            }


                                            {reload &&
                                            <MDBBtn
                                                color="primary"
                                                onClick={() => location.reload()}
                                            >
                                                <Message message="upgrade:tutorial.reload" />
                                            </MDBBtn>
                                            }
                                        </>
                                    }
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </main>
                    <aside className="loginPage__aside">
                        <BackgroundSvg className="loginPage__background" />
                        {shuttingDown ? null : <div className="upgradeChange__wave" />}
                    </aside>
                </div>
            </>
        </I18nextProvider>
    );


export const callResizeEvent = (time = 200) => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), time);
};


export const useCombinedRefs = (...refs) => {
    return target => {
        refs.forEach(ref => {
            if (!ref) {
                return;
            }
            if (typeof ref === 'function') {
                ref(target);
            } else {
                ref.current = target;
            }
        });
    };
};

/**
 * @param title
 * @param desc
 * @param {"success"|"danger"|"info"|"warning"} type
 * @param {object} [descParams]
 * @param {boolean} [persistent]
 */

export const createNotification = ({ title, desc, type, descParams = {}, persistent = false }) => {
    store.add &&
            store.addNotification({
                title: tFilter(title),
                message: desc ? tFilter(desc, descParams) : ' ',
                type: type,
                insert: 'bottom',
                container: 'bottom-right',
                animationIn: [ 'animate__animated', 'animate__fadeIn' ],
                animationOut: [ 'animate__animated', 'animate__fadeOut' ],
                dismiss: {
                    duration: persistent ? 0 : 5000,
                    pauseOnHover: true,
                    onScreen: true,
                } });
};


/**
 * Hook to debug functional component updates, console logs changed props
 *
 * THIS FUCTION CANT BE USED IN PRODUCTION
 *
 * @param {*} props any props to be logged
 */

export const useTraceUpdate = (props) => {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [ key, value ]) => {
            if (prev.current[key] !== value) {
                ps[key] = [ prev.current[key], value ];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            // eslint-disable-next-line no-console
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
};
