/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';


const defaultStyle = {
    opacity: 1,
    transformStyle: 'preserve-3d',
    transformOrigin: 'top',
};

const transitionStyles = (timeout) => ({
    exiting: { opacity: 1, animation: `wiggle ${timeout}ms ease-in` },
    exited: { opacity: 0 },
});

const TransitionSwipe = ({ children, in: inProp, timeout }) => (
    <Transition
        in={inProp}
        timeout={timeout}
    >
        {state => (
            <div style={{
                ...defaultStyle,
                ...transitionStyles(timeout)[state],
            }}
            >
                <style>{`@keyframes wiggle {
                    40% {  transform: translateX(0) rotateX(20deg);
                           box-shadow: 0px 15px 15px -5px rgba(34,18,3,0.1);
                    }
                    60% {  opacity: 1 ;
                        box-shadow: 0px 15px 15px -10px rgba(34,18,3,0.05);
                    }
                    99% { transform: translateX(100%); opacity: 0; }
                    }`
                }
                </style>
                {children}
            </div>
        )}
    </Transition>
);

TransitionSwipe.propTypes = {
    children: PropTypes.node,
    in: PropTypes.bool,
    timeout: PropTypes.number,
};

export default TransitionSwipe;
