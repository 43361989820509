/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    getHlcfgOpenFromChange,
    getIsCluster,
    getIsTreeDirty,
    getVerificationErrors,
    resetAllChangesToInit,
    setChangeDiffOpen
} from '~frontendDucks/hlcfgEditor/index.js';
import { ButtonWithLock, Input } from '~frontendComponents/Generic/index.js';
import { cfgActivationPrepare, getCommitMessage, setCommitMessage,
    getCfgActivationPreparation } from '~frontendDucks/cfgActivation/index.js';
import {
    getHealthIssues,
    getHealthIssuesRequest,
    getIsLoadingHealthIssues,
    getIsSynchronizationActivation,
    isNodeUnreachable,
} from '~frontendDucks/clusterSetup/index.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';


const ActivateConf = () => {
    const isCluster = useSelector<any, boolean>(getIsCluster);
    const healthIssues = useSelector<any, any[]>(getHealthIssues);
    const nodeUnreachable = useSelector<any, boolean>(isNodeUnreachable);
    const isLoadingHealthIssues = useSelector<any, boolean>(getIsLoadingHealthIssues);
    const verificationErrors = useSelector<any, any[]>(getVerificationErrors);
    const isTreeDirty = useSelector<any, boolean>(getIsTreeDirty);
    const commitMessage = useSelector<any, string>(getCommitMessage);
    const openFromChange = useSelector<any, boolean>(getHlcfgOpenFromChange);
    const isPreparingActivation = useSelector<any, boolean>(getCfgActivationPreparation);

    const requestHealthIssues = useDispatchCallback(getHealthIssuesRequest, []);
    useEffect(() => {
        if (isCluster) {
            requestHealthIssues();
        }
    }, [ isCluster, requestHealthIssues ]);

    // used to display the correct name of activation button
    const isSynchronizationActivation = useSelector(getIsSynchronizationActivation);


    const { t } = useTranslation();


    const dispatch = useDispatch();

    const click = useCallback(() => {
        dispatch(cfgActivationPrepare());
    }, [ dispatch ]);

    const reset = useCallback(() => {
        dispatch(resetAllChangesToInit());
        dispatch(setChangeDiffOpen(false));

    }, [ dispatch ]);

    const doSetCommitMessage = useCallback(({ value }) => {
        dispatch(setCommitMessage({ value }));
    }, [ dispatch ]);
    return (
        <MDBCard className="help__Card">

            <MDBCardBody className="help__CardBody p-2">
                <Input
                    className="form-group--withoutMargin"
                    focus={!openFromChange}
                    id="commitMessage"
                    onChange={doSetCommitMessage}
                    onEnterPress={click}
                    placeholder={t('modalWindows:ChangesConfirmationModal.commitMessage.placeholder')}
                    renderFocus={!openFromChange}
                    value={commitMessage}
                />
                <div className="differs__activateButtons">
                    <ButtonWithLock
                        className="pl-2 pr-2 btn-flex-40"
                        datacy="forgetChangesBtn"
                        messageOnLocked="modalWindows:ChangesConfirmationModal.reverse.title"
                        messageOnOpen="modalWindows:ChangesConfirmationModal.reverse.title"
                        onClick={reset}
                    />
                    <ButtonWithLock
                        color={isCluster || !healthIssues.length || nodeUnreachable ||
                            healthIssues.some(item => item.canNotBeAutoSynced) ||
                            isLoadingHealthIssues ? 'primary' : 'secondary'}
                        datacy="activate"
                        disabled={verificationErrors.length > 0 ||
                                isLoadingHealthIssues || isTreeDirty || isPreparingActivation}
                        id="changesConfirmationModalConfirm"
                        key="submit"
                        messageOnLocked={
                            `modalWindows:ChangesConfirmationModal.${
                                isPreparingActivation ? 'preparing' :
                                    isSynchronizationActivation ? 'needSync' : 'submit'}.title`
                        }
                        messageOnOpen={
                            `modalWindows:ChangesConfirmationModal.${
                                isPreparingActivation ? 'preparing' :
                                    isSynchronizationActivation ? 'needSync' : 'submit'}.title`
                        }
                        onClick={click}
                        openLock={Boolean(openFromChange || commitMessage)}
                    />

                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ActivateConf;
