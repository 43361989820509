/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const TIME_INTERVAL_REGEX = /^([01]?\d:[0-5]\d|2[0-3]:[0-5]\d)-([01]?\d:[0-5]\d|2[0-3]:[0-5]\d)$/;

type TimeSchema = {
    hour: number,
    minute: number
}

export type TimeInterval = {
    from: TimeSchema,
    to: TimeSchema,
}

export const timeIntervalObj = {
    getValueSetter: actionCreator => ({ value, name, id }) => {
        const intervalsArr: TimeInterval[] = value.map((value: string) => {
            const intervals = value.split('-');
            const fromSplit = intervals[0].split(':');
            const toSplit = intervals[1].split(':');

            const from: TimeSchema = {
                hour: Number(fromSplit[0]),
                minute: Number(fromSplit[1])
            };

            const to: TimeSchema = {
                hour: Number(toSplit[0]),
                minute: Number(toSplit[1])
            };

            const objToSaveToHlcfg: TimeInterval = {
                from: from,
                to: to,
            };
            return objToSaveToHlcfg;
        });
        actionCreator({ name, id, value: intervalsArr });
    },
    isValid: (value: string) => {
        return TIME_INTERVAL_REGEX.test(value);
    },
    stringify: (hlcfgObjArray: TimeInterval[] | undefined) => {
        const minute = (minute: number) => {
            return minute < 10 ? `0${minute}` : minute;
        };

        if (hlcfgObjArray) {
            return hlcfgObjArray.map(item => {
                return `${item.from.hour}:${minute(item.from.minute)}-${item.to.hour}:${minute(item.to.minute)}`;
            });
        }
        return [];
    },
};
