/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getTableName = columnId => columnId.split('.')[0];

export const getColumnName = columnId => columnId.split('.')[1];

export const getColumnVariant = columnId => columnId.split('.')[2];

export const getColumnId = (tableName, columnName, columnVariant) => {
    if (columnVariant) {
        return `${tableName}.${columnName}.${columnVariant}`;
    }
    return `${tableName}.${columnName}`;
};
