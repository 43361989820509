/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';
import { callSaga, selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { getValue, PathGetter, setValueRemoveEmptyParents } from '~commonLib/objectUtils.ts';
import { UserSettingType } from '~frontendDucks/userSettings/types.ts';
import { getTaskQueue } from '~commonLib/functionUtils.ts';


const initialState: UserSettings = {};


const userSettings = createSlice({
    name: 'ak/userSettings',
    initialState,
    reducers: {
        /**
         * Internal reducer to set FE state without saving it to BE.
         */
        setUserSettings: (state, { payload }: PayloadAction<UserSettings>) => payload,

        setUserSetting: (state, action: ReturnType<typeof setUserSetting>) => {
            setValueRemoveEmptyParents(state, action.payload.path, action.payload.value);
        }
    },
});

export default userSettings.reducer;

// Action creators
const {
    setUserSettings,
    setUserSetting: setUserSettingReducer
} = userSettings.actions;

// TS started detecting cycles in types weirdly in 5.5 - the setUserSettingReducer can not be used directly
const setUserSettingReducerType: string = setUserSettingReducer.type;

/**
 * Custom action creator, because it can not be typed correctly with reduxjs/toolkit helpers.
 */
export const setUserSetting = <T extends PathGetter>(
    pathGetter: T, value: UserSettingType<T>
) => ({ payload: { path: pathGetter.getPath(), value }, type: setUserSettingReducerType });

export const fetchUserSettingsRequest = createAction(`${userSettings.name}/fetchUserSettingsRequest`);

// Getters
const getState = (state): UserSettings => state.userSettings;
const getUserSettings = (state) => getState(state);

export const getUserSetting = <
    T extends PathGetter
>(state, pathGetter: T): UserSettingType<T> => {
    return getValue(getUserSettings(state), pathGetter.getPath());
};
// Sagas

const postSetUserSettings = backendPost('/users/setUserSettings');
const postGetUserSettings = backendPost('/users/getUserSettings');

// Explicit queue because takeLatest behaves like takeEvery when the only action is running an api call
// This queue prevents multiple api calls firing at once,
// because that could could possibly cause race-condition in saving of the settings.
const apiCallQueue = getTaskQueue();
const saveUserSettings = async (userSettings: UserSettings) => {
    apiCallQueue.flushAndResolveUnstartedTasks();
    await apiCallQueue.queueAndAwaitTask(async () => postSetUserSettings(userSettings));
};

const workerFetchUserSettings = function* () {
    try {
        const { data } = yield* callSaga(postGetUserSettings, {});
        yield put(setUserSettings(data));
    } catch (error) {
        createNotification({
            title: 'widgets:userSettings.fetchError',
            type: 'warning',
            desc: getApiError(error).message,
        });
    }
};


const workerSaveUserSettingsToBe = function* () {
    try {
        const settings = yield* selectSaga(getUserSettings);
        yield* callSaga(saveUserSettings, settings);
    } catch (error) {
        createNotification({
            title: 'widgets:userSettings.setError',
            type: 'warning',
            desc: getApiError(error).message,
        });
    }
};

export const sagas = [
    takeLatest(setUserSettingReducer.type, workerSaveUserSettingsToBe),
    takeLatest(fetchUserSettingsRequest.type, workerFetchUserSettings),
];
