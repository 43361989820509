/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    HOURS_IN_DAY, DAYS_IN_YEAR, MINUTES_IN_HOUR, SECONDS_IN_MINUTE, TIME_INPUT_VALUE_REGEX
} from '~frontendConstants/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';


// frontend asynchronous utility functions
/**
 * Resolves a promise after given number of milliseconds.
 *
 * @param {number} waitTime - waiting time in ms
 * @returns {Promise}
 */
export const promiseSetTimeout = ({ waitTime }: {waitTime: number}) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(undefined);
        }, waitTime);
    });
};
type Args = {
    value: number | undefined, uuid?: string, key: string, subkey?: string, subsubkey?: string, profile?: string,
type?: string}

type SetTimeoutComponentFunctionType = {
    setValue: (arg: Args) => void,
    uuid?: string,
    profile?: string,
    type?: string,
    subsubkey?: string
}

export const setTimeoutComponentFunction = (
    { setValue, uuid, profile, type, subsubkey }: SetTimeoutComponentFunctionType
) =>
    ({ value, name, id }) => {
        const valueWithoutWhitespaces = value.replace(/\s/g, '');
        if (valueWithoutWhitespaces === '0' || valueWithoutWhitespaces === '0s') {
            setValue({ value: undefined, uuid, key: name, subkey: id, subsubkey: subsubkey, profile, type });
            return;
        }
        if (value === '') {
            setValue({ value: undefined, uuid, key: name, subkey: id, subsubkey: subsubkey, profile, type });
        }
        if (value !== '' && valueWithoutWhitespaces.match(TIME_INPUT_VALUE_REGEX)) {
            setValue({ value: convertTimeStringToSeconds(valueWithoutWhitespaces),
                uuid, key: name, subkey: id, subsubkey: subsubkey, profile, type });
        }

    };


const convertTimeStringToSeconds = (valueWithoutWhitespaces) => {
    let seconds = 0;
    [
        { unit: 'y', multiplier: SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY * DAYS_IN_YEAR },
        { unit: 'd', multiplier: SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY },
        { unit: 'h', multiplier: SECONDS_IN_MINUTE * MINUTES_IN_HOUR },
        { unit: 'm', multiplier: SECONDS_IN_MINUTE },
        { unit: 's', multiplier: 1 },
    ].forEach(({ unit, multiplier }) => {
        const match = valueWithoutWhitespaces.match(new RegExp(`(\\d+)${unit}`));
        if (match) {
            seconds += Number(match[1]) * multiplier;
        }
    });
    return seconds;
};

export const setTimeoutComponentValidator = (t) =>
    ({ value, schema }) => {
        const valueWithoutWhitespaces = value.replace(/\s/g, '');
        if (valueWithoutWhitespaces === '0' || valueWithoutWhitespaces === '0s') {
            return t('profile:timeouts.minimum');
        }
        if (value === '') {
            return null;
        }
        if (schema?.maximum) {
            if (schema.maximum < convertTimeStringToSeconds(valueWithoutWhitespaces)) {
                return t('widgets:global.tooHigh', { maximum: valueFormatter.formatSeconds(schema.maximum) });
            }
        }
        if (value !== '' && valueWithoutWhitespaces.match(TIME_INPUT_VALUE_REGEX)) {
            return null;
        } else {
            return [ t('profile:timeouts.notValid'), t('profile:timeouts.valid') ];
        }
    };
