/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getStaticReferenceName = obj => obj?.[STATIC_HLCFG_REFERENCE_KEY];
export const isStaticReference = obj => !!getStaticReferenceName(obj);

export const STATIC_HLCFG_REFERENCE_KEY = '__staticHlcfgReference';


export const STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS = 'STATIC_HLCFG_REFERENCE_GUI_HTTPS';
export const STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS = 'STATIC_HLCFG_REFERENCE_GUI_PORTS';
export const STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN = 'STATIC_HLCFG_REFERENCE_WPAD_LISTEN';
export const STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES = 'STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES';
export const STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS = 'STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS';
export const STATIC_HLCFG_REFERENCE_ALL_FQDNS = 'STATIC_HLCFG_REFERENCE_ALL_FQDNS';
