/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';


type dataWindow = {
    matches: number[],
}

type initialStateType = {
    dataWindows: dataWindow,
}

const initialState: initialStateType = {
    dataWindows: {
        matches: []
    },
};

const logDataWindow = createSlice({
    name: 'ak/logDataWindow',
    initialState,
    reducers: {
        addDataWindows: (state, { payload }: PayloadAction<{dataWindow: dataWindow, index: number}>) => {
            state.dataWindows[payload.index] = payload.dataWindow;
        },
        clearMatches: (state, { payload }: PayloadAction<{windowIdx: number}>) => {
            delete state.dataWindows[payload.windowIdx];
        }
    }
});

export const { addDataWindows, clearMatches } = logDataWindow.actions;

export default logDataWindow.reducer;

const getRootState = state => state.logDataWIndow;

export const getDataWindows = (state) => getRootState(state).dataWindows;

export const isRowMatching = (state, row: number, window: number) =>
    getRootState(state).dataWindows?.[window]?.matches.includes(row) ?? false;

const getLogRowMatching = () => createSelector(
    [ (state, rowIdx: number, windowIdx: number) => isRowMatching(state, rowIdx, windowIdx) ],
    matching => matching
);

export const useLogRowIsMatching = (rowIdx: number, windowIdx: number) => {
    const matchingSelector = useMemo(getLogRowMatching, []);
    const matching = useSelector(state => matchingSelector(state, rowIdx, windowIdx));
    return matching;
};
