/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Component } from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { typeTimestamp } from '~frontendRoot/types/index.js';

import Icon from '../../Generic/Icon/index.ts';
import { TimeDuration } from '../../../components/Moment/index.js';
import Message from '../../Message/index.js';


@withTranslation()
class Table extends Component {
    static get propTypes() {
        return {
            userActivity: PropTypes.array,
            // tableItems: PropTypes.array,
            className: PropTypes.string,
            currentServerTime: typeTimestamp,
            t: PropTypes.func
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        const { userActivity, className, currentServerTime, t } = this.props;
        return (
            <MDBTable
                className={className}
                hover
            >
                <MDBTableHead className="tableHead__head">
                    <tr className="tableHead__row">
                        <th className="tableHead__activities">
                            <h4 className="tableHead__heading">
                                <Message message="user:heading.table" />
                            </h4>
                            <div className="tableHead__sorting">
                                <button className="tableHead__triangleUp"></button>
                                <button className="tableHead__triangleDown"></button>
                            </div>
                        </th>
                        <th className="tableHead__time">
                            <div className="tableHead__timeIcon">
                                <Icon
                                    name="clock-outline"
                                    size="sm"
                                />
                            </div>
                            {/* // TODO table sort / datatable default sort */}
                            <div className="tableHead__sorting">
                                <button className="tableHead__triangleUp"></button>
                                <button className="tableHead__triangleDown"></button>
                            </div>
                        </th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {userActivity?.length ? userActivity.map((row, index) => (
                        <tr
                            className="tableBody__tableItem"
                            key={index}
                        >
                            <td className="tableBody__cell">
                                <div className="tableBody__cellIcon">
                                    {row.icon ? <Icon name={row.icon} /> : null}
                                </div>
                                <div className="tableBody__cellContent">
                                    {row.title}
                                </div>
                            </td>
                            <td
                                className="tableBody__cell"
                            >
                                <TimeDuration
                                    from={row.date}
                                    to={currentServerTime}
                                />
                            </td>
                        </tr>
                    )) :
                        <tr
                            className="tableBody__tableItem"
                        >
                            <td
                                className="tableBody__cell--noData"
                                colSpan={3}
                            >
                                <h4 className="tableBody__noData">{t('user:table.noData')}</h4>
                            </td>
                        </tr>
                    }
                </MDBTableBody>
            </MDBTable>
        );
    }
}

export default Table;
