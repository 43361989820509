/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { default as lodashDebounce }  from 'lodash.debounce';

import { timestampMs } from '~commonLib/uncategorizedUtils.ts';
import { SECOND } from '~commonLib/constants.ts';
import type { PromiseOrNot } from '~commonLib/types.ts';

import { sleep } from './asyncUtils/asyncUtils.ts';
import { AnyFunc, AnyFuncMaybeAsync } from './types.ts';


export const debounce = <T extends AnyFunc>(
    fn: T,
    wait: number,
    opts?: {leading?:boolean, trailing?: boolean, maxWait?: number}
) => lodashDebounce(fn, wait, opts) as T;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]) => {};

type QueueItem = {
    func: AnyFunc,
    resolve?: AnyFunc,
    reject?: VoidFunction,
}
export const getTaskQueue = () => {
    const queue: QueueItem[] = [];

    let isProcessing;

    let unresolvedAwaitedTasks = 0;

    const processTasks = async () => {
        if (isProcessing) {
            return;
        }
        isProcessing = true;
        while (queue.length) {
            const task = queue.shift() || { func: () => {} };
            await task.func();
        }
        isProcessing = false;
    };
    return {
        addTask: (task: AnyFunc) => {
            queue.push({ func: task });
            void processTasks();
        },
        queueAndAwaitTask: <Task extends AnyFunc>(task: Task): Promise<ReturnType<Task>> => {
            const promise: Promise<ReturnType<Task>> = new Promise((resolve, reject) => {
                queue.push({
                    func: async () => {
                        try {
                            const result = await task();
                            resolve(result);
                        } catch (error) {
                            reject(error);
                        } finally {
                            unresolvedAwaitedTasks--;
                        }
                    },
                    resolve, reject,
                });
            });
            unresolvedAwaitedTasks++;
            void processTasks();
            return promise;
        },
        flushUnstartedTasks: () => {
            assert(
                unresolvedAwaitedTasks === 0,
                'Trying to flush tasks that are awaited. ' +
                'That is developer error and would likely result in hanging application'
            );
            queue.splice(0, queue.length);
        },
        hasUnstaredTask: () => {
            return queue.length > 0;
        },

        /**
         * Note that this will resolve every unstarted task with undefined.
         * This is type-unsafe, and will result in incorrect types if the awaited function has non-void return value.
         */
        flushAndResolveUnstartedTasks: () => {
            const tasks = queue.splice(0, queue.length);
            unresolvedAwaitedTasks = 0;
            tasks.forEach(task => task.resolve?.());
        },
        flushAndRejectUnstartedTasks: () => {
            const tasks = queue.splice(0, queue.length);
            unresolvedAwaitedTasks = 0;
            tasks.forEach(task => task.reject?.());
        },
    };
};

type RetryFnParams = {
    fn: (opts: {attemptIdx: number}) => PromiseOrNot<any>
    times: number,
    interval: number
    onSoftFail?: AnyFunc,
    doContinue?: AnyFunc,
}
export const retryFnNTimesWithInterval = async ({ fn, times, interval, onSoftFail, doContinue, }: RetryFnParams) => {
    for (let i = 0; i < times || times === 0; i++) {
        try {
            return await fn({ attemptIdx: i });
        } catch (error) {
            if (i + 1 === times || (doContinue && !doContinue(error))) {
                throw error;
            }
            if (onSoftFail) {
                onSoftFail(error);
            }
            await sleep(interval);
        }
    }
};

export const doOnce = <T extends AnyFunc>(fn: T): (...args: Parameters<T>) => void => {
    let done = false;
    return (...args): void => {
        if (!done) {
            fn(...args);
            done = true;
        }
    };
};

/**
 * We will create function that will call provided functions sequentially.
 * If all functions have been called, next calls will call the last one.
 */
export const callSequenceRepeatLast = (fns: VoidFunction[]) => {
    let i = 0;
    let nextFn = fns[i];
    return (): void => {
        assert(nextFn);
        nextFn();
        if (i < fns.length - 1) {
            i++;
        }
        nextFn = fns[i];
    };
};

/**
 * Memoize function to cache last respond using uuid as a second param, not used for now.
 * This function does not clean its cache, be wary of memory leaks when using with possibly infinite first arguments
 */
export const memoizeByFirstStringifiedArg = <T extends AnyFunc>(func: T): T => {
    const cache: Record<PropertyKey, ReturnType<T>> = {};
    return <T>((...args: Parameters<T>) => {
        const strKey = args[0]?.toString() || '';
        if (!cache[strKey]) {
            cache[strKey] = func(...args);
        }
        return cache[strKey];
    });
};

/**
 * Memoize function to cache one result. Function will be re-run if provided value or reference has changed.
 */
export const memoizeOneByFirstArg = <T extends AnyFunc>(func: T): T => {
    let cache = null;
    let memoizationArg = null;
    return <T>((...args: Parameters<T>) => {
        const firstArg = args[0];
        if (firstArg !== memoizationArg) {
            memoizationArg = firstArg;
            cache = func(...args);
        }
        return cache;
    });
};

export const memoizeSingle = <T extends AnyFunc>(func: T): T => {
    let cache = undefined;
    return <T>((...args: Parameters<T>) => {
        if (!cache) {
            cache = func(...args);
        }
        return cache;
    });
};
const memoizeAllCache = new WeakMap();
export const memoizeAllByFirstArg = <T extends AnyFunc>(func: T): T => {
    return <T>((...args: Parameters<T>): ReturnType<T> => {
        if (!memoizeAllCache.has(args[0])) {
            memoizeAllCache.set(args[0], func(...args));
        }
        return memoizeAllCache.get(args[0]);
    });
};

export const measureFn = <T extends AnyFuncMaybeAsync>(func: T, onFinished: (durationSeconds) => void): T => {
    return (async (...params) => {
        const now = timestampMs();
        const result = await func(...params);
        const durationSeconds = (timestampMs() - now) / SECOND;
        onFinished(durationSeconds);
        return result;
    }) as T;
};

export const measureSyncFn = <T extends AnyFunc>(func: T, onFinished: (durationSeconds) => void): T => {
    return ((...params) => {
        const now = timestampMs();
        const result = func(...params);
        const durationSeconds = (timestampMs() - now) / SECOND;
        onFinished(durationSeconds);
        return result;
    }) as T;
};

/**
 * Usable as throw expression {@link https://github.com/tc39/proposal-throw-expressions}
 */
export const throwErr = (err: Error): never => {
    throw err;
};
