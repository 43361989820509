/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';


// actions
const LOAD_SSO_SETTINGS_REQUEST = 'ak/userManagement/ssoSettings/LOAD_SSO_SETTINGS_REQUEST';
const LOAD_SSO_SETTINGS_SUCCESS = 'ak/userManagement/ssoSettings/LOAD_SSO_SETTINGS_SUCCESS';
const LOAD_SSO_SETTINGS_ERROR = 'ak/userManagement/ssoSettings/LOAD_SSO_SETTINGS_ERROR';


// initial state
const initialState = {
    error: null,
    isConfigured: false,
    isLoading: false,
    settings: null,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOAD_SSO_SETTINGS_REQUEST:
        return {
            ...state,
            isLoading: true
        };
    case LOAD_SSO_SETTINGS_SUCCESS:
        return {
            error: null,
            isConfigured: true,
            isLoading: false,
            settings: action.payload,
        };
    case LOAD_SSO_SETTINGS_ERROR:
        return {
            error: action.payload,
            isConfigured: false,
            isLoading: false,
            setting: null,
        };
    default:
        return state;
    }
};

export default reducer;


// data accessors
const getRootState = state => state.userManagement.ssoSettings;

export const getSSOSettings = state => getRootState(state).settings;
export const getIsConfiguredSSOSettings = state => getRootState(state).isConfigured;
export const getIsLoadingSSOSettings = state => getRootState(state).isLoading;
export const getErrorSSOSettings = state => getRootState(state).error;


// action creators
export const loadSSOSettingsRequest = () =>
    ({ type: LOAD_SSO_SETTINGS_REQUEST });

const loadSSOSettingsSuccess = payload =>
    ({ type: LOAD_SSO_SETTINGS_SUCCESS, payload });

const loadSSOSettingsError = payload =>
    ({ type: LOAD_SSO_SETTINGS_ERROR, payload });


// API endpoints
export const apiCallGetSsoSettings = async () =>
    axios.get('/api/users/getSSOSettings');


// side effects
const loadSSOSettingsWorker = function* () {
    try {
        const { data } = yield call(apiCallGetSsoSettings);
        yield put(loadSSOSettingsSuccess(data));
    } catch (error) {
        yield put(loadSSOSettingsError, getApiError(error));
    }
};

export const sagas = [
    takeLatest(LOAD_SSO_SETTINGS_REQUEST, loadSSOSettingsWorker),
];
