/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelectorCreator, defaultMemoize } from 'reselect';
import { shallowEqual } from 'react-redux';
import assert from 'assert';


export const shallowArrayOfObjectEqual =  (prev, next) => {
    assert(Array.isArray(prev) && Array.isArray(next), 'Both values should be arrays');
    if (prev.length !== next.length) {
        return false;
    }
    for (let i = 0; i < prev.length; i++) {
        if (!shallowEqual(prev[i], next[i])) {
            return false;
        }
    }
    return true;
};

export const createSelectorArrayOfStrings = createSelectorCreator(
    defaultMemoize,
    shallowEqual
);


export const createSelectorArrayOfObjectsShallow = createSelectorCreator(
    defaultMemoize,
    shallowArrayOfObjectEqual
);

export const createSelectorSchemaObjectShallow = createSelectorCreator(
    defaultMemoize,
    (prev, next) => {
        return shallowEqual(prev, next);
    }
);

export const getProcessMultiAction = progressReducer => (state, action) => action.payload.reduce((acc, actionItem) => {
    return progressReducer(acc, { type: action.type, ...actionItem });
}, state);


export const createDebugSelector = createSelectorCreator(defaultMemoize,
    (previousVal, currentVal) => {
        const rv = currentVal === previousVal;
        if (!rv) {
            //eslint-disable-next-line
            console.log('Selector param value changed', previousVal, currentVal);
        }
        return rv;
    },);
