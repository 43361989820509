/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { IMG_GUI_CFG_ACTIVATION_PATH, IMG_GUI_HELP, IMG_GUI_ICONS_PATH, IMG_GUI_MAIN_WINDOW_PATH, IMG_GUI_MENU_PATH } from '../imgPaths.js';
import { TYPE_IMAGE, TYPE_LINK } from '../textObjectTypes.js';
import {
    SECTION_GUI, SECTION_GUI_CFG_ACT, SECTION_GUI_HELP, SECTION_GUI_MAIN, SECTION_GUI_MENU, SECTION_GUI_STATE,
    SECTION_INSTALLATION_FIREWALL
} from './sectionTags.js';


const sectionGui = {
    title: 'handbook:sectionGui.title',
    tag: SECTION_GUI,
    pars: [
        {
            text: [
                'handbook:sectionGui.text1',
                {
                    type: TYPE_LINK,
                    link: 'state firewall',
                    text: 'handbook:sectionGui.text2',
                },
                'handbook:sectionGui.text3'
            ],
        }
    ],
    sections: [
        {
            title: 'handbook:sectionGui.navigation.title',
            tag: SECTION_GUI_MENU,
            pars: [
                {
                    text: [ 'handbook:sectionGui.navigation.text1' ]
                },
                {
                    text: [ 'handbook:sectionGui.navigation.text2' ]
                },
                {
                    isSpecial: true,
                    specialObject: {
                        type: TYPE_IMAGE,
                        src: IMG_GUI_MENU_PATH,
                    }
                }
            ]
        },
        {
            title: 'handbook:sectionGui.mainWindow.title',
            tag: SECTION_GUI_MAIN,
            pars: [
                {
                    text: [ 'handbook:sectionGui.mainWindow.text' ]
                },
                {
                    isSpecial: true,
                    specialObject: {
                        type: TYPE_IMAGE,
                        src: IMG_GUI_MAIN_WINDOW_PATH,
                    }
                }
            ]
        },
        {
            title: 'handbook:sectionGui.cfgActivation.title',
            tag: SECTION_GUI_CFG_ACT,
            pars: [
                {
                    text: [
                        'handbook:sectionGui.cfgActivation.text1',
                        {
                            type: TYPE_LINK,
                            link: 'todo',
                            text: 'TODO: link cfgActivation'
                        },
                        'handbook:sectionGui.cfgActivation.text3'
                    ]
                },
                {
                    isSpecial: true,
                    specialObject: {
                        type: TYPE_IMAGE,
                        src: IMG_GUI_CFG_ACTIVATION_PATH,
                    }
                }
            ]
        },
        {
            title: 'handbook:sectionGui.stateIcons.title',
            tag: SECTION_GUI_STATE,
            pars: [
                {
                    text: [ 'handbook:sectionGui.stateIcons.text' ]
                },
                {
                    isSpecial: true,
                    specialObject: {
                        type: TYPE_IMAGE,
                        src: IMG_GUI_ICONS_PATH,
                    }
                }
            ]
        },
        {
            title: 'handbook:sectionGui.help.title',
            tag: SECTION_GUI_HELP,
            pars: [
                {
                    text: [
                        'handbook:sectionGui.help.text1',
                        {
                            type: TYPE_LINK,
                            link: SECTION_INSTALLATION_FIREWALL,
                            text: 'handbook:sectionGui.help.text2'
                        },
                        'handbook:sectionGui.help.text3',
                    ]
                },
                {
                    isSpecial: true,
                    specialObject: {
                        type: TYPE_IMAGE,
                        src: IMG_GUI_HELP,
                    }
                }
            ]
        }
    ]
};

export default sectionGui;
