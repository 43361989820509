/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';


const TREE_CHART_HIDDEN_CHANGE = 'ak/treeChartActive/TREE_CHART_HIDDEN_CHANGE';


// initial state
export const initialState = {
    qos: {},
};


// reducer

const reducer = createReducer(initialState, {
    [TREE_CHART_HIDDEN_CHANGE]: (state, { payload }) => {
        if (!payload.value) {
            delete state[payload.type][payload.uuid];
        } else {
            state[payload.type][payload.uuid] = payload.value;
        }
    },
});

export default reducer;


const getRootState = state => state.treeChartHidden;


export const getTreeChartHidden = createSelector([
    (state, type) => getRootState(state)[type]
], (hidden) => hidden);

export const getTreeChartHiddenNode = (state, type, uuid) => getRootState(state)[type][uuid];


// action creators


export const setTreeChartHidden = (payload) =>
    ({ type: TREE_CHART_HIDDEN_CHANGE, payload });
