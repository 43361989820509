/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';

import filterObjectCustomManipulator from './filterObjectCustomManipulator.js';
import filterObjectEnumsManipulator from './filterObjectEnumsManipulator.js';
import filterObjectNumbersManipulator from './filterObjectNumbersManipulator.js';
import filterObjectTimeManipulator from './filterObjectTimeManipulator.js';
import filterObjectValuesManipulator from './filterObjectValuesManipulator.js';


/**
 * A single filter. It is verified by schema #filter. It is send to the
 * server as-is, thus it should at all times be a plain old JS object.
 *
 * @typedef {object} FilterObject
 * @property {string} columnName - name of the column this filter is
 * filtering
 * @property {object} configure
 * @property {boolean} configure.type - whether the type can currently be
 * changed
 * @property {boolean} configure.value - whether the value can currently be
 * changed
 * @property {boolean} isDisabled - whether this filter is currently
 * disabled
 * @property {boolean} isNegated - whether this filter is negated
 * @property {boolean} isAnd - whether this filter is AND or OR, makes sense
 * only when the value of selected is an array
 * @property {object|object[]} selected - the actual value of the filter,
 * the type depends on the filterType
 * @property {string} type - aka filterType
 */

/**
 * A filter configuration that can be send directly to the Reporter. It is
 * verified by schema #filter-serialized. It is send to the server as-is,
 * thus it should at all times be a plain old JS object.
 *
 * @typedef {object} FilterParams
 */

// key is filterType, value is filterObjectManipulator
const FILTER_TYPES = {
    custom: filterObjectCustomManipulator,
    enums: filterObjectEnumsManipulator,
    numbers: filterObjectNumbersManipulator,
    time: filterObjectTimeManipulator,
    values: filterObjectValuesManipulator,
};

const EXPECTED_FUNCTIONS = {
    createNewFilterObject: true,
    addValueToFilter: true,
    removeValueFromFilter: true,
    getReporterParams: true,
};

Object.keys(FILTER_TYPES).forEach(function(filterType) {
    Object.keys(EXPECTED_FUNCTIONS).forEach(function(fnName) {
        const fn = FILTER_TYPES[filterType][fnName];
        if (typeof fn !== 'function' && fn !== null) {
            throw new Error('Function "' + fnName + '" in manipulator ' +
                'for filterType "' + filterType + '" is not a function ' +
                'nor null, but "' + typeof fn + '"');
        }
    });
});

// special filter types where key is columnName, value is filterType
const SPECIALS = {
    custom: 'custom',
};

// key in columnType, value is array of columnName
const EXTRA_COLUMNS = {
    others: [ 'custom' ]
};

// verify the consistence between EXTRA_COLUMNS and SPECIALS
Object.keys(EXTRA_COLUMNS).forEach(function(columnType) {
    EXTRA_COLUMNS[columnType].forEach(function(columnName) {
        if (!(columnName in SPECIALS)) {
            throw new Error('Column "' + columnName + '" is special but ' +
                'not present in SPECIALS');
        }
    });
});

// verify the consistence between SPECIALS and EXTRA_COLUMNS
Object.keys(SPECIALS).forEach(function(columnName) {
    const found = Object.keys(EXTRA_COLUMNS).reduce(
        function(temp, columnType) {
            return temp +
                (EXTRA_COLUMNS[columnType].indexOf(columnName) === -1 ?
                    0 :
                    1);
        },
        0
    );
    if (found !== 1) {
        throw new Error('Column "' + columnName + '" is special but is ' +
            'present ' + found + ' times in EXTRA_COLUMNS');
    }
});

const filterObjectManipulator = {
    EXTRA_COLUMNS: EXTRA_COLUMNS,
    SPECIALS: SPECIALS,
};

/**
 * Returns filter type for given column name. Note that there are also
 * special column names for special filter types. Those are defined in
 * SPECIALS.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {string} columnName - name of the column
 * @returns {string}
 */
filterObjectManipulator.getFilterType = function(
    reporterTemplates, columnName
) {
    if (columnName in SPECIALS) {
        return SPECIALS[columnName];
    }
    if (!reporterTemplates.columns) {
        return;
    }
    const column = reporterTemplates.columns.byName[columnName];
    if (!column) {
        return;
    }
    if (reporterTemplates.columnTypeIsOfType(column.type, 'enum') ||
        reporterTemplates.columnTypeIsOfType(column.type, 'kcw_category') ||
        reporterTemplates.columnTypeIsOfType(column.type, 'day_of_week'))
    {
        return 'enums';
    }
    if (reporterTemplates.columnTypeIsOfType(column.type, 'time')) {
        return 'time';
    }
    if (reporterTemplates.columnIsOfType(columnName, 'integer') ||
        reporterTemplates.columnIsOfType(columnName, 'duration'))
    {
        return 'numbers';
    }
    if (column.isCategory) {
        return 'values';
    }
    return 'values';
};

const getManipulator = function(reporterTemplates, columnName) {
    const filterType = filterObjectManipulator.getFilterType(
        reporterTemplates, columnName
    );
    if (!(filterType in FILTER_TYPES)) {
        throw new Error('Unknown filter type "' + filterType + '" for ' +
            'column "' + columnName + '"');
    }
    return FILTER_TYPES[filterType];
};

/**
 * Creates default filter on given column name and value.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {string} columnName - name of the column
 * @param {string|number} value - value
 * @returns {FilterObject} a new filterObject
 */
filterObjectManipulator.createNewFilterObject = function(reporterTemplates, columnName, value) {
    const manipulator = getManipulator(reporterTemplates, columnName);
    const filterType = filterObjectManipulator.getFilterType(
        reporterTemplates, columnName
    );
    const columnObject = reporterTemplates.columns.byName[columnName];
    return Object.assign(
        {},
        {
            columnName: getValue(columnObject, 'filterAs.name') || columnName,
            configure: {
                type: true,
                value: true,
            },
            isDisabled: false,
            isNegated: false,
            isAnd: false,
            type: filterType,
        },
        manipulator.createNewFilterObject(columnName, value)
    );
};

/**
 * Adds a new value to a filter.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {FilterObject} filterObject
 * @param {*} value
 */
filterObjectManipulator.addValueToFilter = function(reporterTemplates, filterObject, value) {
    getManipulator(
        reporterTemplates, filterObject.columnName
    ).addValueToFilter(filterObject, value);
};

/**
 * Removes value at given index from a filter.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {FilterObject} filterObject
 * @param {number} index
 */
filterObjectManipulator.removeValueFromFilter = function(reporterTemplates, filterObject, index) {
    getManipulator(
        reporterTemplates, filterObject.columnName
    ).removeValueFromFilter(
        filterObject, index
    );
};

/**
 * Returns parameters for report generation by Reporter.
 * The result should be valid according to #filter-serialized.
 *
 * @memberof filterObjectManipulator
 * @param customFilterStorage
 * @param reporterTemplates
 * @param {filterContainerManipulator} filterContainerManipulator
 * @param {FilterObject} filterObject
 * @returns {JSON|undefined} object with parameters or undefined when the
 * filter is empty
 */
filterObjectManipulator.getReporterParams =
    function(customFilterStorage, reporterTemplates, filterContainerManipulator, filterObject) {
        return getManipulator(
            reporterTemplates, filterObject.columnName
        ).getReporterParams(
            customFilterStorage, reporterTemplates, filterContainerManipulator, filterObject
        );
    };

/**
 * Makes the filter's value configurable unless the filter editor would be
 * too big.
 *
 * @memberof filterObjectManipulator
 */
filterObjectManipulator.expandFilterIfSmall = function(filterObject) {
    if (!Array.isArray(filterObject.selected) || filterObject.selected.length < 2) {
        filterObject.configure.value = true;
    }
};

/**
 * Fixes the filterObject upon loading.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {FilterObject} filterObject
 */
filterObjectManipulator.fix = function(reporterTemplates, filterObject) {
    const newType = filterObjectManipulator.getFilterType(
        reporterTemplates, filterObject.columnName
    );
    filterObject.type = newType;
};


/**
 * Returns true if given filter would be ignored for a report with given
 * tableName and templateName.
 *
 * @memberof filterObjectManipulator
 * @param reporterTemplates
 * @param {FilterObject} filterObject
 * @param {string} tableName
 * @param {string} reporterTemplateName
 * @returns {boolean}
 */
filterObjectManipulator.isIgnoredForReport =
    function(reporterTemplates, filterObject, tableName, reporterTemplateName) {
        const colName = filterObject.columnName;
        if (colName in SPECIALS) {
            return false;
        }
        const mapColumns = getValue(
            reporterTemplates,
            'tables.' + tableName + '.mapColumns'
        );
        if (mapColumns) {
            if (!(colName in mapColumns)) {
                return true;
            }
        } else {
            // eslint-disable-next-line no-console
            console.error('mapColumns is undefined for table "' + tableName + '"');
        }
        if (!(colName in (getValue(reporterTemplates, 'tables.' + tableName + '.columns.' + colName + '.mapColumns') ||
            {}))) {
            return false;
        }
        return (getValue(reporterTemplates, reporterTemplateName + '.ignored_filters') || []).reduce(
            function(temp, ignoredFilter) {
                return temp ||
                    (
                        ignoredFilter.type === 'metric' &&
                        reporterTemplates.columnIsOfType(colName, 'metric')
                    );
            },
            false
        );
    };

export default filterObjectManipulator;
