/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * A scene is a page of the application. It is rendered in the main portion of the page, the main panel (there is also
 * top panel aka information bar and left-side panel aka menu). The structure of this directory corresponds to the URI
 * structure of the application. A scene can have subscenes, which corresponds to second-layer navigation. Components
 * that are specific for a scene are defined in the directory of that scene.
 *
 * See https://medium.com/@alexmngn/how-to-better-organize-your-react-applications-2fd3ea1920f1 for more information.
 */

import { createRedirect, createScene } from '~frontendLib/scenes.js';
import { INITIAL_DEPLOYMENT_MODE } from '~sharedConstants/index.ts';

import Configuration, { scenes as scenesConfiguration } from './Configuration/index.js';
import Develop, { scenes as developScenes } from './Develop/index.js';
import Monitoring, { scenes as scenesMonitoring } from './Monitoring/index.js';
import Protection from './Protection/Protection.js';
import { default as scenesProtection } from './Protection/scenes/scenes.js';
import System, { scenes as scenesSystem } from './System/index.js';
import User, { scenes as scenesUser, Title as UserTitle } from './User/index.js';
import Users, { scenes as scenesUsers } from './Users/index.js';


const HOME_REDIRECT_SCENE = createRedirect({
    path: '/',
    to: '/monitoring/system/changes',
    exact: true,
});

const MONITORING_SCENE = createScene({
    path: '/monitoring',
    component: Monitoring,
    scenes: scenesMonitoring,
    title: 'scenes:scenes.Monitoring.title',
    icon: 'chart-areaspline'
});

const PROTECTION_SCENE = createScene({
    path: '/protection',
    component: Protection,
    scenes: scenesProtection,
    title: 'scenes:scenes.Protection.title',
    icon: 'shield-check-outline',
});

const CONFIGURATION_SCENE = createScene({
    path: '/configuration',
    component: Configuration,
    scenes: scenesConfiguration,
    title: 'scenes:scenes.Configuration.title',
    icon: 'wrench-outline',
});

const SYSTEM_SCENE = createScene({
    path: '/system',
    component: System,
    scenes: scenesSystem,
    title: 'scenes:scenes.System.title',
    icon: 'settings-outline',
});

const USERS_SCENE = createScene({
    path: '/users',
    component: Users,
    scenes: scenesUsers,
    title: 'scenes:scenes.Users.title',
    icon: 'account-multiple-outline',
});

const DEVELOP_SCENE = createScene({
    path: '/develop',
    component: Develop,
    scenes: developScenes,
    title: 'scenes:scenes.Develop.title',
    icon: 'auto-fix',
});

const USER_SCENE = createScene({
    path: '/profile',
    component: User,
    scenes: scenesUser,
    Title: UserTitle,
    icon: 'account-outline',
    menu: 'bottom',
});

const scenes = ({ deployment, isProductionMode } = {}) => [
    HOME_REDIRECT_SCENE,
    MONITORING_SCENE,
    CONFIGURATION_SCENE,
    deployment === INITIAL_DEPLOYMENT_MODE ? null : PROTECTION_SCENE,
    SYSTEM_SCENE,
    USERS_SCENE,
    USER_SCENE,
    isProductionMode === true ? null : DEVELOP_SCENE,
].filter(objRoute => objRoute);

const getMapUris = (arrScenes, mapUris) => {
    for (const objScene of arrScenes) {
        if (objScene.route) {
            const uri = objScene.route.props.path || objScene.route.props.from || objScene.route.key;
            mapUris[uri] = objScene;
        }
        getMapUris(objScene.scenes ? objScene.scenes() : [], mapUris);
    }
};

const getScenesUris = () => {
    const mapUris = {};
    getMapUris(scenes(), mapUris);
    return mapUris;
};

export const mapUriToObjScene = getScenesUris();


/**
 * Returns the entire scene tree for given deployment. Also includes empty scenes.
 *
 * @param {object} objRoute
 * @param {string} deployment
 * @returns {object?}
 */
const getSpreadingSceneTree = (objRoute, deployment, isProductionMode, showTodos) => {
    if (!objRoute.scenes) {
        return objRoute;
    }
    return {
        ...objRoute,
        scenes: objRoute.scenes({ deployment, isProductionMode, showTodos }).map(
            objChildRoute =>
                getSpreadingSceneTree(objChildRoute, deployment, isProductionMode, showTodos)
        ),
    };
};

/**
 * Returns a pruned scene tree node which doesn't include empty scenes. Returns null if the current node doesn't have
 * any nodes.
 *
 * @param {object} objRoute
 * @returns {object?}
 */
const pruneSceneTree = (objRoute) => {
    if (!objRoute.scenes) {
        return objRoute;
    }
    const prunedObjRoute = {
        ...objRoute,
        scenes: objRoute.scenes.map(
            childObjRoute =>
                pruneSceneTree(childObjRoute)
        ).filter(childObjRoute => childObjRoute),
    };
    if (!prunedObjRoute.scenes.length) {
        return null;
    }
    return prunedObjRoute;
};

/**
 * Returns a pruned scene tree which doesn't include empty scenes. Returns null if the current
 *
 * @returns {object?}
 */
export const getPrunedSceneTree = (deployment, isProductionMode, showTodos) => {
    const spreadingSceneTree = scenes({ deployment, isProductionMode, showTodos }).map(
        objRoute =>
            getSpreadingSceneTree(objRoute, deployment, isProductionMode, showTodos)
    ).filter(
        objRoute =>
            objRoute
    );
    return spreadingSceneTree.map(objRoute => pruneSceneTree(objRoute));
};

export const getFirstScene = (objRoute) => {
    if (!objRoute.scenes) {
        return objRoute;
    }
    return getFirstScene(objRoute.scenes[0]);
};

const isPrefixBreadcrumbs = (string, prefix) => {
    return (string || '').startsWith(prefix + '/') || (string || '').endsWith(prefix);
};

const addBreadcrumbs = (breadcrumbs, objRoute, location) => {
    if (objRoute.hideFromMenu || !isPrefixBreadcrumbs(location.pathname, objRoute.route.key)) {
        return;
    }
    breadcrumbs.push(objRoute);
    for (const childObjRoute of objRoute.scenes || []) {
        addBreadcrumbs(breadcrumbs, childObjRoute, location);
    }
};

export const getBreadcrumbs = ({ deployment, location, isProductionMode }) => {
    const prunedSceneTree = getPrunedSceneTree(deployment, isProductionMode);
    const breadcrumbs = [];
    for (const objRoute of prunedSceneTree) {
        addBreadcrumbs(breadcrumbs, objRoute, location);
    }
    return breadcrumbs;
};

export default scenes;
