/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';


const isIdsPath = path => path[path.length - 1] === '$ids';

export const isRowReorderDiff = (diff: HlcfgDiff) => {
    return isIdsPath(diff.hlcfgDescriptivePath);
};

const descriptivePathIsRow = path => path[path.length - 2] === '$byId';

export const isRowAddDiff = (diff: HlcfgDiff) => {
    return isAddDiff(diff) && descriptivePathIsRow(diff.hlcfgDescriptivePath);
};

export const isRowDeleteDiff = (diff: HlcfgDiff) => {
    return isDeleteDiff(diff) && descriptivePathIsRow(diff.hlcfgDescriptivePath);
};

const isDeleteDiff = (diff: HlcfgDiff) => diff.fromValue !== undefined && diff.toValue === undefined;

export const isAddDiff = (diff: HlcfgDiff) => diff.fromValue === undefined && diff.toValue !== undefined;
