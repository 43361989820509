/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { hot } from 'react-hot-loader/root.js';
import { connect } from 'react-redux';

import { getSessionTerminateReason } from '~frontendRoot/ducks/sessionsManagement/index.js';
import BackgroundSvg from '~frontendComponents/BackgroundSvg/index.ts';

import DevelStatusBar from '../../components/DevelStatusBar/index.js';
import Loader from '../../components/Loader/index.js';
import Message from '../../components/Message/index.js';
import { getSelectedLanguage, setSelectedLanguage } from '../../ducks/language/index.js';
import { getIsErrorStatusCode, getStatusCode } from '../../ducks/statusCode/index.js';
import {
    getIsLoggedIn, getIsLoginFormVisible, loginClearError, loginFormShow, getIsResetPasswordErrorMatch,
    getIsResetPasswordErrorLong, getLogintTime
} from '../../ducks/userAuthentication/ducks/login.js';
import LoginContent from './components/LoginContent/index.js';
import LoginFormWrap from './components/LoginFormWrap/index.js';
import { getIsGuiLoading } from '../../ducks/userAuthentication/ducks/guiLoading.js';


const BlurTop = ({ isErrorStatusCode, isGuiLoading, isLoggedIn, logoutReason }) => (
    <div
        className={classNames(
            'loginPage__blur loginPage__blur--top',
            {
                'loginPage__blur--fullHeight': isErrorStatusCode || isLoggedIn || isGuiLoading || logoutReason
            }
        )}
    >
        {isGuiLoading ?
            <Loader
                className="loader--horizontal"
                label={<Message message="login:loader.isLoading" />}
            /> :
            null
        }
    </div>
);

BlurTop.propTypes = {
    isErrorStatusCode: PropTypes.bool,
    isGuiLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    logoutReason: PropTypes.string,
};


const BlurBottom = () => (
    <div className="loginPage__blur loginPage__blur--bottom" />
);


@hot
@connect(
    state => ({
        isErrorStatusCode: getIsErrorStatusCode(state),
        terminateReason: getSessionTerminateReason(state),
        isGuiLoading: getIsGuiLoading(state),
        isLoggedIn: getIsLoggedIn(state),
        isLoginFormVisible: getIsLoginFormVisible(state),
        isResetPasswordErrorMatch: getIsResetPasswordErrorMatch(state),
        isResetPasswordErrorLong: getIsResetPasswordErrorLong(state),
        selectedLanguage: getSelectedLanguage(state),
        statusCode: getStatusCode(state),
        loginTime: getLogintTime(state),
    }),
    {
        doLoginFormShow: loginFormShow,
        doLoginClearError: loginClearError,
        doSetSelectedLanguage: setSelectedLanguage,
    }
) class LoginPage extends Component {
    static get propTypes() {
        return {
            doLoginFormShow: PropTypes.func,
            doSetSelectedLanguage: PropTypes.func,
            isGuiLoading: PropTypes.bool,
            isLoginFormVisible: PropTypes.bool,
            isLoggedIn: PropTypes.bool,
            isErrorStatusCode: PropTypes.bool,
            isResetPasswordErrorLong: PropTypes.bool,
            isResetPasswordErrorMatch: PropTypes.bool,
            selectedLanguage: PropTypes.string,
            statusCode: PropTypes.number,
            terminateReason: PropTypes.string,
        };
    }

    render() {
        const {
            doLoginFormShow, doSetSelectedLanguage, isErrorStatusCode, isGuiLoading, isLoginFormVisible, isLoggedIn,
            isResetPasswordErrorMatch, isResetPasswordErrorLong, selectedLanguage, statusCode, terminateReason
        } = this.props;
        return (
            <div className="page loginPage">
                <DevelStatusBar />

                <main className={classNames({
                    'loginPage__main': true,
                    'loginPage__main--error': isResetPasswordErrorMatch || isResetPasswordErrorLong,
                })}
                >

                    <LoginFormWrap
                        doSetSelectedLanguage={doSetSelectedLanguage}
                        selectedLanguage={selectedLanguage}
                    />

                    <LoginContent
                        doLoginFormShow={doLoginFormShow}
                        isErrorStatusCode={isErrorStatusCode}
                        isGuiLoading={isGuiLoading}
                        isLoggedIn={isLoggedIn}
                        isLoginFormVisible={isLoginFormVisible}
                        statusCode={statusCode}
                        terminateReason={terminateReason}
                    />

                </main>

                <aside className="loginPage__aside">
                    <BackgroundSvg className="loginPage__background" />
                </aside>

                <BlurBottom />

                <BlurTop
                    isErrorStatusCode={isErrorStatusCode}
                    isGuiLoading={isGuiLoading}
                    isLoggedIn={isLoggedIn}
                />


            </div>
        );
    }
}

export default LoginPage;
