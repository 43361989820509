/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const VPN_PATH = '/configuration/services/vpnServer';
export const IPSEC_PATH = '/configuration/services/ipsec';
export const DHCP_PATH = '/configuration/services/dhcpServer';
export const QOS_PATH = '/configuration/network/QoS';
export const NETWORKSERVICES_MONITORING_SCENE_PATH = '/configuration/services/remoteAccess';
export const PROTECTION_PROXY_PROFILES_SCENE_PATH = '/protection/proxy/profiles';
export const PROTECTION_WAF_PROFILES_SCENE_PATH = '/protection/waf/profiles';
