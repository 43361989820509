/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy.tsx';
import { SURICATA_LICENSE_REQUIRED } from '~frontendConstants/constants.ts';

import { createScene } from '../../../../lib/scenes.js';


const Suricata = createLazy(() => import('./components/Suricata/index.js'));
const SuricataVariables = createLazy(() => import('./components/SuricataVariables/index.js'));


export const PROTECTION_IPS_SETTINGS_PATH = '/protection/ips/settings';
export const PROTECTION_IPS_VARIABLES_PATH = '/protection/ips/variables';


const PROTECTION_IPS_SETTINGS_SCENE = createScene({
    component: Suricata,
    path: PROTECTION_IPS_SETTINGS_PATH,
    title: 'scenes:scenes.Protection.scenes.Ips.scenes.Settings.title',
    requiredLicensePermission: SURICATA_LICENSE_REQUIRED,
});

const PROTECTION_IPS_VARIABLES_SCENE = createScene({
    component: SuricataVariables,
    path: PROTECTION_IPS_VARIABLES_PATH,
    title: 'scenes:scenes.Protection.scenes.Ips.scenes.Variables.title',
    requiredLicensePermission: SURICATA_LICENSE_REQUIRED,
});


const scenes = () => {
    return [
        PROTECTION_IPS_SETTINGS_SCENE,
        PROTECTION_IPS_VARIABLES_SCENE
    ];
};

export default scenes;
