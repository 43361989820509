/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const filterObjectCustomManipulator = {};

const getNewValue = function(customFilterId) {
    return customFilterId ?
        { customFilterId: customFilterId } :
        {};
};

filterObjectCustomManipulator.createNewFilterObject = function(
    columnName, customFilterId
) {
    return {
        selected: getNewValue(customFilterId),
    };
};

filterObjectCustomManipulator.addValueToFilter = null;

filterObjectCustomManipulator.removeValueFromFilter = null;

const identity = item => item;

const serializeRecursive = function(reporterTemplates, filterContainerManipulator, customFilterStorage, isNegated) {
    isNegated = !!isNegated;
    return function(selected) {
        if (!selected) {
            return;
        }
        const params = selected.of === 'filters' ?
            filterContainerManipulator.getReporterParams(
                customFilterStorage, reporterTemplates, selected.filters
            ) :
            selected.children.map(serializeRecursive(
                reporterTemplates,
                filterContainerManipulator,
                customFilterStorage,
                false
            )).filter(identity);
        if (!params || !params.length) {
            return undefined;
        }
        return {
            type: selected.type === 'or' ? 'OR' : 'AND',
            neg: isNegated !== (selected.type === 'nand'),
            params: params
        };
    };
};

filterObjectCustomManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, filterContainerManipulator, filterObject
) {
    if (filterObject.isDisabled) {
        return undefined;
    }
    const customFilterObject = customFilterStorage.idMap[
        filterObject.selected.customFilterId
    ];
    if (!customFilterObject) {
        return undefined;
    }
    return serializeRecursive(
        reporterTemplates,
        filterContainerManipulator,
        customFilterStorage,
        filterObject.isNegated
    )(customFilterObject.customFilter);
};

export default filterObjectCustomManipulator;
