/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
const SET_STATUS_CODE = 'ak/statusCode/SET_STATUS_CODE';
const SET_API_ERROR = 'ak/statusCode/SET_API_ERROR';


// initial state
const initialState = {
    statusCode: 200,
    apiError: null,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_STATUS_CODE:
        return { ...state, statusCode: action.statusCode };
    case SET_API_ERROR:
        return { ...state, apiError: action.apiError };
    default:
        return state;
    }
};

export default reducer;


// actions creators
export const setStatusCode = statusCode => ({ type: SET_STATUS_CODE, statusCode });
export const setApiError = apiError => ({ type: SET_API_ERROR, apiError });


// data accessors
const getRootState = rootState => rootState.statusCode;
export const getStatusCode = rootState => getRootState(rootState).statusCode;
export const getApiError = rootState => getRootState(rootState).apiError;
export const getIsErrorStatusCode = rootState => getStatusCode(rootState) !== 200;


// side effects
export const sagas = [];
