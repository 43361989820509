/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { createReducer } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { call, put, takeLatest, } from '~commonLib/reduxSagaEffects.ts';


import { getApiError } from '../../lib/apiUtils.ts';

// actions
const SET_PRODUCT_VERSION = 'ak/systemInfo/SET_PRODUCT_VERSION';
const SET_HARDWARE_INFO = 'ak/systemInfo/SET_HARDWARE_INFO';
const SET_HOSTNAME = 'ak/systemInfo/SET_HOSTNAME';
const SET_HARDWARE_INFO_MODAL_OPEN = 'ak/systemInfo/SET_HARDWARE_INFO_MODAL_OPEN';
const DO_REBOOT = 'ak/systemInfo/DO_REBOOT';
const DO_SHUTDOWN = 'ak/systemInfo/DO_SHUTDOWN';
const DO_SHUTDOWN_SUCCESSS = 'ak/systemInfo/DO_SHUTDOWN_SUCCESS';
const DO_SHUTDOWN_ERROR = 'ak/systemInfo/DO_SHUTDOWN_ERROR';
const DO_REBOOT_SUCCESSS = 'ak/systemInfo/DO_REBOOT_SUCCESS';
const DO_REBOOT_ERROR = 'ak/systemInfo/DO_REBOOT_ERROR';
const TEST_LICENSE = 'ak/systemInfo/TEST_LICENSE';
const TEST_LICENSE_SUCCESS = 'ak/systemInfo/TEST_LICENSE_SUCCESS';
const TEST_LICENSE_ERROR = 'ak/systemInfo/TEST_LICENSE_ERROR';
const TEST_CLEAR = 'ak/systemInfo/TEST_CLEAR';


// initialState
const initialState = {
    productVersion: '',
    productVersionRaw: '',
    hardwareInfo: {},
    shutdownModal: false,
    rebootModal: false,
    isShutingdown: false,
    isRebooting: false,
    error: {},
    hostname: '',
    isLoadingTest: false,
    testError: null,
    testResult: null,
};

// reducer
const reducer = createReducer(initialState, {
    [SET_PRODUCT_VERSION]: (state, action) => {
        state.productVersion = action.payload.pretty;
        state.productVersionRaw = action.payload.raw;
    },
    [SET_HARDWARE_INFO]: (state, action) => {
        state.hardwareInfo = action.payload;
    },
    [SET_HOSTNAME]: (state, action) => {
        state.hostname = action.payload;
    },
    [SET_HARDWARE_INFO_MODAL_OPEN]: (state, action) => {
        state[action.payload.name] = action.payload.value;
    },
    [DO_REBOOT_SUCCESSS]: (state) => {
        state.isRebooting = true;
    },
    [DO_SHUTDOWN_SUCCESSS]: (state) => {
        state.isShutingdown = true;
    },
    [DO_SHUTDOWN_ERROR]: (state, action) => {
        state.error = action.payload;
    },
    [DO_REBOOT_ERROR]: (state, action) => {
        state.error = action.payload;
    },
    [TEST_LICENSE]: (state) => {
        state.isLoadingTest = true;
        state.testError = null;
        state.testResult = null;
    },
    [TEST_LICENSE_ERROR]: (state, action) => {
        state.isLoadingTest = false;
        state.testError = action.payload;
    },
    [TEST_LICENSE_SUCCESS]: (state, action) => {
        state.isLoadingTest = false;
        state.testResult = action.payload;
    },
    [TEST_CLEAR]: (state) => {
        state.isLoadingTest = false;
        state.testResult = null;
        state.testError = null;
    }
});

export default reducer;

// data accessors
const getState = state => state.systemInfo;
export const getAkVersion = state => getState(state).productVersion;
export const getRawAkVersion = state => getState(state).productVersionRaw;
export const getHardwareInfo = state => getState(state).hardwareInfo;
export const getHostname = state => getState(state).hostname;
export const getIsLoadingTest = state => getState(state).isLoadingTest;
export const getTestResult = state => getState(state).testResult;
export const getTestError = state => getState(state).testError;

export const getDisksDevices = createSelector(
    state => getState(state).hardwareInfo,
    hardwareInfo => (hardwareInfo?.disks || []).map(item => ({
        filter: item.device,
    }))
);


export const getHardwareInfoModalOpen = (state, type) => getState(state)[type];


// action creators
const setter = type => payload => ({ type, payload });
export const setProductVersion = setter(SET_PRODUCT_VERSION);
export const setHardwareInfo = setter(SET_HARDWARE_INFO);
export const setHostname = setter(SET_HOSTNAME);
export const doShutdown = setter(DO_SHUTDOWN);
export const doReboot = setter(DO_REBOOT);

export const doRebootSuccess = setter(DO_REBOOT_SUCCESSS);
export const doRebootError = setter(DO_REBOOT_ERROR);
export const doShutdownSuccess = setter(DO_SHUTDOWN_SUCCESSS);
export const doShutdownError = setter(DO_SHUTDOWN_ERROR);

export const doClearTest = setter(TEST_CLEAR);

export const testLicenseSuccess = setter(TEST_LICENSE_SUCCESS);
export const testLicenseError = setter(TEST_LICENSE_ERROR);
export const testLicense = setter(TEST_LICENSE);


export const setHardwareInfoModalOpen = setter(SET_HARDWARE_INFO_MODAL_OPEN);


export const apiCallPostDoShutdown = async () => {
    return axios.post('/api/device/shutdown');
};

export const apiCallPostDoReboot = async () => {
    return axios.post('/api/device/reboot');
};

export const apiCallGetTestLicense = async () => {
    return axios.get('/api/device/testLicense');
};

const workerDoShutdown = function* () {
    try {
        const { data } = yield call(apiCallPostDoShutdown);
        yield put(doShutdownSuccess(data));
    } catch (error) {
        yield put(doShutdownError(getApiError(error)));
    }
};

const workerDoReboot = function* () {
    try {
        const { data } = yield call(apiCallPostDoReboot);
        yield put(doRebootSuccess(data));
    } catch (error) {
        yield put(doRebootError(getApiError(error)));
    }
};

const workerTestLicense = function* () {
    try {
        const { data } = yield call(apiCallGetTestLicense);
        yield put(testLicenseSuccess(data));
    } catch (error) {
        yield put(testLicenseError(getApiError(error)));
    }
};

// sagas
export const sagas = [
    takeLatest(DO_SHUTDOWN, workerDoShutdown),
    takeLatest(DO_REBOOT, workerDoReboot),
    takeLatest(TEST_LICENSE, workerTestLicense),
];
