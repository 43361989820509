/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Transforms a string from camelCase to snake_case.
 *
 * @memberof objectKeysToSnakeCase
 * @param {string} string - string to be transformed
 * @returns {string}
 */
const toSnakeCase = function(string) {
    return string.replace(/([A-Z])/g, function(char) {
        return '_' + char.toLowerCase();
    });
};

const objectKeysToSnakeCase = function(params) {
    if (typeof params !== 'object' || params === null) {
        return params;
    }
    if (Array.isArray(params)) {
        return params.map(function(parameter) {
            return objectKeysToSnakeCase(parameter);
        });
    }
    const ret = {};
    for (const key in params) {
        ret[toSnakeCase(key)] = objectKeysToSnakeCase(params[key]);
    }
    return ret;
};

export default objectKeysToSnakeCase;
