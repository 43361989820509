/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { expectTypeOf } from 'expect-type';

import { emptyObjectSchema, integer, object, SchemaTestHelper } from '~commonLib/schemaUtils.ts';


export const netportSimpleSchema = object({
    port: integer(),
}, [ 'port' ]);

export const netportRangeSchema = object({
    from: netportSimpleSchema,
    to: netportSimpleSchema,
}, [ 'from', 'to' ]);


expectTypeOf(<SchemaTestHelper<typeof netportObjSchema>>{}).not.toMatchTypeOf<'fromSchNever'|'not schema'>();
export const netportObjSchema = <const>{
    anyOf: [
        netportSimpleSchema,
        netportRangeSchema,
        emptyObjectSchema,
    ]
};
