/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { call, cancel, put, select, take, takeEvery, takeLatest, fork } from '~commonLib/reduxSagaEffects.ts';
import {
    getCanNotBeAutoSync,
    getIsLoadingHealthIssues,
    isNodeUnreachable,
} from '~frontendDucks/clusterSetup/index.js';
import {
    ActionSequenceFnStartGenerator, errorAdder, getCommonActionSequenceGetters, getSequenceOpener,
    getWorkerActionSequence,
    InitialStateByNode,
    progressAdder, successAdder,
} from '~frontendLib/actionSequence/actionSequence.ts';
import { ACTION_SEQUENCE_CONFIGURATION_ACTIVATION } from '~sharedConstants/index.ts';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib.ts';
import {
    EVENT_PREFIX_CFG_APPLY,
    EVENT_PREFIX_CFG_GENERATE, EVENT_PREFIX_CFG_PROCESS_DEPENDENCIES,
    EVENT_PREFIX_CFG_VALIDATE,
    EVENT_PREFIX_CFG_VERIFY,
    EVENT_PREFIX_COMPONENT_ACTIVATATION_LIST,
    EVENT_PREFIX_COMPONENT_ACTIVATE,
    EVENT_PREFIX_FRONTEND_RELOAD,
} from '~commonLib/constants.ts';
import {
    ActionSequenceRequestActionPayloadType
} from '~frontendLib/actionSequence/types.ts';
import { ActionSequenceInfo } from '~sharedLib/types.ts';
import { ACTIVATE_ON_ONE_WARNING, EMPTY_REDUX_REDUCER, NEED_RESET_CONFIGURATION, NODE_A_ID, NODE_B_ID,
    RECOVERY_MODE_ACTIVATION } from '~frontendRoot/constants/index.js';
import { promiseSetTimeout } from '~frontendRoot/lib/timeUtils.ts';
import { createNotification, replaceFrontendWithUpgradeMessage } from '~frontendRoot/lib/reactUtils.js';
import {
    getIsCluster,
    getIsTreeDirty,
    getNeedsSessionReset,
    getVerificationErrors,
    needsResetSessionHlcfg,
    setChangeDiffOpen
} from '~frontendDucks/hlcfgEditor/index.js';
import { backendGet } from '~frontendRoot/lib/backendApiCalls.ts';
import { getRecoveryMode } from '~frontendDucks/backup/index.js';
import { retryFnNTimesWithInterval } from '~commonLib/functionUtils.ts';
import { queryClient } from '~frontendQueries/client.ts';
import { queries } from '~frontendQueries/queries.ts';

import { getApiError } from '../../lib/apiUtils.ts';
import { storeHlcfg,  workerSetBothHlcfgs,
    workerSerializeHlcfg,
} from '../hlcfgEditor/index.js';
import { setModalState } from '../modals/index.js';
import { workerGetNodes } from '../actionSequence/index.ts';


export const ACTION_STEP_CFG_VERIFY = 'cfgVerify';
export const ACTION_STEP_CFG_GENERATE = 'cfgGenerate';
export const ACTION_STEP_CFG_VALIDATE = 'cfgValidate';
export const ACTION_STEP_CFG_APPLY = 'cfgApply';
export const ACTION_STEP_CFG_PROCESS_DEPENDENCIES = 'cfgProcessDependencies';
export const ACTION_STEP_COMPONENTS_ACTIVATE = 'componentsActivate';
export const ACTION_STEP_COMPONENTS_ACTIVATION_LIST = 'componentsActivationList';
export const ACTION_STEP_FRONTEND_RELOAD = 'frontendReload';

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],

    commitMessage: '',
    replayTimeCreated: '',
    replayUserName: '',
    isOpen: false,
    replayingActionSequenceId: '',
    error: null,
    isLoadingPrepare: false
};

// data accessors
const getState = (rootState): typeof initialState => rootState.cfgActivation;

const commonGetters = getCommonActionSequenceGetters(getState);
const { getNodeState } = commonGetters;
export const {
    getIsOpen: getCfgActivationIsOpen,
    getIsLoading: getCfgActivationIsLoading,
    getIsAborted: getCfgActivationIsAborted,
    getError: getCfgActivationError,
    getProgress: getCfgActivationProgress,
} = commonGetters;

export const getCommitMessage = rootState => getState(rootState).commitMessage;
export const getReplayTimeCreated = rootState => getState(rootState).replayTimeCreated;
export const getReplayUserName = rootState => getState(rootState).replayUserName;
export const getReplayingActionSequenceId = rootState => getState(rootState).replayingActionSequenceId;

// these getters are hacky, but i'm keeping the interface to keep refactoring scope manageable
export const getProgressnodeA = rootState => getNodeState(rootState, NODE_A_ID).progress;
export const getProgressnodeB = rootState => getNodeState(rootState, NODE_B_ID).progress;

export const getCfgActivationPreparation = rootState => getState(rootState).isLoadingPrepare;

const cfgActivationSlice = createSlice({
    name: 'ak/cfgActivation',
    initialState,
    reducers: {
        setCommitMessage: (state, action: PayloadAction<{value: string}>) => {
            state.commitMessage = action.payload.value;
        },
        cfgActivationPrepare: (state) => {
            state.isLoadingPrepare = true;
        },
        cfgActivationPrepareEnded: (state) => {
            state.isLoadingPrepare = false;
        },
        cfgActivationRequest: getSequenceOpener(initialState, 'commitMessage'),

        cfgActivationAbort: (state) => {
            Object.keys(state.byNode).forEach(node => {
                state.byNode[node].isAborted = true;
            });
        },


        cfgActivationClose: (state) => {
            state.isOpen = false;
            state.commitMessage = '';
        },


        cfgActivationSuccess: successAdder,
        cfgActivationError: errorAdder,

        cfgVerifyStarted: progressAdder(ACTION_STEP_CFG_VERIFY, getStateProgressStarted),
        cfgVerifyProgress: progressAdder(ACTION_STEP_CFG_VERIFY, getStateProgressProgress),
        cfgVerifySuccess: progressAdder(ACTION_STEP_CFG_VERIFY, getStateProgressSuccess),
        cfgVerifyFailure: progressAdder(ACTION_STEP_CFG_VERIFY, getStateProgressFailure),

        cfgGenerateStarted: progressAdder(ACTION_STEP_CFG_GENERATE, getStateProgressStarted),
        cfgGenerateProgress: progressAdder(ACTION_STEP_CFG_GENERATE, getStateProgressProgress),
        cfgGenerateSuccess: progressAdder(ACTION_STEP_CFG_GENERATE, getStateProgressSuccess),
        cfgGenerateFailure: progressAdder(ACTION_STEP_CFG_GENERATE, getStateProgressFailure),

        cfgValidateStarted: progressAdder(ACTION_STEP_CFG_VALIDATE, getStateProgressStarted),
        cfgValidateProgress: progressAdder(ACTION_STEP_CFG_VALIDATE, getStateProgressProgress),
        cfgValidateSuccess: progressAdder(ACTION_STEP_CFG_VALIDATE, getStateProgressSuccess),
        cfgValidateFailure: progressAdder(ACTION_STEP_CFG_VALIDATE, getStateProgressFailure),

        cfgApplyStarted: progressAdder(ACTION_STEP_CFG_APPLY, getStateProgressStarted),
        cfgApplyProgress: progressAdder(ACTION_STEP_CFG_APPLY, getStateProgressProgress),
        cfgApplySuccess: progressAdder(ACTION_STEP_CFG_APPLY, getStateProgressSuccess),
        cfgApplyFailure: progressAdder(ACTION_STEP_CFG_APPLY, getStateProgressFailure),


        cfgProcessDepsStarted: progressAdder(ACTION_STEP_CFG_PROCESS_DEPENDENCIES, getStateProgressStarted),
        cfgProcessDepsProgress: progressAdder(ACTION_STEP_CFG_PROCESS_DEPENDENCIES, getStateProgressProgress),
        cfgProcessDepsSuccess: progressAdder(ACTION_STEP_CFG_PROCESS_DEPENDENCIES, getStateProgressSuccess),
        cfgProcessDepsFailure: progressAdder(ACTION_STEP_CFG_PROCESS_DEPENDENCIES, getStateProgressFailure),

        componentsActivateStarted: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATE, getStateProgressStarted),
        componentsActivateProgress: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATE, getStateProgressProgress),
        componentsActivateSuccess: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATE, getStateProgressSuccess),
        componentsActivateFailure: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATE, getStateProgressFailure),

        componentsActivationListStarted: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATION_LIST, getStateProgressStarted),
        componentsActivationListProgress:
            progressAdder(ACTION_STEP_COMPONENTS_ACTIVATION_LIST, getStateProgressProgress),
        componentsActivationListSuccess: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATION_LIST, getStateProgressSuccess),
        componentsActivationListFailure: progressAdder(ACTION_STEP_COMPONENTS_ACTIVATION_LIST, getStateProgressFailure),

        frontendReloadStarted: progressAdder(ACTION_STEP_FRONTEND_RELOAD, getStateProgressStarted),
        frontendReloadProgress: progressAdder(ACTION_STEP_FRONTEND_RELOAD, getStateProgressProgress),
        frontendReloadSuccess: progressAdder(ACTION_STEP_FRONTEND_RELOAD, getStateProgressSuccess),
        frontendReloadFailure: progressAdder(ACTION_STEP_FRONTEND_RELOAD, getStateProgressFailure),

        startCheckingAvailability: EMPTY_REDUX_REDUCER,
        stopCheckingAvailability: EMPTY_REDUX_REDUCER
    },
});

const actions = cfgActivationSlice.actions;
export const {
    cfgActivationAbort,
    cfgActivationPrepare,
    cfgActivationPrepareEnded,
    cfgActivationRequest,
    cfgActivationError,
    cfgActivationSuccess,
    setCommitMessage,
    cfgActivationClose,
    componentsActivateStarted,
    startCheckingAvailability,
    stopCheckingAvailability
} = actions;
export default cfgActivationSlice.reducer;


// action creators


// side effects
const workerCfgVerify = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.cfgVerifyStarted,
    actionStepProgressed: actions.cfgVerifyProgress,
    actionStepSucceeded: actions.cfgVerifySuccess,
    actionStepFailed: actions.cfgVerifyFailure,
    eventPrefix: EVENT_PREFIX_CFG_VERIFY,
});

const workerCfgGenerate = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.cfgGenerateStarted,
    actionStepProgressed: actions.cfgGenerateProgress,
    actionStepSucceeded: actions.cfgGenerateSuccess,
    actionStepFailed: actions.cfgGenerateFailure,
    eventPrefix: EVENT_PREFIX_CFG_GENERATE,
});

const workerCfgValidate = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.cfgValidateStarted,
    actionStepProgressed: actions.cfgValidateProgress,
    actionStepSucceeded: actions.cfgValidateSuccess,
    actionStepFailed: actions.cfgValidateFailure,
    eventPrefix: EVENT_PREFIX_CFG_VALIDATE,
});

const workerCfgApply = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.cfgApplyStarted,
    actionStepProgressed: actions.cfgApplyProgress,
    actionStepSucceeded: actions.cfgApplySuccess,
    actionStepFailed: actions.cfgApplyFailure,
    eventPrefix: EVENT_PREFIX_CFG_APPLY,
});

const workerCfgProcessDependencies = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.cfgProcessDepsStarted,
    actionStepProgressed: actions.cfgProcessDepsProgress,
    actionStepSucceeded: actions.cfgProcessDepsSuccess,
    actionStepFailed: actions.cfgProcessDepsFailure,
    eventPrefix: EVENT_PREFIX_CFG_PROCESS_DEPENDENCIES,
});

const workerComponentsActivate = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.componentsActivateStarted,
    actionStepProgressed: actions.componentsActivateProgress,
    actionStepSucceeded: actions.componentsActivateSuccess,
    actionStepFailed: actions.componentsActivateFailure,
    eventPrefix: EVENT_PREFIX_COMPONENT_ACTIVATE,
});

const workerComponentsActivationList = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.componentsActivationListStarted,
    actionStepProgressed: actions.componentsActivationListProgress,
    actionStepSucceeded: actions.componentsActivationListSuccess,
    actionStepFailed: actions.componentsActivationListFailure,
    eventPrefix: EVENT_PREFIX_COMPONENT_ACTIVATATION_LIST,
});

const workerFrontendReload = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.frontendReloadStarted,
    actionStepProgressed: actions.frontendReloadProgress,
    actionStepSucceeded: actions.frontendReloadSuccess,
    actionStepFailed: actions.frontendReloadFailure,
    eventPrefix: EVENT_PREFIX_FRONTEND_RELOAD,
});

const cfgActivationStart: ActionSequenceFnStartGenerator<ActionSequenceRequestActionPayloadType> =
    function* ({ action, breakLock = false }) {

        const commitMessage = yield select(getCommitMessage);
        const replayingActionSequenceId = action.payload.replayingActionSequenceId;
        if (!replayingActionSequenceId) {
            const hlcfgTree = yield* workerSerializeHlcfg();
            yield call(storeHlcfg, hlcfgTree);
        }
        const nodes = action.payload.nodes;

        const response = yield call(
            axios.post,
            '/api/cfg/activateConfiguration', { commitMessage, breakLock, replayingActionSequenceId, nodes }
        );

        const { data } = response as AxiosResponse<ActionSequenceInfo>;
        return data;
    };

const workerCfgActivation = getWorkerActionSequence({
    actionSequenceSucceeded: actions.cfgActivationSuccess,
    actionSequenceFailed: actions.cfgActivationError,
    fnStart: cfgActivationStart,
    actionSequenceType: ACTION_SEQUENCE_CONFIGURATION_ACTIVATION,
    workers: [
        workerCfgVerify,
        workerFrontendReload,
        workerCfgGenerate,
        workerCfgProcessDependencies,
        workerCfgValidate,
        workerCfgApply,
        workerComponentsActivationList,
        workerComponentsActivate,
    ],
    close: cfgActivationClose
});


const getApiAreYouAlive = async () => {
    return retryFnNTimesWithInterval({
        fn: async () => axios.get(`${window.location.origin}/are-you-alive`, { timeout: 1000 }),
        interval: 1000,
        times: 10,
    });
};

const workerStartCheckingAvailability = function* (action) {
    while (true) {
        yield call(promiseSetTimeout, { waitTime: 3000 });
        try {
            yield call(getApiAreYouAlive);
        } catch (error) {
            replaceFrontendWithUpgradeMessage({
                first: { message: 'widgets:ActivationReload.title' },
                second: { message: 'widgets:ActivationReload.desc' },
                third: undefined, //make TS happy
                addrs: action.payload,
            });
            return;
        }
    }
};

const workerCheckAvailability = function* (action) {
    const checking = yield fork(workerStartCheckingAvailability, action);
    // wait for the user stop action
    yield take([ stopCheckingAvailability.type, cfgActivationSuccess.type, cfgActivationError.type ]);
    // user stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(checking);
};

/**
 * This saga is called when the user clicks on the "Activate" button, but before the action sequence is started.
 * Use this saga to prepare the action sequence or decide if some warning are in place,
 * e.g. by calling the API to check init hlcfg.
 */
const workerCfgActivationPrepare = function* () {
    try {
        const verificationErrors = yield select(getVerificationErrors);
        const isLoadingHealthIssues = yield select(getIsLoadingHealthIssues);
        const isTreeDirty = yield select(getIsTreeDirty);
        if (verificationErrors.length > 0 || isLoadingHealthIssues || isTreeDirty) {
            return;
        }

        const isRecoveryMode = yield select(getRecoveryMode);

        if (isRecoveryMode) {
            yield put(setModalState({ modal: RECOVERY_MODE_ACTIVATION, value: true }));
            yield put(cfgActivationPrepareEnded());
            return;
        }

        const needsSessionReset = yield select(getNeedsSessionReset);

        if (needsSessionReset) {
            yield put(setModalState({ modal: NEED_RESET_CONFIGURATION, value: true }));
            yield put(cfgActivationPrepareEnded());

            return;
        }
        const { data } = yield call(getIsCurrentInitHlcfgStored);

        if (!data.isOnDisk) {
            yield put(needsResetSessionHlcfg(true));
            yield put(setModalState({ modal: NEED_RESET_CONFIGURATION, value: true }));
            yield put(cfgActivationPrepareEnded());
            return;
        }
        const canNotBeAutoSynced = yield select(getCanNotBeAutoSync);

        const nodes = yield workerGetNodes(canNotBeAutoSynced);
        const isCluster = yield select(getIsCluster);
        const nodeUnreachable = yield select(isNodeUnreachable);
        const shouldDoClusterActivation = isCluster && !canNotBeAutoSynced && !nodeUnreachable;

        // if we are in cluster but we can not do cluster activation, we need to show warning about
        // activating only on one node
        if (isCluster && !shouldDoClusterActivation) {
            yield put(setModalState({
                modal: ACTIVATE_ON_ONE_WARNING, value: true, nodes, specialValues: { nodes } }));
        } else {
            //HERE STARTS ACTIVATION
            yield put(cfgActivationPrepareEnded());
            yield put(cfgActivationRequest({ isOpen: true, nodes }));
            yield put(setChangeDiffOpen(false));
        }

    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger'
        });
        yield put(cfgActivationError(getApiError(error)));
    }
};

const workerRefetchLicense = function* () {
    yield call(() => queryClient.refetchQueries(queries.system.licenseInfo));
};

const getIsCurrentInitHlcfgStored = backendGet('/cfg/isCurrentInitHlcfgStoredOnDisk');

export const getSagas = () => [
    takeEvery(actions.cfgActivationRequest.type, workerCfgActivation),
    takeEvery(actions.cfgActivationPrepare.type, workerCfgActivationPrepare),
    takeEvery(actions.frontendReloadSuccess.type, workerSetBothHlcfgs),
    takeLatest(actions.startCheckingAvailability.type, workerCheckAvailability),
    takeEvery(actions.cfgActivationClose.type, workerRefetchLicense),
];
