/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';
import React from 'react';

import { getIsProductionMode } from '~frontendDucks/constantInformation/constantInformation.js';
import { getRawAkVersion } from '~frontendDucks/systemInfo/systemInfo.js';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { KERNUN_BUILDNUM } from '~sharedConstants/constants.ts';

import Message from './Message/Message.tsx';


export const WrongGuiVersionBar = () => {
    const [ hidden, setHidden ] = useBoolean();
    const isProduction = useSelector(getIsProductionMode);
    const productVersion = useSelector(getRawAkVersion);
    if (!isProduction) {
        return null;
    }
    if (productVersion === KERNUN_BUILDNUM) {
        return null;
    }
    if (hidden) {
        return null;
    }

    return (
        <div
            className="develStatusBar"
            onClick={setHidden.on}
        >
            <Message message="navbar:wrongGuiVersion" />
        </div>
    );

};
