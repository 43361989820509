/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';


// initial state
const initialState = {};


// reducer
const reducer = (state = initialState) => {
    return state;
};

export default reducer;


// data accessors
export const getI18nStore = rootState => rootState.i18next;


// side effects
export const sagas = [];


const notifiedMessages = {};

// notify the backend that a message is missing
export const notifyMissingMessage = (lng, ns, key, fallbackValue) => {
    if (window.Cypress?.testingType === 'component') {
        return;
    }
    if (!notifiedMessages[lng]) {
        notifiedMessages[lng] = {};
    }
    if (!notifiedMessages[lng][ns]) {
        notifiedMessages[lng][ns] = {};
    }
    if (notifiedMessages[lng][ns][key]) {
        return;
    }
    notifiedMessages[lng][ns][key] = true;
    Axios.post(
        '/api/i18n/missingMessage',
        { lng, ns, key, fallbackValue }
    );
};
