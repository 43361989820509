/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBDropdownMenu } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';


import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.tsx';
import { getHlcfgDiff } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import { CONFIRM_LOGOUT_MODAL } from '~frontendRoot/constants/index.js';
import { getIsRouteGetter } from '~frontendDucks/userAuthentication/userAuthentication.js';
import { useLicenseInfo } from '~frontendQueries/system/hooks.ts';
import { isSceneLocked } from '~frontendRoot/scenes/ObjRouteContext.ts';

import MenuDropdown from '../MenuDropdown/index.js';
import MenuDropdownDevice from '../MenuDropdownDevice/index.js';
import MenuDropdownItem from '../MenuDropdownItem/index.js';
import MenuDropdownToggle from '../MenuDropdownToggle/index.js';


const makeMapStateToProps = () => {
    const getIsRoute = getIsRouteGetter();
    const mapStateToProps = (state, { objRoute }) => {
        return {
            isRoute: getIsRoute(state, objRoute.route.key),
            hlcfgDiff: getHlcfgDiff(state),
        };
    };
    return mapStateToProps;
};
@connect(
    makeMapStateToProps,
    {
        doSetModalState: setModalState,
    }
)
class NavBarMenuDropdown extends Component {
    static get propTypes() {
        return {
            isRoute: PropTypes.bool.isRequired,
            objRoute: PropTypes.object.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            doLogoutRequest: PropTypes.func,
            hlcfgDiff: PropTypes.array,
            doSetModalState: PropTypes.func,
        };
    }

    resolveTitle = () => {
        const { objRoute } = this.props;
        if (!objRoute.Title) {
            return objRoute.title;
        }
        return <objRoute.Title />;
    };

    confirmLogoutRequest = () => {
        const { doSetModalState, hlcfgDiff, doLogoutRequest } = this.props;
        if (hlcfgDiff?.length) {
            doSetModalState({ modal: CONFIRM_LOGOUT_MODAL, value: true });
        }
        else {
            doLogoutRequest();
        }
    };

    render() {
        const { doLogoutRequest, objRoute, isRoute, setActiveRoute } = this.props;
        if (objRoute.isDevice) {
            return (
                <MenuDropdownDevice />
            );
        }
        if (!objRoute.scenes) {
            return (
                <WhenItemIsLocked
                    active={isRoute}
                    className={objRoute.className}
                    icon={objRoute.icon}
                    key={objRoute.route.key}
                    message={this.resolveTitle()}
                    onClick={objRoute.isLogout ? this.confirmLogoutRequest : () => setActiveRoute(objRoute)}
                    requiredLicensePermission={objRoute.requiredLicensePermission}
                    routeKey={objRoute.route.key}
                />
            );
        }

        return (
            <MenuDropdown
                key={objRoute.route.key}
                objRoute={objRoute}
                setActiveRoute={setActiveRoute}
            >
                <MenuDropdownToggle
                    icon={objRoute.icon}
                    message={this.resolveTitle()}
                    objRoute={objRoute}
                    setActiveRoute={setActiveRoute}
                />
                <MDBDropdownMenu>
                    {objRoute.scenes.map(childObjRoute => (
                        <NavBarMenuDropDownAdvanced
                            className={objRoute.className}
                            doLogoutRequest={doLogoutRequest}
                            key={childObjRoute.route.key}
                            objRoute={childObjRoute}
                            setActiveRoute={setActiveRoute}
                        />
                    ))}
                </MDBDropdownMenu>
            </MenuDropdown>
        );
    }
}

const NavBarMenuDropDownAdvanced = (props) => {
    if (props?.objRoute?.isAdvanced) {
        return (
            <WhenAdvanced>
                <NavBarMenuDropdown {...props} />
            </WhenAdvanced>
        );
    }
    return <NavBarMenuDropdown {...props} />;
};

NavBarMenuDropDownAdvanced.propTypes = {
    objRoute: PropTypes.object.isRequired,
};

const WhenItemIsLocked = ({ active, className, icon, message, onClick, routeKey, requiredLicensePermission }) => {
    const licenseInfo = useLicenseInfo().data;
    const locked = isSceneLocked(licenseInfo, requiredLicensePermission);
    return (
        <MenuDropdownItem
            active={active}
            className={className}
            icon={locked ? 'lock-outline' : icon}
            message={message}
            onClick={onClick}
            routeKey={routeKey}
        />
    );
};

WhenItemIsLocked.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    message: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    onClick: PropTypes.func,
    routeKey: PropTypes.string,
    requiredLicensePermission: PropTypes.string,
};

export default NavBarMenuDropDownAdvanced;
