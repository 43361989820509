/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getHlcfgDiff, getIsTreeDirty, getVerificationErrors } from '~frontendDucks/hlcfgEditor/index.js';
import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { Cards } from '~frontendRoot/components/Generic/index.js';
import { getActiveCardHook } from '~frontendRoot/ducks/activeCards/index.js';
import { HlcfgVerificationTranslatedItem } from '~sharedLib/types.ts';
import { getGlcfgTree } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersUtils.ts';
import { getHlcfgSchema, getIncomprehensibleHlcfgNodes } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { transformGlcfgToHlcfg } from '~frontendLib/glcfgTransformers/index.js';
import { HlcfgSchemaJSON, HlcfgSchemaTS } from '~frontendTypes/externalTypes.ts';
import { getProxyCategoriesTranslation } from '~frontendRoot/ducks/policy/index.ts';

import ActivateConf from './ActivateConf.tsx';
import DifferErrorCard from './DifferErrorCard.tsx';
import DifferCard from './DifferCard.tsx';


export const HlcfgContext = createContext({ hlcfg: {}, revisionHlcfg: {}, schema: {} } as {
    hlcfg: HlcfgSchemaTS,
    revisionHlcfg?: HlcfgSchemaTS
    schema: HlcfgSchemaJSON
});

export const CategoryTranslationContext = createContext({});

const Differ = () => {
    const diff = useSelector<any, HlcfgDiff[]>(getHlcfgDiff);
    const errors = useSelector<any, HlcfgVerificationTranslatedItem[]>(getVerificationErrors);
    const activeCard = useSelector(getActiveCardHook('changes'));
    const isLoading = useSelector<any, boolean>(getIsTreeDirty);
    const schema = useSelector<any, HlcfgSchemaJSON>(getHlcfgSchema);
    const glcfgTree = useSelector(getGlcfgTree);
    const incomprehensibleHlcfgNodes = useSelector(getIncomprehensibleHlcfgNodes);
    const hlcfgTree = transformGlcfgToHlcfg(glcfgTree, incomprehensibleHlcfgNodes);
    const categoryTranslation = useSelector(getProxyCategoriesTranslation);
    const { t } = useTranslation();
    const menuItemProps = {
        'changes': {
            icon: 'edit',
            number: diff?.length,
            isLoading,
        },
        'errors': {
            icon: 'alert-outline',
            iconColor: 'red',
            number: errors?.length
        }
    };
    return (
        <CategoryTranslationContext.Provider value={categoryTranslation}>
            <HlcfgContext.Provider value={{ hlcfg: hlcfgTree, schema }}>
                <div className="differs__Card">
                    <div>
                        <div>
                            <Cards
                                ids={[ 'changes', 'errors' ]}
                                isDragDisabled={true}
                                itemClassName="differs__CardHeaders"
                                menuItemProps={menuItemProps}
                                simple
                                type="changes"
                                wrapClassName="vpn__scene"
                            />
                        </div>
                        <div className={classNames('differs__changes')}>
                            {activeCard === 'changes' &&
                     (diff?.length ?

                         diff?.map(item => (
                             <DifferCard
                                 diff={item}
                                 key={item.hlcfgDescriptivePath + 'key'}
                                 numberOfSameDiffChanges={
                                     diff.filter(value =>
                                         value.hlcfgDescriptivePath[0] === item.hlcfgDescriptivePath[0]).length}
                             />
                         )) :
                         <div className="differs__without">
                             {`${t('widgets:differs.noChanges')}`}
                         </div>)}

                            {activeCard === 'errors' &&
                        (errors.length ?
                            errors?.map((item, index) => (
                                <DifferErrorCard
                                    error={item}
                                    key={item.hlcfgPaths.join('') + index}
                                />

                            )) :
                            <div className="differs__without">
                                {`${t('widgets:differs.noErrors')}`}
                            </div>)
                            }
                        </div>
                    </div>
                    <div className="differs__activate">
                        <ActivateConf />
                    </div>
                </div>
            </HlcfgContext.Provider>
        </CategoryTranslationContext.Provider>
    );
};

export default Differ;
