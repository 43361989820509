/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import deepCopyObjectOnPath from './deepCopyObjectOnPath.js';


/**
 * Makes a deep copy of the given object, but only of subobjects on given path.
 * Then sets the given path to a value.
 *
 * @param {object} object
 * @param {string|string[]} keysPath
 * @param {*} value
 * @returns {object} deep copied object with set value
 */
const deepCopySetValue = (object = {}, keysPath, value) => {
    if (typeof keysPath === 'string') {
        keysPath = keysPath.split('.');
    }
    const resultObject = deepCopyObjectOnPath(object, keysPath, value);
    const lastIndex = keysPath.length - 1;
    const parentNode = keysPath.reduce(
        (currNode, key, index) => {
            if (index === lastIndex) {
                return currNode;
            }
            return currNode[key];
        },
        resultObject
    );
    parentNode[keysPath[lastIndex]] = value;
    return resultObject;
};

export default deepCopySetValue;
