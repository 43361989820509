/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';
import getValue from 'get-value';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { createSelector } from 'reselect';

import { all, call, put, select, takeEvery, cancel, cancelled, fork, take, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { NODE_A_ID } from '~frontendConstants/index.js';

import { getApiError } from '../../lib/apiUtils.ts';
import { getReporterReporterTemplates } from '../reporterDbStructure/index.js';
import defaultDashboards from './defaultDashboards.js';
import defaultReports from './Reports/index.js';
import { addValueFilter, getDefaultTimeParams } from './Reports/reportsFilters/index.js';
import buildReportQuery from '../../../shared/lib/reportQuery.js';
import { getSuricataInitHomeNetVariable, getInterfacesFromConfigurationInit,
    getVpnFromConfigurationInit } from '../hlcfgEditor/index.js';

// actions
export const DASHBOARD_REFRESH_REQUEST = 'ak/reporterEntities/DASHBOARD_REFRESH_REQUEST';
export const REPORT_REFRESH_FAILURE = 'ak/reporterEntities/REPORT_REFRESH_FAILURE';
export const REPORT_REFRESH_CANCEL = 'ak/reporterEntities/REPORT_REFRESH_CANCEL';
export const REPORT_REFRESH_SUCCESS = 'ak/reporterEntities/REPORT_REFRESH_SUCCESS';
export const REPORT_REFRESH_TO_HOURS = 'ak/reporterEntities/REPORT_REFRESH_TO_HOURS';
export const DASHBOARD_REFRESH_STOP = 'ak/reporterEntities/REPORT_REQUEST_STOP';
export const SET_FILTER = 'ak/reporterEntities/SET_FILTER';
export const SET_FILTER_DEFAULT_HW_IFACES = 'ak/reporterEntities/SET_FILTER_DEFAULT_HW_IFACES';
export const CREATE_DASHBOARD_COPY = 'ak/reporterEntities/CREATE_DASHBOARD_COPY';

/** Converts server's representation of a DashboardDefinition a client's DashboardDefinition. */
const convertDashboardDefinitionFromServerToClient = (dashboardDefinition) => ({
    ...dashboardDefinition,
    reports: dashboardDefinition.reports.flatMap(reportUsage => ({
        ...reportUsage,
        clientOnly: {},
    })),
});


// initial state
const initialReports = {};
for (const reportDefinition of defaultReports.subsections) {
    initialReports[reportDefinition.report.id] = reportDefinition;
}

const initialDashboards = {};
for (const dashboardDefinition of defaultDashboards.dashboards) {
    initialDashboards[dashboardDefinition.id] = dashboardDefinition;
}

const initialDashboardDefinitionMap = {};
for (const dashboardId in initialDashboards) {
    initialDashboardDefinitionMap[dashboardId] = convertDashboardDefinitionFromServerToClient(
        initialDashboards[dashboardId]
    );
}

const initialState = {
    reportDefinitionMap: { ...initialReports },
    reportDefinitionOrder: Object.keys(initialReports),
    dashboardDefinitionMap: initialDashboardDefinitionMap,
    dashboardDefinitionOrder: Object.keys(initialDashboards),
    globalFilters: defaultDashboards.filtersDynamic,
    time: getDefaultTimeParams(),
    filters: {
        networkCards: {}
    }
    // TODO add reportRefreshMap
};


// getters
export const getLocalizedString = (localizedString, selectedLanguage) => {
    if (localizedString) {
        if (localizedString.user) {
            return localizedString.user;
        }
        if (localizedString[selectedLanguage]) {
            return localizedString[selectedLanguage];
        }
    }
    return '';
};
export const getReportId = reportUsage => getValue(reportUsage, 'id');
export const getIsReportRefreshing = reportUsage => getValue(reportUsage, 'clientOnly.isLoading');
export const getRefreshId = reportUsage => getValue(reportUsage, 'clientOnly.refreshId');
export const getRefreshError = reportUsage => getValue(reportUsage, 'clientOnly.error');
export const getRefreshResult = reportUsage => getValue(reportUsage, 'clientOnly.data');

export const getFrozenReportDefinition = reportUsage => getValue(reportUsage, 'clientOnly.frozenReportDefinition');
export const getFrozenReportUsage = reportUsage => getValue(reportUsage, 'clientOnly.frozenReportUsage');
export const getReportDatabaseName = reportDefinition => getValue(reportDefinition, 'report.params.database');
export const getReportDefinitionId = reportDefinition => getValue(reportDefinition, 'id');
export const getReporterTemplates = reportDefinition => {
    const reportDatabaseName = getReportDatabaseName(reportDefinition);
    switch (reportDatabaseName) {
    case 'reporter': return getReporterReporterTemplates();
    default: throw new Error(`Unsupported report database "${reportDatabaseName}" for report with id "${
        getReportDefinitionId(reportDefinition)}"`);
    }
};

// data accessors
const getState = rootState =>
    rootState.reporterEntities;

export const getGlobalFilterContainer = rootState =>
    getState(rootState).globalFilters;

export const getReportFilterContainer = (rootState, reportId) =>
    getReportDefinition(rootState, reportId).report.params.filters;

export const getDashboardFilterContainer = (rootState, dashboardId) =>
    getDashboardDefinition(rootState, dashboardId).filtersStatic;

export const getDashboardDefinitionOrder = rootState =>
    getState(rootState).dashboardDefinitionOrder;

export const getDashboardDefinition = (rootState, dashboardId) =>
    getState(rootState).dashboardDefinitionMap[dashboardId];

export const getDashboardDesc = (rootState, dashboardId, selectedLanguage) =>
    getLocalizedString(getValue(getDashboardDefinition(rootState, dashboardId), 'description'), selectedLanguage);

export const getDashboardTitle = (rootState, dashboardId, selectedLanguage) =>
    getLocalizedString(getValue(getDashboardDefinition(rootState, dashboardId), 'name'), selectedLanguage);

export const getDashboardReportIsLoading = (rootState, dashboardId) =>
    getState(rootState).dashboardDefinitionMap[dashboardId].reports[0].clientOnly?.isLoading;

export const getReportDefinition = (rootState, reportId) => {
    return getState(rootState).reportDefinitionMap[reportId];
};

export const getReportUsages = (rootState, dashboardId) =>
    getValue(getDashboardDefinition(rootState, dashboardId), 'reports');

export const getChartType = (rootState, reportId, reportUsage) =>
    getValue(reportUsage, 'charts.0.config.type') ||
    getValue(getReportDefinition(rootState, reportId), 'charts.0.config.type');

export const getReportQuery = (rootState, reportId) =>
    getValue(getReportDefinition(rootState, reportId), 'report.query');

export const getReportDesc = (rootState, reportId, selectedLanguage) =>
    getLocalizedString(getValue(getReportDefinition(rootState, reportId), 'report.description'), selectedLanguage);

export const getReportTitle = (rootState, reportId, selectedLanguage) =>
    getLocalizedString(getValue(getReportDefinition(rootState, reportId), 'report.name'), selectedLanguage);

export const getReportGlobalTime = (rootState) =>
    getState(rootState).time;

export const getReportFiltersNetworkCard = (rootState) =>
    getState(rootState).filters.networkCards;

const getDevice = (iface) => {
    switch (iface.type) {
    case 'vlan':
        return `vlan${iface.vlanTag}`;
    case 'bridge':
        return `br${iface.ifaceTag}`;
    case 'bond':
        return `bond${iface.ifaceTag}`;
    default:
        return iface.device?.[NODE_A_ID];

    }
};

export const getInterfacesForCharts = createSelector(
    [
        state =>  getInterfacesFromConfigurationInit(state),
        state =>  getVpnFromConfigurationInit(state),
    ], (interfaces, vpn) => {
        const names = [];
        interfaces.forEach(item => names.push(
            {
                name: item.name,
                filter: getDevice(item),
                color: item.color,
            }
        ));
        vpn.forEach(item => names.push(
            {
                name: item.name,
                filter: `${item.interfaceTopology?.substring(0, 3) || 'tun'}${item.tunIndex}`,
                color: item.color,
            }
        ));
        return names;
    }
);

// action creators
export const dashboardRefresh = (dashboardId, time, filter, isManual) =>
    ({ type: DASHBOARD_REFRESH_REQUEST, dashboardId, time, filter, isManual });

export const dashboardStop = (dashboardId) =>
    ({ type: DASHBOARD_REFRESH_STOP, dashboardId });

const reportRefreshFailure = (dashboardId, refreshId, error) =>
    ({ type: REPORT_REFRESH_FAILURE, dashboardId, refreshId, error });

const reportRefreshCancel = (dashboardId, refreshId) =>
    ({ type: REPORT_REFRESH_CANCEL, dashboardId, refreshId, });

const reportRefreshSuccess = (dashboardId, refreshId, data, frozenReportDefinition, frozenReportUsage, time) =>
    ({ type: REPORT_REFRESH_SUCCESS, dashboardId, refreshId, data, frozenReportDefinition, frozenReportUsage, time });

export const reportRefreshToHours = (reportId, columnTime, isManual) =>
    ({ type: REPORT_REFRESH_TO_HOURS, reportId, columnTime, isManual });

export const createDashboardCopyWithFilter = (dashboardId, filterValue, columnName) =>
    ({ type: CREATE_DASHBOARD_COPY, dashboardId, filterValue, columnName });

export const createDashboardIdWithFilterValue = (dashboardId, filterValue) => filterValue + ':' + dashboardId;

export const setAllHwInterfacesAsFilter = (interfaces) => {
    const networkCards = {};
    interfaces.forEach(item =>
        networkCards[item] = true);
    return { type: SET_FILTER_DEFAULT_HW_IFACES, networkCards };
};


export const reportSetFilter = ({ dashboardId, filter, value, name }) =>
    ({ type: SET_FILTER, dashboardId, filter, value, name });

// reducer
const reportUsageReduce = (reportUsage, action) => {
    const refreshId = getRefreshId(reportUsage);
    switch (action.type) {
    case REPORT_REFRESH_FAILURE:
    case REPORT_REFRESH_SUCCESS:
        if (action.refreshId !== refreshId) {
            return reportUsage;
        }
    // no default
    }
    switch (action.type) {
    case DASHBOARD_REFRESH_REQUEST:
        return {
            ...reportUsage,
            clientOnly: {
                ...reportUsage.clientOnly,
                refreshId: uuidv4(),
                isLoading: true,
                error: null
            },
        };
    case REPORT_REFRESH_FAILURE:
        return {
            ...reportUsage,
            clientOnly: {
                ...reportUsage.clientOnly,
                isLoading: false,
                error: action.error,
            },
        };
    case REPORT_REFRESH_CANCEL:
        return {
            ...reportUsage,
            clientOnly: {
                ...reportUsage.clientOnly,
                isLoading: false,
            },
        };
    case REPORT_REFRESH_SUCCESS:
        return {
            ...reportUsage,
            clientOnly: {
                ...reportUsage.clientOnly,
                isLoading: false,
                data: action.data,
                frozenReportDefinition: action.frozenReportDefinition,
                frozenReportUsage: {
                    ...action.frozenReportUsage,
                    clientOnly: {
                        ...action.frozenReportUsage.clientOnly,
                        // the time has to be updated here because it is changed while reportRefresh() is running
                        time: action.time
                    }
                }
            },
        };
    default: return reportUsage;
    }
};


const dashboardDefinitionReduce = (dashboardDefinition, action) => {
    switch (action.type) {
    case DASHBOARD_REFRESH_REQUEST:
    case REPORT_REFRESH_FAILURE:
    case REPORT_REFRESH_CANCEL:
    case REPORT_REFRESH_SUCCESS:
        return {
            ...dashboardDefinition,
            reports: dashboardDefinition.reports.map(reportUsage =>
                reportUsageReduce(reportUsage, action)),
        };
    case CREATE_DASHBOARD_COPY:
        return {
            ...dashboardDefinition,
            id: createDashboardIdWithFilterValue(action.dashboardId, action.filterValue),
            filtersStatic: addValueFilter({ columnName: action.columnName, value: action.filterValue })(),
        };
    default: return dashboardDefinition;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case DASHBOARD_REFRESH_REQUEST:
    case REPORT_REFRESH_FAILURE:
    case REPORT_REFRESH_CANCEL:
    case REPORT_REFRESH_SUCCESS:
        return {
            ...state,
            time: action.type === DASHBOARD_REFRESH_REQUEST && action.time ?
                action.time :
                state.time,
            dashboardDefinitionMap: {
                ...state.dashboardDefinitionMap,
                [action.dashboardId]:
                    dashboardDefinitionReduce(state.dashboardDefinitionMap[action.dashboardId], action),
            },
        };
    case CREATE_DASHBOARD_COPY:
        if (state.dashboardDefinitionMap[createDashboardIdWithFilterValue(action.dashboardId, action.filterValue)]) {
            return state;
        }
        return {
            ...state,
            dashboardDefinitionMap: {
                ...state.dashboardDefinitionMap,
                [createDashboardIdWithFilterValue(action.dashboardId, action.filterValue)]:
                    dashboardDefinitionReduce(state.dashboardDefinitionMap[action.dashboardId], action),
            },
        };
    case REPORT_REFRESH_TO_HOURS:
        return {
            ...state,
            reportDefinitionMap: {
                ...state.reportDefinitionMap,
                [action.reportId]: {
                    ...state.reportDefinitionMap[action.reportId],
                    report: {
                        ...state.reportDefinitionMap[action.reportId].report,
                        params: {
                            ...state.reportDefinitionMap[action.reportId].report.params,
                            categories: state.reportDefinitionMap[action.reportId].report.params?.categories.map(
                                item => item === 'event.date_minute' ||  item === 'event.date_hour' ?
                                    action.columnTime :
                                    item
                            ),
                            orderBy: state.reportDefinitionMap[action.reportId].report.params?.orderBy.map(item => {
                                return {
                                    col: item.col === 'event.date_minute' ||
                                  item.col ===
                                 'event.date_hour' ? action.columnTime :
                                        item.col,
                                    dir: item.dir
                                };
                            })
                        }
                    }
                }
            },
        };
    case SET_FILTER:
        return {
            ...state,
            filters: {
                ...state.filters,
                [action.filter]: {
                    ...state.filters[action.filter],
                    [action.name]: action.value,
                }
            }
        };
    case SET_FILTER_DEFAULT_HW_IFACES: {
        return {
            ...state,
            filters: {
                ...state.filters,
                networkCards: action.networkCards
            }
        };
    }
    default: return state;
    }
};

export default reducer;


// API
const generateReport = async (cacheLevel, reportQuery, cancelToken) =>
    Axios.post('/api/reporter/generateReport', { cacheLevel, reportQuery }, { cancelToken });


// use cached results if they are up to date, otherwise refresh the cache
const USE_CACHE_WHEN_FRESH = 1;

// only obtain the report from cache, do not refresh the report if the result is not in the cache
const USE_CACHE_ALWAYS = 4;

// side effects
const reportRefresh = function* (dashboardId, reportUsage, time, filter, cancelToken, isManual) {
    const reportId = getReportId(reportUsage);
    const refreshId = getRefreshId(reportUsage);
    if (!time) {
        time = yield select(state => getReportGlobalTime(state));
    }
    try {
        if (time) {
            if (moment.duration(moment(time.to).diff(time.from)).asMinutes() > 10000) {
                yield put(reportRefreshToHours(reportId, 'event.date_hour', isManual));
            } else {
                yield put(reportRefreshToHours(reportId, 'event.date_minute', isManual));
            }
        }
        // Freeze the reportDefinition and reportUsage to preserve their state when the report refresh began. Prevents
        // bugs when changing the report configuration while the report or chart is being refreshed.
        const frozenReportDefinition = yield select(state => getReportDefinition(state, reportId));
        const frozenReportUsage = reportUsage;
        const arrFilterContainers = [
            yield select(getGlobalFilterContainer),
            yield select(state => getDashboardFilterContainer(state, dashboardId)),
        ];
        const reporterTemplates = getReporterTemplates(frozenReportDefinition);

        const suricataVariableHomeNet = yield select(getSuricataInitHomeNetVariable);

        const reportQuery = buildReportQuery(
            { frozenReportDefinition, arrFilterContainers, reporterTemplates, suricataVariableHomeNet, time, filter }
        );

        const cacheLevel = isManual ? USE_CACHE_WHEN_FRESH : USE_CACHE_ALWAYS;
        const { data } = yield call(generateReport, cacheLevel, reportQuery, cancelToken);
        yield put(reportRefreshSuccess(dashboardId, refreshId, data, frozenReportDefinition, frozenReportUsage, time));
        if (!isManual && (!data.cache_hit || data.cache?.expired)) {
            const { data } = yield call(generateReport, USE_CACHE_WHEN_FRESH, reportQuery, cancelToken);
            yield put(reportRefreshSuccess(
                dashboardId, refreshId, data, frozenReportDefinition, frozenReportUsage, time
            ));
        }
    } catch (error) {
        yield put(reportRefreshFailure(dashboardId, refreshId, getApiError(error)));
    } finally {
        if (yield cancelled()) {
            yield put(reportRefreshCancel(dashboardId, refreshId));
        }
    }
};


const workerDashboardRefresh = function* (action) {
    const { dashboardId, time, filter, isManual } = action;
    const cancelSource = Axios.CancelToken.source();
    try {
        // refresh all reports in parallel
        const arrReportUsage = yield select(state => getReportUsages(state, dashboardId));
        yield all(arrReportUsage.map(reportUsage =>
            call(reportRefresh, dashboardId, reportUsage, time, filter, cancelSource.token, isManual)));
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel);
        }
    }
};

const workerDashboardRefreshMain = function* (action) {
    const refreshTask = yield fork(workerDashboardRefresh, action);
    // wait for the user stop action
    yield take(({ dashboardId, type }) => type === DASHBOARD_REFRESH_STOP && dashboardId === action.dashboardId);
    // user stop. cancel the background task
    // this will cause the forked bgSync task to jump into its finally block
    yield cancel(refreshTask);
};

export const workerRefreshDueToFilter = function* (action) {
    if (action.filter === 'networkCards') {
        const networkDevices = yield select(state => getInterfacesForCharts(state));
        const filter = { interfaces: Object.values(networkDevices).filter(item => item.show).map(item => item.device) };
        yield put(dashboardRefresh(action.dashboardId, undefined, filter, true));
    }
};


export const sagas = [
    takeEvery(DASHBOARD_REFRESH_REQUEST, workerDashboardRefreshMain),
    takeLatest(SET_FILTER, workerRefreshDueToFilter)
];
