/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { isProductionMode } from './constants.ts';
import { objectPick } from './objectUtils.ts';


export interface PODefinitionSelect {
    id: string,
    type: 'select',
}
export interface PODefinitionStatusBox {
    id: string,
    type: 'statusBox',
}
export interface PODefinitionClickableStatusBox {
    id: string,
    type: 'clickableStatusBox',
}
export interface PODefinitionContainer<T extends Record<string, PODefinition>> {
    id: string,
    type: 'container',
    child: T
}
export type PODefinition = PODefinitionContainer<any>|PODefinitionSelect|
    PODefinitionStatusBox|PODefinitionClickableStatusBox;

export const TEST_ID_PROP = 'test-id';
export const TEST_STATUS_PROP = 'test-status';
export interface TestProps {
    [TEST_ID_PROP]?: string,
    [TEST_STATUS_PROP]?: string,
}

export const poDef = {
    statusBox: (id: string): PODefinitionStatusBox => ({ id, type: 'statusBox' }),
    container: <T extends Record<string, PODefinition>>(id: string, child: T): PODefinitionContainer<T> =>
        ({ id, type: 'container', child }),
    clickableStatusBox: (id: string): PODefinitionClickableStatusBox => ({ id, type: 'clickableStatusBox' }),
    select: (id: string): PODefinitionSelect => ({ id, type: 'select' }),
};

export const testPropsPassthrough = (props: Record<string, any>) =>
    objectPick(props, [ TEST_ID_PROP, TEST_STATUS_PROP ]);
export const testProps = (id: string, opts: {status?: string} = {}) => {
    if (isProductionMode) {
        return {};
    }
    const props = {
        [TEST_ID_PROP]: id,
    };
    if (opts.status) {
        props[TEST_STATUS_PROP] = opts.status;
    }
    return props;
};
export const testPropsStatus = (id: string, status: string) => {
    if (isProductionMode) {
        return {};
    }
    return {
        [TEST_ID_PROP]: id,
        [TEST_STATUS_PROP]: status,
    };

};
