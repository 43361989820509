/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import arrayUtils from './arrayUtils.js';


const filterObjectValuesManipulator = {};

const getNewValue = function(value) {
    // TODO check if correct, it was {} before
    return {
        selected: value || ''
    };
};

filterObjectValuesManipulator.createNewFilterObject = function(columnName, value) {
    return {
        selected: [
            getNewValue(value)
        ],
    };
};

filterObjectValuesManipulator.addValueToFilter = function(filterObject, value) {
    const newValue = getNewValue(value);
    if (filterObject.selected.length === 1 &&
        !filterObject.selected[0].selected &&
        value)
    {
        filterObject.selected[0] = newValue;
    } else {
        filterObject.selected.push(newValue);
    }
};

filterObjectValuesManipulator.removeValueFromFilter = function(filterObject, index) {
    arrayUtils.removeAtIndex(filterObject.selected, index);
};

// columns that can have IP addresses with masks
const COLS_IP_MASKABLE = {
    'network.client': 'network.client_ip',
    'network.client_ip': 'network.client_ip',
    'network.server': 'network.server_ip',
    'network.server_ip': 'network.server_ip',
    'eve_e.src_ip': 'eve_e.src_ip',
    'net.dest_ip': 'net.dest_ip',
};

const STR_8_0 = '00000000';

/**
 * Transforms given IP address to a string that contains '0's and '1's.
 * Works with a part of an IP address too.
 *
 * @memberof filterObjectValuesManipulator
 * @private
 * @param {string} ip - eg. '192.168.150.4' or '192.168.150'
 * @returns {string} eg. '11000000101010001001011000000100' or
 * '11000000101010001001011000000000'
 */
const ip2bits = function(ip) {
    const numDots = ip.split('.').length;
    if (numDots < 4) {
        ip += '....'.substr(0, 4 - numDots);
    }
    return ip.split('.').slice(0, 4).map(function(ipPart) {
        const str = (Number(ipPart) || 0).toString(2);
        return STR_8_0.substr(0, 8 - str.length) + str;
    }).join('');
};

/**
 * Transforms given string that contains '0's and '1's to an IP address.
 *
 * @memberof filterObjectValuesManipulator
 * @private
 * @param {string} bits - eg. '11000000101010001001011000000100'
 * @returns {string} eg. '192.168.150.4'
 */
const bits2ip = function(bits) {
    return [ 0, 1, 2, 3 ].map(function(index) {
        return parseInt(bits.toString(2).substr(index * 8, 8), 2);
    }).join('.');
};

// is the value an IP address with a mask?
// TODO extract regexes from simplenet service to another service, include
// from there
const RE_IS_IP_WITH_MASK = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[12][0-9]|3[0-2])$/;

filterObjectValuesManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, filterContainerManipulator,
    filterObject
) {
    if (filterObject.isDisabled ||
        !filterObject.selected ||
        !filterObject.selected.reduce(function(temp, value) {
            return temp || (value && value.selected);
        }, false))
    {
        return undefined;
    }
    const params = filterObject.selected.map(function(item) {
        if (filterObject.columnName in COLS_IP_MASKABLE &&
            RE_IS_IP_WITH_MASK.test(item.selected))
        {
            const ipAndMask = item.selected.split('/');
            const mask = Number(ipAndMask[1]);
            if (!isNaN(mask) && mask >= 0 && mask <= 32) {
                return {
                    col: COLS_IP_MASKABLE[filterObject.columnName],
                    type: 'in_ip_network',
                    neg: !!item.isNegated,
                    params: [
                        bits2ip(ip2bits(ipAndMask[0])), // normalize the address
                        ipAndMask[1]
                    ]
                };
            }
        }
        return {
            col: filterObject.columnName,
            type: 'matches',
            neg: !!item.isNegated,
            params: [
                item.selected + '' // so that it is a string
            ]
        };
    }).filter(function(param) {
        return param.params[0];
    });
    return {
        type: filterObject.isAnd ? 'AND' : 'OR',
        neg: !!filterObject.isNegated,
        params: params
    };
};

export default filterObjectValuesManipulator;
