/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Service that provides common functionality for factories that have
 * dependencies given in constructors.
 *
 * @name dependencer
 */
const dependencer = {};

dependencer.setDependencies = function(storage, dependencies, dependencyList) {
    for (const key in dependencyList) {
        if (!(key in dependencies)) {
            throw new Error('Dependency "' + key + '" not defined');
        }
        storage[key] = dependencies[key];
    }
};

export default dependencer;
