/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { select, takeEvery, call, takeLatest, put } from '~commonLib/reduxSagaEffects.ts';
import { DEFAULT_LANGUAGE } from '~commonLib/constants.ts';

import localization from '../../lib/localization.js';
import { getInstanceI18n } from '../../lib/reactUtils.js';
import { getApiError } from '../../lib/apiUtils.ts';


// actions
const SET_SELECTED_LANGUAGE = 'ak/language/SET_SELECTED_LANGUAGE';
const SET_INIT_LANGUAGE = 'ak/language/SET_INIT_LANGUAGE';

const APPLY_LANGUAGE_REQUEST = 'ak/language/APPLY_LANGUAGE_REQUEST';
export const APPLY_LANGUAGE_SUCCESS = 'ak/language/APPLY_LANGUAGE_SUCCESS';
export const APPLY_LANGUAGE_FAIL = 'ak/language/APPLY_LANGUAGE_FAIL';

const SYNC_LANGUAGE_TO_SESSION_LANG_REQUEST = 'ak/language/SYNC_LANGUAGE_TO_SESSION_LANG_REQUEST';
export const SYNC_LANGUAGE_TO_SESSION_LANG_SUCCESS = 'ak/language/SYNC_LANGUAGE_TO_SESSION_LANG_SUCCESS';
export const SYNC_LANGUAGE_TO_SESSION_LANG_FAIL = 'ak/language/SYNC_LANGUAGE_TO_SESSION_LANG_FAIL';


// initial state
const initialState = {
    selectedLanguage: DEFAULT_LANGUAGE,
    applyLanguageToUser: false,
    error: null,
    isLoading: false,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_SELECTED_LANGUAGE:
        return {
            ...state,
            selectedLanguage: action.payload.selectedLanguage,
            applyLanguageToUser: true,
            isLoading: false,
        };
    case SET_INIT_LANGUAGE:
        return {
            ...state,
            selectedLanguage: action.payload.selectedLanguage,
            applyLanguageToUser: false,
            isLoading: false,
        };
    case APPLY_LANGUAGE_SUCCESS:
        return { ...state, applyLanguageToUser: false, isLoading: false };
    case APPLY_LANGUAGE_FAIL:
    case SYNC_LANGUAGE_TO_SESSION_LANG_FAIL:
        return { ...state, error: action.payload, applyLanguageToUser: false, isLoading: false };
    case SYNC_LANGUAGE_TO_SESSION_LANG_REQUEST:
    case APPLY_LANGUAGE_REQUEST:
        return { ...state, isLoading: true };
    case SYNC_LANGUAGE_TO_SESSION_LANG_SUCCESS:
        return { ...state, isLoading: false };
    default:
        return state;
    }
};

export default reducer;


// data accessor
const getState = rootState =>
    rootState.language;

export const getSelectedLanguage = rootState =>
    getState(rootState).selectedLanguage;

export const getApplyLanguageToUser = rootState =>
    getState(rootState).applyLanguageToUser;


export const setLocalization = (initialState, instanceI18n) => {
    const selectedLanguage = getSelectedLanguage(initialState);
    localization(selectedLanguage, instanceI18n);
};

// action creators
export const setSelectedLanguage = payload =>
    ({ type: SET_SELECTED_LANGUAGE, payload });

export const setInitLanguage = payload =>
    ({ type: SET_INIT_LANGUAGE, payload });

export const applyLanguageSuccess = () =>
    ({ type: APPLY_LANGUAGE_SUCCESS });

export const applyLanguageRequest = () =>
    ({ type: APPLY_LANGUAGE_REQUEST });

export const applyLanguageFail = payload =>
    ({ type: APPLY_LANGUAGE_FAIL, payload });

export const syncLanguageToSessionLangRequest = () =>
    ({ type: SYNC_LANGUAGE_TO_SESSION_LANG_REQUEST });

export const syncLanguageToSessionLangSuccess = () =>
    ({ type: SYNC_LANGUAGE_TO_SESSION_LANG_SUCCESS });

export const syncLanguageToSessionLangFail = payload =>
    ({ type: SYNC_LANGUAGE_TO_SESSION_LANG_FAIL, payload });


// API endpoints
export const apiCallChangeUserLanguage = async data =>
    axios.post('/api/users/changeUserLanguage', data);

export const apiCallGetSessionLanguage = async () =>
    axios.get('/api/users/getSessionLanguage');


// side effects
const workerLanguageChange = function* () {
    const selectedLanguage = yield select(getSelectedLanguage);
    localization(selectedLanguage, getInstanceI18n());
};

export const workerApplyLanguageToUser = function* () {
    const applyLanguageToUser = yield select(getApplyLanguageToUser);
    if (!applyLanguageToUser) {
        yield put(applyLanguageSuccess());
        return;
    }
    const selectedLanguage = yield select(getSelectedLanguage);
    try {
        yield call(apiCallChangeUserLanguage, { language: selectedLanguage });
        yield put(applyLanguageSuccess());
    } catch (error) {
        yield put(applyLanguageFail(getApiError(error)));
    }
};

export const workerSyncLanguageToSessionLang = function* () {
    try {
        const { data } = yield call(apiCallGetSessionLanguage);
        yield put(setInitLanguage({ selectedLanguage: data.language }));
        yield put(syncLanguageToSessionLangSuccess());
    } catch (error) {
        yield put(syncLanguageToSessionLangFail(getApiError(error)));
    }
};

export const sagas = [
    takeEvery([ SET_SELECTED_LANGUAGE, SET_INIT_LANGUAGE ], workerLanguageChange),
    takeLatest(APPLY_LANGUAGE_REQUEST, workerApplyLanguageToUser),
    takeLatest(SYNC_LANGUAGE_TO_SESSION_LANG_REQUEST, workerSyncLanguageToSessionLang),
];
