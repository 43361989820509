/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import createSagaMiddleware from 'redux-saga';

import { logoutRequest, logoutUser } from '~frontendRoot/ducks/userAuthentication/ducks/login.js';


import getStore from '../store/store.js';


let globalTask;
let rootSaga;
let currentOnRootSagaError = () => {};

export const updateCurrentOnRootSagaError = func => currentOnRootSagaError = func;

export const setRootSaga = theSaga => rootSaga = theSaga;

export const restartAllTask = () => {
    globalTask.cancel();
    globalTask = sagaMiddleware.run(rootSaga);
};

export const stopAllTask = () => {
    globalTask.cancel();
};

export const runGlobalTask = () => globalTask = sagaMiddleware.run(rootSaga);


const sagaMiddleware = createSagaMiddleware({
    onError: async (error) => {
        if (typeof window === 'undefined') {
            return currentOnRootSagaError(error);
        }
        getStore().dispatch(logoutRequest({ reason: error.message, statusCode: 500 }));
        await logoutUser();
    },
});

export default sagaMiddleware;
