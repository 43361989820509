/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const IMG_GUI_MAIN_WINDOW_PATH = '/handbook/img/gui_main_window.png';
export const IMG_GUI_MENU_PATH = '/handbook/img/gui_menu.png';
export const IMG_GUI_ICONS_PATH = '/handbook/img/gui_icons.png';
export const IMG_GUI_CFG_ACTIVATION_PATH = '/handbook/img/gui_cfg_activation.png';
export const IMG_GUI_HELP = '/handbook/img/gui_help.png';
