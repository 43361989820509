/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';

import arrayUtils from './arrayUtils.js';
import dependencer from './dependencer.js';


const DEPENDENCY_LIST = {};

const CustomFilterStorage = function(dependencies) {
    dependencer.setDependencies(this, dependencies, DEPENDENCY_LIST);

    this.myFilters = [];
    this.builtinFilters = [];
    this.selectedFilter = null;
    this.idMap = {};
};

/**
 * Selects another custom filter. The custom filter is chosen in the
 * following priority:
 *  - the given custom filter
 *  - first visible item in myFilters
 *  - first visible item in builtinFilter
 *
 * @memberof CustomFilterStorage
 * @param [newlySelectedFilter]
 */
CustomFilterStorage.prototype.selectFilter = function(newlySelectedFilter) {
    this.selectedFilter = newlySelectedFilter ||
        this.myFilters[0] ||
        this.builtinFilters[0] ||
        null;
};

/**
 * Loads data from a serialized object.
 *
 * @memberof CustomFilterStorage
 * @param {JSON} data
 * @param {callback} callback
 */
CustomFilterStorage.prototype.load = function(data, callback) {
    this.myFilters = getValue(data, 'customFilters') || [];
    this.idMap = {};
    this.myFilters.forEach((myFilter) => {
        this.idMap[myFilter.id] = myFilter;
    });
    this.selectFilter(null);
    callback();
};

/**
 * Stores data to a serialized object.
 *
 * @memberof CustomFilterStorage
 * @returns {JSON}
 */
CustomFilterStorage.prototype.store = function() {
    return {
        customFilters: this.myFilters,
    };
};

/**
 * Adds given filter definition to my filters.
 *
 * @memberof CustomFilterStorage
 * @param customFilterDefinition
 * @param {callback} [callback]
 */
CustomFilterStorage.prototype.addMyFilterDefinition = function(
    customFilterDefinition, callback
) {
    if (typeof callback !== 'function') {
        callback = function() {};
    }
    if (customFilterDefinition.id in this.idMap) {
        return callback(new Error(
            'Custom filter with ID "' +
            customFilterDefinition.id +
            '" already exists'
        ));
    }
    this.idMap[customFilterDefinition.id] = customFilterDefinition;
    this.myFilters.push(customFilterDefinition);
    callback(null, customFilterDefinition);
};

/**
 * Removes given filter definition from my filters.
 *
 * @memberof CustomFilterStorage
 * @param customFilterDefinition
 * @param {callback} [callback]
 */
CustomFilterStorage.prototype.removeMyCustomFilter = function(
    customFilterDefinition, callback
) {
    if (typeof callback !== 'function') {
        callback = function() {};
    }
    if (!(customFilterDefinition.id in this.idMap)) {
        return callback(new Error(
            'Custom filter with ID "' +
            customFilterDefinition.id +
            '" does not exist'
        ));
    }
    arrayUtils.removeItem(this.myFilters, customFilterDefinition);
    delete this.idMap[customFilterDefinition.id];
    if (this.selectedFilter === customFilterDefinition) {
        this.selectFilter(null);
    }
    callback();
};

/**
 * Obtains custom filter definition refered by a filter object.
 *
 * @memberof CustomFilterStorage
 * @param filterObject
 * @returns customFilterDefinition
 */
CustomFilterStorage.prototype.getFromFilterObject = function(filterObject) {
    return this.idMap[getValue(filterObject, 'selected.customFilterId')];
};

export default CustomFilterStorage;
