/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';
import { v4 as uuidv4 } from 'uuid';

import chartManipulator from './chartManipulator.js';
import chartTypes from './chartTypes.js';
import dynamicSettings from './dynamicSettings.js';
import localizedName from './localizedName.js';
import reportTypes from './reportTypes.js';
import tFilter from './tFilter.js';
import whereAmI from './whereAmI.js';
import Worksheet from './Worksheet.js';

/** @typedef {string} UUID */

/**
 * A single report as used somewhere (e.g. on a dashboard). It is send to
 * the server as-is, thus it should at all times be a plain old JS object.
 *
 * @typedef {object} ReportUsage
 * @property {ChartCfgContainer} charts - an array of charts, currently
 * each report has only one chart, but the code should not count on that
 * @property {UUID} id - report ID
 */

/**
 * A report as used in report list. It contains the definition of the
 * report, also known as ReportCfg. A collection of these objects is
 * verified by schema #reports. It is send to the server as-is, thus it
 * should at all times be a plain old JS object.
 *
 * @typedef {object} ReportDefinition
 * @property {ChartCfgContainer} charts - an array of charts, currently each
 * report has only one chart, but the code should not count on that
 * @property {ReportCfg} report - report configuration
 */

/**
 * A chart container.
 *
 * @typedef {ChartCfgObject[]} ChartCfgContainer
 */

/**
 * A single chart configuration. It contains the definition of a single
 * chart of a report. An array of these objects is verified by schema
 * #charts. It is send to the server as-is, thus it should at all times be
 * a plain old JS object.
 *
 * @typedef {object} ChartCfgObject
 * @property {object} config - the actual configuration
 * @property {string} type - type of the chart, aka chartType
 * @property {boolean} isLogarithmic - whether this chart is logarithmic
 * @property {boolean} drilldownExpanded - whether the drilldown (aka 2D
 * table) has the second level expanded
 * @property {boolean} showAllRows - whether to show all points on the
 * chart, may cause performance issues
 * @property {number} rowsPerPage - how many rows show a single page when
 * displaying a table
 */

/**
 * A report configuration. It is verified by schema #report. It is
 * send to the server as-is, thus it should at all times be a plain old JS
 * object.
 *
 * @typedef {object} ReportCfg
 * @property {object} description - localized textual descriptions of the
 * report
 * @property {string} id - unique ID of the report
 * @property {object} name - localized textual names of the report
 * @property {object} params - parameters of the report
 * @property {string} type - type of the report, aka reportType
 */

/**
 * Report parameters object. It is verified by schema #report-params. It is
 * send to the server as-is, thus it should at all times be a plain old JS
 * object. The properties depend on the current reportType of the ReportCfg
 * object.
 *
 * @typedef {object} ReportParams
 * @property {object} filters
 */
const reportManipulator = {};

/**
 * Creates a new {@link ReportDefinition} structure.
 *
 * @memberof reportManipulator
 * @param {boolean} [isBuiltin=false]
 * @returns {ReportDefinition}
 */
reportManipulator.createNewReportDefinition = function(isBuiltin) {
    const reportCfg = {
        id: uuidv4(),
        type: 'universal',
    };
    if (isBuiltin) {
        reportCfg.readOnly = true;
    }
    localizedName.initialize(reportCfg);
    reportCfg.name.user = tFilter('report:default-name');
    reportCfg.params = reportTypes[reportCfg.type].getDefaultParams();
    return {
        charts: chartManipulator.createNewChartContainerForReportDefinition(),
        report: reportCfg
    };
};

/**
 * Creates a copy of a {@link ReportDefinition} structure.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @param {object} [options]
 * @param {boolean} [options.doNotModifyName=false] - when truthy, the name
 * is not modified
 * @param {boolean} [options.doNotResetId=false] - when truthy, the report
 * ID is not reset
 * @param {callback} callback
 */
reportManipulator.copyReportDefinition = function(
    reportDefinition, options, callback
) {
    if (!callback && typeof options === 'function') {
        callback = options;
        options = {};
    }
    const newReportDefinition = JSON.parse(JSON.stringify(reportDefinition));
    if (!options.doNotModifyName) {
        newReportDefinition.report.name.user =
            reportManipulator.getReportName(reportDefinition) +
            tFilter('report:is-copy');
    }
    if (!options.doNotResetId) {
        newReportDefinition.report.id = uuidv4();
    }
    if (newReportDefinition.report.readOnly) {
        newReportDefinition.report.readOnly = false;
    }
    callback(null, newReportDefinition);
};

/**
 * Creates a new {@link ReportUsage} structure from given
 * {@link ReportDefinition} structure.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns {ReportUsage}
 */
reportManipulator.createNewReportUsage = function(reportDefinition) {
    return {
        id: reportDefinition.report.id,
        charts: chartManipulator.createNewChartContainerForReportUsage(),
    };
};

/**
 * Creates a copy of a {@link ReportDefinition} structure specifically for
 * use in a drilldown.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @param {ChartCfgContainer?} chartContainerReportUsage
 * @param {callback} callback
 */
reportManipulator.drilldownCopyReportDefinition = function(
    reportDefinition, chartContainerReportUsage, callback
) {
    if (!callback && typeof chartContainerReportUsage === 'function') {
        callback = chartContainerReportUsage;
        chartContainerReportUsage = null;
    }
    reportManipulator.copyReportDefinition(
        reportDefinition,
        {
            doNotModifyName: true,
            doNotResetId: true,
        },
        function(error, newReportDefinition) {
            if (error) {
                return callback(error);
            }
            newReportDefinition.charts =
                chartManipulator.copyChartContainer(
                    reportDefinition.charts, chartContainerReportUsage
                );
            callback(null, newReportDefinition);
        }
    );
};

/**
 * Returns true if given report is currently editable.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns {boolean}
 */
reportManipulator.isReportEditable = function(reportDefinition) {
    return (reportDefinition && !reportDefinition.report.readOnly) ||
        dynamicSettings.isBuiltinEditable;
};

/**
 * Returns true if given report is currently visible.
 *
 * @memberof reportManipulator
 * @returns {boolean}
 */
reportManipulator.isReportVisible = function(
    reporterTemplates, reportDefinition
) {
    if (!reportDefinition) {
        return false;
    }
    if (!whereAmI.angular) {
        return true;
    }
    const typeObject = reportTypes[reportDefinition.report.type];
    if (!typeObject) {
        return true;
    }
    const tableName = typeObject.getTable(reportDefinition.report.params);
    const table = getValue(reporterTemplates, 'tables.' + tableName);
    return dynamicSettings.kbiAllVisible ||
        dynamicSettings.isAdvanced ||
        !table ||
        !table.isEmpty;
};

/**
 * Returns report ID.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns {UUID}
 */
reportManipulator.getReportId = function(reportDefinition) {
    return getValue(reportDefinition, 'report.id');
};

/**
 * Returns report name.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns {string}
 */
reportManipulator.getReportName = function(reportDefinition) {
    return localizedName.getName(reportDefinition && reportDefinition.report);
};

/**
 * Returns report description.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns {string}
 */
reportManipulator.getReportDescription = function(reportDefinition) {
    return localizedName.getDescription(reportDefinition && reportDefinition.report);
};

/**
 * Returns report an object with filter containers indexed by params key.
 *
 * @memberof reportManipulator
 * @param {ReportDefinition} reportDefinition
 * @returns key is field ID, value is filterContainer
 */
reportManipulator.getReportFilters = function(reportDefinition) {
    const reportConfig = reportDefinition.report;
    return reportTypes[reportConfig.type].getFilters(reportConfig.params);
};

/**
 * Returns report type.
 *
 * @memberof reportManipulator
 */
reportManipulator.getReportType = function(reportCfg) {
    return reportTypes[reportCfg.type];
};

/**
 * Returns parameters for report generation by Reporter.
 * The result should be valid according to #filter-serialized.
 *
 * @memberof reportManipulator
 * @returns {JSON} object with parameters for Reporter
 */
reportManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, reportCfg, arrFilterContainers
) {
    return reportManipulator.getReportType(reportCfg).generateCfg(
        customFilterStorage,
        reporterTemplates,
        reportCfg.params,
        arrFilterContainers
    );
};

const ensureChartType = function(reportDefinition, reportTypeName) {
    reportDefinition.charts.forEach(function(chart) {
        let chartType = chart.config.type;
        // BANNED_CHART_TYPES contains chartType transformation rules
        if (chartTypes.BANNED_CHART_TYPES[reportTypeName] &&
            chartTypes.BANNED_CHART_TYPES[reportTypeName][chartType])
        {
            chartType = chartTypes.BANNED_CHART_TYPES[reportTypeName][chartType];
        }
        // ensure that the chartType is viable, otherwise switch to the
        // first viable chartType
        if (!(reportTypeName in chartTypes.CHART_TYPES[chartType].reportTypes)) {
            for (const tempChartType in chartTypes.CHART_TYPES) {
                if (reportTypeName in chartTypes.CHART_TYPES[tempChartType].reportTypes) {
                    chartType = tempChartType;
                    break;
                }
            }
        }
        chart.config.type = chartType;
    });
};

/**
 * Changes the report type to given one. It also transforms the parameters
 * and ensures that the report charts have compatible type selected.
 *
 * @memberof reportManipulator
 */
reportManipulator.setReportType = function(
    reportDefinition, reportTypeObject
) {
    const reportCfg = reportDefinition.report;
    reportCfg.params = reportTypeObject.getDefaultParams(
        reportCfg.params, reportTypes[reportCfg.type]
    );
    reportCfg.type = reportTypeObject.id;
    ensureChartType(reportDefinition, reportTypeObject.id);
};

/**
 * Changes the report table to given one. It also ensures that the report
 * columns are from the newly selected table.
 *
 * @memberof reportManipulator
 */
reportManipulator.setTable = function(
    reporterTemplates, reportCfg, tableName
) {
    reportCfg.params.table = tableName;
    reportManipulator.ensureColumnsConsistence(
        reporterTemplates, reportCfg
    );
};

/**
 * Fixes the filterObjects upon loading.
 *
 * @memberof reportManipulator
 */
reportManipulator.fix = function(reporterTemplates, reportDefinition) {
    reportTypes[reportDefinition.report.type].fix(
        reporterTemplates, reportDefinition.report.params
    );
};

/**
 * Ensures column consistency for reportParams. Two things are ensured
 * currently:
 *   - All columns are from the selected table.
 *   - Fields that reference other fields have selected columns that are
 *     referenced.
 *
 * @memberof reportManipulator
 * @param reporterTemplates
 * @param reportCfg
 * @param {string} [newColumnName] - if the column is not consistent, it
 * will be changed to this one
 */
reportManipulator.ensureColumnsConsistence = function(
    reporterTemplates, reportCfg, newColumnName
) {
    reportManipulator.getReportType(reportCfg).ensureColumnsConsistence(
        reporterTemplates,
        reportCfg.params,
        reportCfg.params.table,
        newColumnName
    );
};

reportManipulator.addSheet = function(
    reportRefreshData, reportDefinition, worksheet
) {
    const rows = [];
    if (reportRefreshData.result) {
        rows.push(reportRefreshData.result.cols.map(function(column) {
            return column.title;
        }));
        reportRefreshData.result.rows.forEach(function(row) {
            rows.push(row);
        });
    } else {
        rows.push([ tFilter('report:no-data-for-export') ]);
    }
    worksheet.addSheet(localizedName.getName(reportDefinition.report), rows);
};

reportManipulator.toWorksheet = function(
    reportRefreshData, reportDefinition
) {
    const worksheet = new Worksheet();
    reportManipulator.addSheet(reportRefreshData, reportDefinition, worksheet);
    return worksheet;
};

reportManipulator.toCsv = function(reportRefreshData) {
    if (!reportRefreshData.result) {
        return;
    }
    const csv = [];
    csv.push(reportRefreshData.result.cols.map(function(column) {
        return JSON.stringify(column.title);
    }));
    reportRefreshData.result.rows.forEach(function(row) {
        csv.push(row.map(JSON.stringify));
    });
    return csv;
};


export default reportManipulator;
