/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { URL } from 'url';


export const urlToHost = urlString => {
    let urlObject;
    try {
        if (typeof window === 'undefined') {
            urlObject = new URL(urlString);
        } else {
            urlObject = new window.URL(urlString);
        }
    } catch (error) {
        return urlString;
    }
    return urlObject.host;
};

/**
 * For purposes of generating QR code of google authenticator.
 *
 * @example
 * //in google authenticator app, scanned QR code will create item "issuer (label)"
 * createOtpAuthUrl({label: 'label', secret: 'secret', issuer: 'issuer'})
 */

export const createOtpAuthUrl = ({ label, secret, issuer }) => {
    return encodeURI(`otpauth://totp/${label}?secret=${secret}&issuer=${issuer}`);
};
