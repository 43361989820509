/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getDataKeyHook } from '~frontendRoot/ducks/certificationExpiration/index.js';
import { CERTIFICATION_EXPIRATION } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';

import Input from '../Input/Input.js';


const propTypes = {
    hlcfgPath: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fakeFile: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.object,
    ]),
    id: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string,
    onChange: PropTypes.func,
    renderId: PropTypes.string,
};


const InputFile = (props: PropTypes.InferProps<typeof propTypes>) => {
    const certification = useSelector(getDataKeyHook(props.hlcfgPath));
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModalState({ modal: CERTIFICATION_EXPIRATION, value: true }));
    };
    return (
        <Input
            {...props}
            className={classNames(props.className,
                { 'certificationExpiration--warning': certification.warning },
                { 'certificationExpiration--error': certification.error },)
            }
            expirationTime={certification.expirationDate}
            maxSize={2000000}
            openModal={openModal}
            outline={false}
            type="file"

        />
    );
};

InputFile.propTypes = propTypes;

export default InputFile;
