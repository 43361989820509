/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NODE_A_ID, NODE_B_ID, NODE_SHARED } from '~commonLib/constants.ts';
import { HlcfgPath } from '~sharedLib/types.ts';


const isClusterSetting = (thing) => thing === NODE_B_ID || thing === NODE_A_ID || thing === NODE_SHARED;

export const findTranslationByHlcfgPathGeneric = (hlcfgRealPath: HlcfgPath) => {
    let translationLine = hlcfgRealPath.join('.');
    if (hlcfgRealPath.find(item => item.includes(':'))) {
        const diffArray = hlcfgRealPath.filter(item =>
            !item.includes(':'));
        if (isClusterSetting(diffArray[diffArray.length - 1])) {
            diffArray.pop();
        }
        translationLine = diffArray.join('.');
    } else if (isClusterSetting(hlcfgRealPath[hlcfgRealPath.length - 1])) {
        translationLine = `${hlcfgRealPath.slice(0, hlcfgRealPath.length - 1).join('.')}`;
    }
    return translationLine;
};

export const findTranslationByHlcfgPath = (hlcfgRealPath: HlcfgPath) => {
    return `differs:${findTranslationByHlcfgPathGeneric(hlcfgRealPath)}`;
};
