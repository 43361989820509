/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  MDBBtn,  } from 'mdbreact';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';


class ButtonWithLock extends Component {
    static get propTypes() {
        return {
            onClick: PropTypes.func,
            messageOnLocked: PropTypes.string,
            messageOnOpen: PropTypes.string,
            disabled: PropTypes.bool,
            reset: PropTypes.bool,
            datacy: PropTypes.string,
            color: PropTypes.string,
            openLock: PropTypes.bool,
            className: PropTypes.string,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            lockOpen: props.openLock || false,
        };
    }

    componentDidUpdate(prevProps) {
        const { reset, openLock } = this.props;
        if (reset !== prevProps.reset) {
            this.setState({
                lockOpen: false
            });
        }
        if (openLock && openLock !== prevProps.openLock)  {
            this.setState({
                lockOpen: true
            });
        }
    }

    changeOpen = (value) => {
        this.setState({
            lockOpen: value
        });
    };

    render() {
        const {  lockOpen } = this.state;
        const { onClick, messageOnOpen, messageOnLocked, disabled, datacy, color, className  } = this.props;
        return (
            <MDBBtn
                className={className}
                color={color || (lockOpen ? 'primary' : 'gray')}
                data-cy={datacy}
                disabled={disabled}
                onClick={() => {
                    if (lockOpen) {
                        onClick();
                        this.changeOpen(false);
                    } else {
                        this.changeOpen(true);
                    }}}
            >

                <Message message={lockOpen ? messageOnOpen : messageOnLocked} />
                <Icon
                    className="m-0"
                    name={lockOpen ? 'lock-open-outline' : 'lock-outline'}
                />
            </MDBBtn>
        );
    }
}

export default ButtonWithLock;
