/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Similar to typeof, but 'array' is also a type.
 *
 * @param {any} item
 * @returns {string} 'undefined', 'object', 'boolean', 'number', 'string', 'symbol', 'null', 'function' or 'array'
 */
export const getTypeOf = (item, nullIsObject = true) => {
    if (Array.isArray(item)) {
        return 'array';
    }
    if (item === null && !nullIsObject) {
        return 'null';
    }
    return typeof item;
};
