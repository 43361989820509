/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// various information that don't change after the frontend is started


// actions
const SET_IS_WEBPACK_INITIALIZED = 'ak/constantInformation/SET_IS_WEBPACK_INITIALIZED';
const SET_IS_PRODUCTION_MODE = 'ak/constantInformation/SET_IS_PRODUCTION_MODE';


// initial state
const initialState = {
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_IS_WEBPACK_INITIALIZED:
        return { ...state, isWebpackInitialized: action.isWebpackInitialized };
    case SET_IS_PRODUCTION_MODE:
        return { ...state, isProductionMode: action.isProductionMode };
    default:
        return state;
    }
};

export default reducer;


// data accessors
const getState = rootState => rootState.constantInformation;
export const getIsWebpackInitialized = rootState => getState(rootState).isWebpackInitialized;
export const getIsProductionMode = rootState => getState(rootState).isProductionMode;


// action creators
export const setIsWebpackInitialized = isWebpackInitialized =>
    ({ type: SET_IS_WEBPACK_INITIALIZED, isWebpackInitialized });

export const setIsProductionMode = isProductionMode =>
    ({ type: SET_IS_PRODUCTION_MODE, isProductionMode });


// side effects
export const sagas = [];
