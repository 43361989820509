/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';


class Radio extends Component {
    static get propTypes() {
        return {
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            label: PropTypes.node,
            disabled: PropTypes.bool,
            className: PropTypes.string,
            onClick: PropTypes.func,
            onChange: PropTypes.func.isRequired,
            checked: PropTypes.bool,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            tooltipText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ]),
        };
    }

    static defaultProps = {
        checked: false,
    };

    handleOnChange = (event) => {
        const { onChange, id, name } = this.props;
        onChange({ value: event.target.value, id, name });
    };

    render() {
        const { checked, id, name, label, disabled, className, onClick, value,
            tooltipText } = this.props;
        return (
            <div className={classNames(
                'radio',
                className,
            )}
            >
                <input
                    checked={checked}
                    className="radio__input"
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={this.handleOnChange}
                    onClick={onClick}
                    type="radio"
                    value={value}
                />
                <label
                    className={classNames(
                        'radio__label',
                    )}
                    htmlFor={id}
                >
                    {tooltipText ?
                        <TextWithTooltip
                            className="radio__text"
                            defaultClass={false}
                            text={label}
                            tooltipText={tooltipText}
                            withoutTranslation
                        /> :
                        <span className="radio__text">
                            {label}
                        </span>
                    }
                </label>

            </div>
        );
    }
}

export default Radio;
