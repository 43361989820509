/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * This terrible module somehow detects whether we are on server, in an interactive frontend or in a noninteractive
 * HTML.
 */
const whereAmI = {};

const isServer = typeof window === 'undefined';
const isOffline = !isServer && false; // TODO change when offline GUI becomes relevant
const isAngular = !isServer && !isOffline;

if (Number(isServer) + Number(isOffline) + Number(isAngular) !== 1) {
    throw new Error('Exactly one of isServer, isOffline and isAngular has to be true');
}

if (isServer) {
    whereAmI.server = true;
} else {
    whereAmI.client = true;
    if (isOffline) {
        whereAmI.offline = true;
    } else if (isAngular) {
        whereAmI.angular = true;
        if (window.KMGR_TESTS) {
            whereAmI.tests = true;
        }
    }
}

export default whereAmI;
