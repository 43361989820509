/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const arrayUtils = {};

/**
 * Removes the first occurrence of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
arrayUtils.removeItem = function(array, item) {
    return arrayUtils.removeAtIndex(array, array.indexOf(item));
};

/**
 * Removes all occurrences of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} number of times the item was in the array
 */
arrayUtils.removeAllItems = function(array, item) {
    let nOccurrences = 0;
    while (arrayUtils.removeItem(array, item) !== -1) {
        ++nOccurrences;
    }
    return nOccurrences;
};

/**
 * Removes the item at given index from given array. Does not do anything if
 * the index is negative.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {number} index
 * @returns {number} the index
 */
arrayUtils.removeAtIndex = function(array, index) {
    if (index < 0) {
        return index;
    }
    array.splice(index, 1);
    return index;
};

/**
 * Replaces the first occurrence of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {*} oldItem
 * @param {*} newItem
 * @returns {number} index the item placed at, -1 if the item was not present
 * in the array
 */
arrayUtils.replaceItem = function(array, oldItem, newItem) {
    const index = array.indexOf(oldItem);
    if (index === -1) {
        return index;
    }
    array[index] = newItem;
    return index;
};

/**
 * Moves the first occurrence of given item in given array one position
 * to the front. Does not do anything if the item is not in the array or if
 * the item is at the beginning of the array.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
arrayUtils.moveItemFrontward = function(array, item) {
    const index = array.indexOf(item);
    if (index <= 0) {
        return index;
    }
    array[index] = array[index - 1];
    array[index - 1] = item;
    return index;
};

/**
 * Moves the first occurrence of given item in given array one position to
 * the back. Does not do anything if the item is not in the array or if the
 * item is at the end of the array.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
arrayUtils.moveItemBackward = function(array, item) {
    const index = array.indexOf(item);
    if (index === -1 || index === array.length - 1) {
        return index;
    }
    array[index] = array[index + 1];
    array[index + 1] = item;
    return index;
};

/**
 * Swaps items at given indices.
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 * @param {number} leftIndex
 * @param {number} rightIndex
 */
arrayUtils.swapItems = function(array, leftIndex, rightIndex) {
    const temp = array[leftIndex];
    array[leftIndex] = array[rightIndex];
    array[rightIndex] = temp;
};

/**
 * Returns a function that pushes an item to the array.
 * This function exists because the result of array.push.bind(array) cannot
 * be passed to functions such as Array.prototype.push
 *
 * @memberof arrayUtils
 * @param {Array<*>} array
 */
arrayUtils.getPush = function(array) {
    return function(item) {
        array.push(item);
    };
};

/**
 * Changes given array accordingly to changes from two given arrays.
 *
 * @memberof arrayUtils
 * @param {Array<*>} oldArray
 * @param {Array<*>} newArray
 * @param {Array<*>} changeArray
 * @param {Function} fnCreate - function that creates new item
 */
arrayUtils.changeAccordingToAnotherArray = function(oldArray, newArray, changeArray, fnCreate) {
    oldArray = oldArray.slice(0);
    newArray = newArray.slice(0);
    let changed = true;
    let maxIterations = Math.max(oldArray.length, newArray.length);
    while (changed && maxIterations > 0) {
        --maxIterations;
        changed = false;
        for (let iOld = 0, lOld = oldArray.length; iOld < lOld; ++iOld) {
            const iNew = newArray.indexOf(oldArray[iOld]);
            if (iNew === -1) {
                // removed
                arrayUtils.removeAtIndex(oldArray, iOld);
                arrayUtils.removeAtIndex(changeArray, iOld);
                changed = true;
                break;
            } else if (iOld !== iNew) {
                // moved
                arrayUtils.swapItems(oldArray, iOld, iNew);
                arrayUtils.swapItems(changeArray, iOld, iNew);
                changed = true;
                break;
            }
        }
        for (let jNew = 0, lNew = newArray.length; jNew < lNew; ++jNew) {
            const jOld = oldArray.indexOf(newArray[jNew]);
            if (jOld === -1) {
                // added
                oldArray.splice(jNew, 0, newArray[jNew]);
                changeArray.splice(jNew, 0, fnCreate(newArray[jNew], jNew));
                changed = true;
                break;
            }
        }
    }
};

export default arrayUtils;
