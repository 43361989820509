/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const assignPriorityToSeverity = (candidate, asc) => {
    switch (candidate) {
    case '["Critical"]':
        return asc ? 0 : 6;
    case '["Major"]':
        return asc ? 1 : 5;
    case '["Minor"]':
        return asc ? 2 : 4;
    case '["Informational"]':
        return 3;
    case '["Audit"]':
        return asc ? 4 : 2;
    case '<NULL>':
        return asc ? 5 : 1;
    default:
        return asc ? 6 : 0;
    }
};

export const tableBySeverityComparator = (severityIndex, asc = false, secondaryIndex = 0, secondary = false) =>
    (first, second) => {
        const firstPriority = assignPriorityToSeverity(first[severityIndex], asc);
        const secondPriority = assignPriorityToSeverity(second[severityIndex], asc);
        if (secondary && secondPriority === firstPriority) {
            return asc ?
                first[secondaryIndex] - second[secondaryIndex] :
                second[secondaryIndex] - first[secondaryIndex];
        }
        return secondPriority - firstPriority;
    };

const sortChartByColumn = (chartData, columnIndex) => {

    let isAscending = false;
    if (chartData.paginated.orderedBy[0]) {
        if (chartData.paginated.orderedBy[0].columnIndex === columnIndex) {
            isAscending = !chartData.paginated.orderedBy[0].isAscending;
        }
    }

    chartData.paginated.orderBy(columnIndex, isAscending);

    if (chartData.cols[columnIndex].name === 'eve_e_alert.alert_metadata_sigseverity') {
        chartData.rows.sort(tableBySeverityComparator(columnIndex, isAscending));
    } else {
        chartData.rows.sort(function(left, right) {
            for (let index = 0, len = chartData.paginated.orderedBy.length; index < len; ++index) {
                const itemOrdered = chartData.paginated.orderedBy[index];
                const colIndex = chartData.cols[itemOrdered.columnIndex].index;
                if (left[colIndex] < right[colIndex]) {
                    return itemOrdered.isAscending ? -1 : 1;
                }
                if (left[colIndex] > right[colIndex]) {
                    return itemOrdered.isAscending ? 1 : -1;
                }
            }
            return 0;
        });
    }


    chartData.paginated.actualize();
};

export default sortChartByColumn;
