/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '~frontendLib/scenes.js';
import { createLazy } from '~frontendLib/createLazy.tsx';


const NamedObjectNetaddr = createLazy(() => import('./NamedObjectNetaddr/index.js'));


const CONFIGURATION_NAMED_OBJECT_NETADDR = createScene({
    path: '/configuration/namedObject/netaddr',
    component: NamedObjectNetaddr,
    title: 'scenes:scenes.Configuration.scenes.NamedObject.scenes.Netaddr.title',
});


const scenes = () => {
    return [
        CONFIGURATION_NAMED_OBJECT_NETADDR
    ];
};

export default scenes;
