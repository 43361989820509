/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const LEGEND = {
    'enabled': true,
    'align': 'center',
    'layout': 'horizontal',
    'borderColor': '#909090',
    'borderRadius': 0,
    'navigation': {
        'activeColor': '#274b6d',
        'inactiveColor': '#CCC'
    },
    'shadow': false,
    'itemStyle': {
        'color': '#333333',
        'fontSize': '12px',
        'fontWeight': 'bold'
    },
    'itemHoverStyle': {
        'color': '#000'
    },
    ' itemHiddenStyle': {
        'color': '#CCC'
    },
    'symbolPadding': 5,
    'verticalAlign': 'bottom',
    'x': 0,
    'y': 0,
    'title': {
        'style': {
            'fontWeight': 'bold'
        }
    }
};
