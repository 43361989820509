/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
const IS_LOCKED_SET = 'ak/lock/IS_LOCKED_SET';


// initial state
export const initialState = {};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case IS_LOCKED_SET:
        return {
            ...state,
            locked: action.payload,
            type: action.actionType,
            reason: action.reason,
            action: action.action,
            close: action.close
        };
    default:
        return state;
    }
};

export default reducer;


// value getters and setters
const getState = rootState => rootState.lock;
export const getIsLocked = state => getState(state).locked;
export const getLockedType = state => getState(state).type;
export const getLockedReason = state => getState(state).reason;
export const getLockedAction = state => getState(state).action;
export const getLockedClose = state => getState(state).close;


export const setIsLocked = ({ value, type, reason, action, close }) =>
    ({ type: IS_LOCKED_SET, payload: value, actionType: type, reason, action, close });


// sagas
export const sagas = [];
