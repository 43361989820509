/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';


const OutsideClick = (WrappedComponent) => {
    class HOC extends Component {
        constructor(props) {
            super(props);
            this.setWrapperRef = this.setWrapperRef.bind(this);
            this.handleClickOutside = this.handleClickOutside.bind(this);
            this.state = {
                outsideClick: false
            };
        }
        componentDidMount() {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
        componentWillUnmount() {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
        // Set the wrapper ref
        setWrapperRef(node) {
            this.wrapperRef = node;
        }
        //Alert if clicked on outside of element
        handleClickOutside(event) {
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                this.setState({ outsideClick: true });
            }
            else {
                this.setState({ outsideClick: false });
            }
        }
        render() {
            return (
                <WrappedComponent
                    forwardedRef={this.setWrapperRef}
                    key={this.props}
                    outsideClick={this.state.outsideClick}
                    {...this.props}
                />
            );
        }
    }
    return HOC;
};

export default OutsideClick;
