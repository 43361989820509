/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Radio } from '~frontendComponents/Generic/index.js';


class RadioList extends Component {
    static get propTypes() {
        return {
            name: PropTypes.string.isRequired,
            data: PropTypes.array.isRequired,
            className: PropTypes.string,
            onChange: PropTypes.func.isRequired,
            selectedOptionId: PropTypes.string,
            tag: PropTypes.string,
            dark: PropTypes.bool,
            wrap: PropTypes.bool,
            horizontal: PropTypes.bool,
            listId: PropTypes.string
        };
    }

    static defaultProps = {
        wrap: true,
        dark: false,
        selectedOptionId: '1',
        tag: 'div',
    };


    constructor(props) {
        super(props);
        const { data, selectedOptionId } = this.props;

        this.state = {
            data: data,
            selectedOptionId: selectedOptionId,
        };
    }

    componentDidUpdate = (prevProps) => {
        const { data, selectedOptionId } = this.props;

        if (data !== prevProps.data) {
            this.setState({
                data: data,
            });
        }
        if (selectedOptionId !== prevProps.selectedOptionId) {
            this.setState({
                selectedOptionId: selectedOptionId,
            });
        }
    };

    handleOptionChange = ({ value, id, name }) => {
        const { onChange, listId } = this.props;
        this.setState({
            selectedOptionId: id,
        });
        onChange({ value, id, name, listId });
    };

    render() {
        const { name, className, tag, dark, wrap, horizontal } = this.props;
        const { data, selectedOptionId } = this.state;
        const ListTag = tag;

        return (
            <ListTag
                className={classNames(
                    'radioList',
                    { 'form-group form-group--radio': wrap },
                    { 'form-group--dark': dark && wrap },
                    { 'no-wrap': !wrap },
                    { 'radioList--horizontal': horizontal },
                    className,
                )}
            >
                {data.map((item) => {
                    return (
                        <Radio
                            checked={selectedOptionId === item.id}
                            className="mockForm__field"
                            disabled={item.disabled}
                            id={item.id}
                            key={item.id}
                            label={item.label}
                            name={name}
                            onChange={this.handleOptionChange}
                            tooltipText={item.tooltipText}
                            value={item.value}
                        />
                    );
                })}

            </ListTag>
        );
    }
}

export default RadioList;
