/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Redirect, Route } from 'react-router-dom';
import React, { Suspense } from 'react';

import Handbook from '~handbook/index.js';
import Loader from '~frontendComponents/Loader/index.js';
import { ObjRouteContext } from '~frontendRoot/scenes/ObjRouteContext.ts';


export const createScene = ({
    component, className, exact, isLogout, hideFromMenu, requiredLicensePermission,
    path, scenes, title, Title, icon, menu, isDevice, isAdvanced,
}) => {
    const objRoute = {
        hideFromMenu: typeof hideFromMenu === 'undefined' ? !title && !Title : hideFromMenu,
        className,
        menu,
        title,
        Title,
        isDevice,
        isLogout,
        isAdvanced,
        scenes,
        icon,
        requiredLicensePermission,
    };
    const TheScene = component;
    const RouteComponentNoMemo = () => (
        <Suspense fallback={<Loader className="loader--middle" />}>
            <ObjRouteContext.Provider value={{ license: objRoute.requiredLicensePermission }}>
                <TheScene />
            </ObjRouteContext.Provider>
        </Suspense>
    );
    const RouteComponent = React.memo(RouteComponentNoMemo);

    const route = (
        <Route
            component={() => <RouteComponent />}
            exact={typeof exact === 'undefined' ? !scenes : exact}
            key={path}
            path={path}
        />
    );
    return { ...objRoute, route };
};

export const createRedirect = ({ exact, path, to }) => ({
    hideFromMenu: true,
    route: (
        <Redirect
            exact={exact}
            from={path}
            key={path}
            to={to}
        />
    ),
});

export const HANDBOOK_PATH = '/profile/handbook';


export const HANDBOOK = (t) => {
    return {
        hideFromMenu: true,
        route: (
            <Route
                key={HANDBOOK_PATH}
                path={HANDBOOK_PATH}
                render={props => (
                    <Handbook
                        t={t}
                        {...props}
                    />
                )}
            />
        )
    };
};
