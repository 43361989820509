/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    CPU_THREADS_CHART_ID, CPU_LOAD_CHART_ID,
    CPU_BUSY_CHART_ID, MEMORY_USAGE_CHART_ID, DISK_USAGE_CHART_ID,
    SYSMON_GENERAL, SWAP_USAGE_CHART_ID, DISK_SPACE_TOTAL_CHART_ID, DISK_TIME_CHART_ID,
    GENERIC_UPTIME_ID, GENERIC_BYTES_ID, DISK_OPENED_FILES_ID,  DISK_DONEIOS_ID, GENERIC_PACKETS_ID,
    CHART_HEIGHT_MD, CHART_HEIGHT_SM, GENERIC_DROP_PACKETS_ID,
    GENERIC_DROP_BYTES_ID
} from '~frontendConstants/index.js';

import { addTimeFilter, addTimeFilterInterface } from '../reportsFilters/index.js';
import { LEGEND } from '../reportsUtils.js';


export const sysmonReports = [
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'legend': LEGEND
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'yAxisTitle': 'reporter:chart.titles.usage'
                    }
                }
            },
        ],
        'report': {
            'id': SYSMON_GENERAL,
            'type': 'universal',
            'name': {
                'cs': 'Zdraví systému',
                'en': 'System health',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                    // 'event.host',
                ],
                'metrics': [
                    'sysmon_general.cpu_busy_pct',
                    'sysmon_general.used_mem_pct',
                    'sysmon_general.used_swap_pct',
                    'sysmon_general.disk_used_pct',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter(),
            }
        },
    },


    //CPU

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'plotPoints': false

                    }
                }
            },
        ],
        'report': {
            'id': CPU_BUSY_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Využití procesoru',
                'en': 'CPU usage',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                    'event.host',
                ],
                'metrics': [
                    'sysmon_general.cpu_busy_pct',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false,
                        'yAxisTitle': 'reporter:chart.titles.count'
                    }
                }
            },
        ],
        'report': {
            'id': CPU_THREADS_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Procesy',
                'en': 'Processes',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_general.threads',
                    'sysmon_general.proc',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false

                    }
                }
            },
        ],
        'report': {
            'id': CPU_LOAD_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Systémové zatížení',
                'en': 'System load',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                    'event.host',
                ],
                'metrics': [
                    'sysmon_general.load5',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },


    //MEMORY


    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'endOnTick': false,
                        'plotPoints': false,
                        'yAxisTitle': 'reporter:chart.titles.size'

                    }
                }
            },
        ],
        'report': {
            'id': MEMORY_USAGE_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Využití operační paměti',
                'en': 'Memory usage',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                    'event.host',
                ],
                'metrics': [
                    'sysmon_general.used_mem',
                    'sysmon_general.tot_mem',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'endOnTick': false,
                        'plotPoints': false,
                        'yAxisTitle': 'reporter:chart.titles.size'

                    }
                }
            },
        ],
        'report': {
            'id': SWAP_USAGE_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Využití swapu',
                'en': 'Swap usage',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                    'event.host',
                ],
                'metrics': [
                    'sysmon_general.used_swap',
                    'sysmon_general.tot_swap',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },

    // DISK

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'plotPoints': false,
                        'endOnTick': false,
                        'yAxisTitle': 'reporter:chart.titles.size'

                    }
                }
            },
        ],
        'report': {
            'id': DISK_SPACE_TOTAL_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Zaplnění systémového oddílu',
                'en': 'Usage of system partition',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_general.disk_used',
                    'sysmon_general.disk_mem',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false,

                    }
                }
            },
        ],
        'report': {
            'id': DISK_OPENED_FILES_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet otevřených souborů',
                'en': 'Opened files count',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_general.opened_files',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'title': 'space',
                        'sortBy': 'action',
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false,
                        'yAxisTitle': 'reporter:chart.titles.time',
                    }
                }
            },
        ],
        'report': {
            'id': DISK_TIME_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Čas zápisů / čtení z disku',
                'en': 'Reading / writing time',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_disks',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_disks.time_reading',
                    'sysmon_disks.time_writing',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false,

                    }
                }
            },
        ],
        'report': {
            'id': DISK_DONEIOS_ID,
            'type': 'universal',
            'name': {
                'cs': 'Aktivita disku',
                'en': 'Disk activity',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_disks',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_disks.done_ios',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_MD,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false,
                        'yAxisTitle': 'reporter:chart.titles.count',
                    }
                }
            },
        ],
        'report': {
            'id': DISK_USAGE_CHART_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet zápisů / čtení z disku',
                'en': 'Writes / reads count on disk',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_disks',
                'categories': [
                    'event.date_minute',

                ],
                'metrics': [
                    'sysmon_disks.reads',
                    'sysmon_disks.writes',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2',
                        'plotPoints': false

                    }
                }
            },
        ],
        'report': {
            'id': GENERIC_UPTIME_ID,
            'type': 'universal',
            'name': {
                'cs': 'Uptime',
                'en': 'Uptime',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_general',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_general.uptime'
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'isLogarithmic': true,
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'className': 'mt-2 h-50',
                        'plotPoints': false,
                    }
                }
            },
        ],
        'report': {
            'id': GENERIC_BYTES_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet příchozích a odchozích bajtů',
                'en': 'Number of received and transmitted bytes',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_ifaces',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_ifaces.rec_bytes',
                    'sysmon_ifaces.trans_bytes'

                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'isLogarithmic': true,
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        // 'col': '12',
                        'className': 'mt-2 mb-2',
                        'plotPoints': false,

                    }
                }
            },
        ],
        'report': {
            'id': GENERIC_PACKETS_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet příchozích a odchozích paketů',
                'en': 'Number of received and transmitted packets',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_ifaces',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_ifaces.rec_pkts',
                    'sysmon_ifaces.trans_pkts'

                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'isLogarithmic': true,
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2 mb-2',
                        'plotPoints': false,
                    }
                }
            },
        ],
        'report': {
            'id': GENERIC_DROP_PACKETS_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet příchozích a odchozích zahozených paketů',
                'en': 'Number of incoming and outgoing dropped packets',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_ifaces',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'sysmon_ifaces.rec_drop_pkts',
                    'sysmon_ifaces.trans_drop_pkts'

                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilterInterface({})
            }
        },
    },

    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'isLogarithmic': true,
                    'overridingParameters': {
                        'chart': {
                            'height': CHART_HEIGHT_SM,
                        },
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'className': 'mt-2 mb-2',
                        'plotPoints': false,
                    }
                }
            },
        ],
        'report': {
            'id': GENERIC_DROP_BYTES_ID,
            'type': 'universal',
            'name': {
                'cs': 'Počet příchozích a odchozích chybných paketů',
                'en': 'Number of incoming and outgoing packets that contain errors',
            },
            'params': {
                'database': 'reporter',
                'table': 'sysmon_ifaces',
                'categories': [
                    'event.date_minute',
                    'sysmon_ifaces.iface'

                ],
                'metrics': [
                    'sysmon_ifaces.rec_errs',
                    'sysmon_ifaces.trans_errs'

                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilterInterface({})
            }
        },
    },
];
