/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from '~commonLib/moment.ts';


export const PERSISTED_HLCFG_UPLOAD_ID = 'uniquePersistedHlcfgUploadIdentifier';
export const isPersistedHlcfgUploadLocator =
    (locator: UploadLocatorObject): locator is PersistedHlcfgUploadLocatorObject => {
        return !!locator?.[PERSISTED_HLCFG_UPLOAD_ID];
    };
export const getPersistedHlcfgUploadLocator = ({ resource, time = moment().format(), name }) => ({
    name,
    [PERSISTED_HLCFG_UPLOAD_ID]: resource,
    time,
});


export const TMP_UPLOAD_ID = 'uniqueTmpUploadIdentifier';
export const getTmpUploadLocator = (resource): TmpUploadLocatorObject => ({ [TMP_UPLOAD_ID]: resource });
export const isTmpUploadLocator = (locator: UploadLocatorObject): locator is TmpUploadLocatorObject => {
    return !!locator?.[TMP_UPLOAD_ID];
};

export const STATIC_DEFAULT_UPLOAD_ID = 'uniqueStaticDefaultUploadIdentifier';
export const getStaticDefaultUploadLocator = resource => ({ [STATIC_DEFAULT_UPLOAD_ID]: resource });
export const getStaticDefaultUploadLocatorResourceId =
    (locator: StaticDefaultUploadLocatorObject) => locator[STATIC_DEFAULT_UPLOAD_ID];
export const isStaticDefaultUploadLocator =
    (locator: UploadLocatorObject): locator is StaticDefaultUploadLocatorObject => {
        return !!locator?.[STATIC_DEFAULT_UPLOAD_ID];
    };

export const INTERNALLY_MANAGED_CERTIFICATE_ID = 'internallyManagedCertificateIdentifier';
export const getInternallyManagedCertificateLocatorResourceId =
    (locator: InternallyManagedCertificateLocatorObject) => locator[INTERNALLY_MANAGED_CERTIFICATE_ID];
export const getInternallyManagedCertificateLocator = (opts: {
    resource: string,
    generateForDays?: number,
    hostnames?: string[],
}): InternallyManagedCertificateLocatorObject => ({
    [INTERNALLY_MANAGED_CERTIFICATE_ID]: opts.resource,
    generateForDays: opts.generateForDays,
    hostnames: opts.hostnames,
});
export const isInternallyManagedCertificateLocator =
    (locator: UploadLocatorObject): locator is InternallyManagedCertificateLocatorObject => {
        return !!locator?.[INTERNALLY_MANAGED_CERTIFICATE_ID];
    };


export const isConcreteResourceLocator = (value): value is ConcreteResourceLocatorObject => [
    isPersistedHlcfgUploadLocator,
    isStaticDefaultUploadLocator,
    isTmpUploadLocator,
].some(isLocator => isLocator(value));

export const isUploadLocator = (value): value is UploadLocatorObject => [
    isConcreteResourceLocator,
    isInternallyManagedCertificateLocator,
].some(isLocator => isLocator(value));


export type PersistedHlcfgUploadLocatorObject = {
    [PERSISTED_HLCFG_UPLOAD_ID]: string,
    name?: string,
    time: string,
}
export type TmpUploadLocatorObject = {
    [TMP_UPLOAD_ID]: string,
    name?: string,
    time?: string,
}
export type StaticDefaultUploadLocatorObject = {
    [STATIC_DEFAULT_UPLOAD_ID]: string,
}
export type InternallyManagedCertificateLocatorObject = {
    [INTERNALLY_MANAGED_CERTIFICATE_ID]: string,
    generateForDays?: number,
    hostnames?: string[],
}

export type AbstractResourceLocatorObject = InternallyManagedCertificateLocatorObject;
export type ConcreteResourceLocatorObject = PersistedHlcfgUploadLocatorObject |
    TmpUploadLocatorObject |
    StaticDefaultUploadLocatorObject

export type UploadLocatorObject = AbstractResourceLocatorObject|
    ConcreteResourceLocatorObject;

export const CONCRETE_LOCATOR_KEYS = [
    PERSISTED_HLCFG_UPLOAD_ID,
    TMP_UPLOAD_ID,
    STATIC_DEFAULT_UPLOAD_ID,
];
