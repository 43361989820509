/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { callResizeEvent } from '~frontendLib/reactUtils.js';

// actions
const NAVBARLEFT_COLLAPSE = 'ak/layout/navBarLeft/NAVBARLEFT_COLLAPSE';
const NAVBARLEFT_UNCOLLAPSE = 'ak/layout/navBarLeft/NAVBARLEFT_UNCOLLAPSE';
const NAVBARLEFT_TEMPOPEN = 'ak/layout/navBarLeft/NAVBARLEFT_TEMPOPEN';
const NAVBARLEFT_TEMPCLOSE = 'ak/layout/navBarLeft/NAVBARLEFT_TEMPCLOSE';
const SET_HELP = 'ak/layout/navBarLeft/SET_HELP';


// initial state
export const initialState = {
    isCollapsed: false,
    isTempOpen: false,
    activeRoute: '/overview/system',
    isHelp: false,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case NAVBARLEFT_COLLAPSE:
        callResizeEvent();
        return { ...state, isCollapsed: true, isTempOpen: false };
    case NAVBARLEFT_UNCOLLAPSE:
        callResizeEvent();
        return { ...state, isCollapsed: false };
    case NAVBARLEFT_TEMPOPEN:
        return { ...state, isTempOpen: true };
    case NAVBARLEFT_TEMPCLOSE:
        return { ...state, isTempOpen: false };
    case SET_HELP:
        callResizeEvent();
        return { ...state, isHelp: action.payload !== undefined ? action.payload : !state.isHelp };
    default: return state;
    }
};
export default reducer;


// data accessors
const getState = rootState => rootState?.layout?.navBarLeft || initialState;

/** Is navBarLeft collapsed? */
export const getIsCollapsed = state => getState(state).isCollapsed;

/** Is navBarLeft temporary open (over isCollapsed state)? */
export const getIsTempOpen = state => getState(state).isTempOpen;
export const getIsHelp = state => getState(state).isHelp;
export const getIsHelpHook = () => state => getState(state).isHelp;


// action creators
export const collapseNavBar = () =>
    ({ type: NAVBARLEFT_COLLAPSE });

export const setHelp = (payload) =>
    ({ type: SET_HELP, payload });

export const unCollapseNavBar = () =>
    ({ type: NAVBARLEFT_UNCOLLAPSE });

export const tempOpenNavBar = () =>
    ({ type: NAVBARLEFT_TEMPOPEN });

export const tempCloseNavBar = () =>
    ({ type: NAVBARLEFT_TEMPCLOSE });


export const sagas = [
];
