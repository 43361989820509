/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, Draft, PayloadAction } from '@reduxjs/toolkit';

import { call, put, take } from '~commonLib/reduxSagaEffects.ts';
import {
    dispatchOnEventInNamespaceAction,
    SocketIoEventWithActions,
    SocketIOReduxObject,
    stopDispatchingOnEventInNamespaceAction
} from '~frontendDucks/socketIO/index.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { AbortError, actionSequenceStepObject, getEventsWithActions } from '~frontendLib/actionSequence/lib.ts';
import { ActionSequenceInfo } from '~sharedLib/types.ts';
import {
    ActionSequencePayloadBase,
    ActionSequencePayloadError,
    ActionSequenceRequestActionPayloadType
} from '~frontendLib/actionSequence/types.ts';
import { ACTION_SEQUENCE_LOCKED_EVENT, ACTION_SEQUENCE_PROGRESS_ERROR_KEY, NODE_SELF } from '~commonLib/constants.ts';
import { setIsLocked } from '~frontendRoot/ducks/lock/index.js';
import { setActivationOnNodes } from '~frontendRoot/ducks/actionSequence/index.ts';


export const getWorkerActionSequence = <InitiatingAction extends PayloadAction<any>>({
    actionSequenceType,
    actionSequenceSucceeded,
    actionSequenceFailed,
    workers,
    fnStart,
    close
}: WorkerActionSequenceInterface<InitiatingAction>) => {
    const eventsWithActions = getEventsWithActions(workers, actionSequenceFailed, actionSequenceSucceeded);
    return function* (action: InitiatingAction) {
        let actionSequenceId;
        const sourceNodes = action.payload?.nodes ?? [ NODE_SELF ];
        const nodesCount = new Set(sourceNodes).size;
        try {
            yield put(setActivationOnNodes({ sourceNodes }));
            actionSequenceId = yield startActionSequence({ fnStart, action, actionSequenceType, close });
        } catch (error) {
            yield put(actionSequenceFailed([ {
                [ACTION_SEQUENCE_PROGRESS_ERROR_KEY]: getApiError(error),
                sourceNodes: [] }
            ]));
            return;
        }
        const objForSocket = {} as SocketIOReduxObject;

        const eventsWithLockedEvent = [
            {
                event: ACTION_SEQUENCE_LOCKED_EVENT,
                actionCreator: [
                    getRetryOnAlreadyLockedActionCreator(action),
                    () => stopDispatchingOnEventInNamespaceAction(objForSocket),
                ]
            },
            ...eventsWithActions,
        ] as SocketIoEventWithActions[];

        objForSocket.namespace = actionSequenceId;
        objForSocket.eventsWithActions = eventsWithLockedEvent;
        objForSocket.emitArray = true;

        yield put(dispatchOnEventInNamespaceAction(objForSocket));


        const finishedNodes = new Set();
        do {
            const { payload = [] } = yield take([
                actionSequenceSucceeded.type, actionSequenceFailed.type,
            ]);
            payload.forEach(item => {
                const nodes = item.sourceNodes || [ NODE_SELF ];
                nodes.forEach(node => {
                    finishedNodes.add(node);
                });
            });
        } while (nodesCount !== finishedNodes.size);

        yield put(stopDispatchingOnEventInNamespaceAction(objForSocket));
    };
};

const getRetryOnAlreadyLockedActionCreator = action => (payload) => {
    return {
        ...action,
        payload: {
            ...action.payload,
            locked: payload[0].locked,
        }
    };
};

class AbortInSequenceError extends AbortError {}

type FnStartArgs<T> = {
    action: T,
    breakLock: boolean|undefined,
}
export type ActionSequenceFnStartGenerator<T> = (opts: FnStartArgs<T>) => Generator<any, ActionSequenceInfo>
export type ActionSequenceFnStartPromise<T> = (opts: FnStartArgs<T>) => Promise<ActionSequenceInfo>
export type ActionSequenceFnStartShape<T> = ActionSequenceFnStartGenerator<T> | ActionSequenceFnStartPromise<T>;

interface WorkerActionSequenceInterface<T> {
    actionSequenceType: string,
    actionSequenceSucceeded: ActionCreatorWithPayload<ActionSequencePayloadBase[]> | ActionCreatorWithoutPayload,
    actionSequenceFailed: ActionCreatorWithPayload<ActionSequencePayloadError[]>,
    workers: actionSequenceStepObject[],
    fnStart: ActionSequenceFnStartShape<T>,
    close: ActionCreatorWithoutPayload,
}

type StartActionSequenceParams<T> = {
    fnStart: ActionSequenceFnStartShape<T>,
    action: T,
    actionSequenceType: string,
    close:  ActionCreatorWithoutPayload,
}
const startActionSequence =
    function* <T extends {payload: any}>(
        { fnStart, action, actionSequenceType, close }: StartActionSequenceParams<T>
    ) {
        const locked = action?.payload?.locked;
        const firstTryResult: ActionSequenceInfo = locked ? { locked } : yield call(fnStart, {
            action, breakLock: action?.payload?.breakLock
        });

        if (!firstTryResult.locked) {
            return firstTryResult.actionSequenceId;
        }
        try {
            yield put(setIsLocked({
                value: true,
                type: actionSequenceType,
                reason: firstTryResult,
                action: action,
                close: close.toString()
            }));
        } catch (error) {
            throw new AbortInSequenceError('aborted by lock dialog');
        }
    };

// immer for some reason has some issues if existing object is repeatadly directly assigned or copied.
// hence the getter instead of plain object.
export const getNewNodeInitialState = () => ({
    progress: [] as any[],
    isAborted: false,
    error: null,
    isFinished: false,
});

type NodeInitialStateShape = ReturnType<typeof getNewNodeInitialState>;
export type InitialStateByNode = { byNode: { [node: string]: NodeInitialStateShape } };

export const errorAdder = (state, action: PayloadAction<ActionSequencePayloadError[]>) => {
    action.payload.forEach(event => {
        const nodes = event.sourceNodes || Object.keys(state.byNode);
        nodes.forEach(node => {
            if (!state.byNode[node]) {
                state.byNode[node] = getNewNodeInitialState();
            }
            state.byNode[node].error = event[ACTION_SEQUENCE_PROGRESS_ERROR_KEY];
            state.byNode[node].isFinished = true;
        });
        if (!event.sourceNodes?.length) {
            state.error = event[ACTION_SEQUENCE_PROGRESS_ERROR_KEY];
        }
    });
};
export const successAdder = (state, action: PayloadAction<ActionSequencePayloadBase[]>) => {
    action.payload.forEach(event => {
        const nodes = event.sourceNodes || Object.keys(state.byNode);
        nodes.forEach(node => {
            state.byNode[node].isFinished = true;
        });
    });
};

export const progressAdder = <T extends ActionSequencePayloadBase>(actionStep, progressMapper: (x: T) => any) =>
    (state: Draft<InitialStateByNode>, action: PayloadAction<T[]>) => {
        action.payload.forEach(progressItem => {
            progressItem.sourceNodes.forEach(node => {
                if (!state.byNode[node]) {
                    state.byNode[node] = getNewNodeInitialState();
                }
                state.byNode[node].progress.push({
                    actionStep,
                    ...progressMapper(progressItem),
                });
            });
        });
    };

export const getCommonActionSequenceGetters = getState => {
    const getNodeState = (rootState, node = NODE_SELF) => getState(rootState).byNode[node] || getNewNodeInitialState();
    const getIsOpen = rootState => getState(rootState).isOpen;
    const getIsFinished = rootState => {
        const state = getState(rootState);
        const nodes = Object.keys(state.byNode);
        const isError = getState(rootState).error;
        return nodes.length && nodes.every(node => getNodeState(rootState, node).isFinished) || isError;
    };
    const getIsLoading = rootState => !getIsFinished(rootState);

    const getIsAborted = (rootState, node) => getNodeState(rootState, node).isAborted;
    const getError = (rootState, node) => getNodeState(rootState, node).error || getState(rootState).error;
    const getProgress = (rootState, node) => getNodeState(rootState, node).progress;
    return {
        getNodeState,
        getIsOpen,
        getIsFinished,
        getIsLoading,
        getIsAborted,
        getError,
        getProgress,
    };
};

export const getSequenceOpener = (initialState, keyToKeep?: string) => (
    state,
    action: ActionSequenceRequestActionPayloadType
) => {
    const { replayingActionSequenceId = '', isOpen = true, nodes, breakLock } = action.payload;
    const returnValue = {
        ...initialState,
        replayingActionSequenceId,
        isOpen,
        byNode: nodes ?
            nodes.reduce((acc, node) => ({ ...acc, [node]: getNewNodeInitialState() }), {}) :
            {},
        breakLock
    };
    if (keyToKeep) {
        returnValue[keyToKeep] = state[keyToKeep];
    }
    return returnValue;
};
