/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import Tippy from '@tippyjs/react';

import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';


type TooltipType = {
    content?: React.ReactNode,
    children?: React.ReactNode,
    className?: string,
    onClick?: () => void,

    //cypress
    datacy?: string
};

const EmptyWrapper = ({ children }) => children;
const Tooltip = ({ content, children, className, onClick, datacy }: TooltipType) => {
    // This prevents rendering of tooltip before hovering over the element, because Tippy causes additional renders.
    // Which degrades performance with many tooltips present
    const [ renderTooltip, setRenderTooltip ] = useBoolean(false);
    const Tag = renderTooltip ? Tippy : EmptyWrapper;
    if (content) {
        return (
            <Tag
                content={content}
            >
                <span
                    className={className}
                    data-cy={datacy}
                    onClick={onClick}
                    onMouseOver={setRenderTooltip.on}
                >
                    {children}
                </span>
            </Tag>
        );
    } else {
        return (
            <>
                {children}
            </>
        );
    }
};

export default Tooltip;
