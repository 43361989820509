/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { HlcfgPath } from '~sharedLib/types.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import {
    findTranslationByHlcfgPath,
    findTranslationByHlcfgPathGeneric
} from '~sharedLib/hlcfg/findTranslationByHlcfgPath.ts';
import hlcfgPathsAdditionalData from '~frontendRoot/i18n/hlcfgPathsAdditionals.en.yaml';
import { getValue } from '~commonLib/objectUtils.ts';
import { HlcfgRowId, hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { HlcfgSchemaJSON } from '~frontendTypes/externalTypes.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { NODE_A_ID, NODE_B_ID, NODE_SHARED } from '~commonLib/constants.ts';
import { yamlParseWithoutLogging } from '~commonLib/yamlUtils.ts';


const hlcfgPathsAdditional = yamlParseWithoutLogging(hlcfgPathsAdditionalData);


export const isStaticSegment = (segment: TranslationSegment): segment is StaticSegment =>
    'staticTranslationPath' in segment;

export type StaticSegment = {
    staticTranslationPath: string,
}

export type RowSegment = {
    rowId: HlcfgRowId,
    translationPath: string,
}

export type TranslationSegment = StaticSegment | RowSegment

export const descriptiveHlcfgPathToTranslationSegment =
    (descriptivePath: HlcfgPath, schema: HlcfgSchemaJSON, forCluster = false): TranslationSegment[] => {
        const segments: TranslationSegment[] = [];

        for (let i = 1; i <= descriptivePath.length; i++) {
            if (descriptivePath[i - 1] === '$byId') {
                continue;
            }
            if (i >= 2 && descriptivePath[i - 2] === '$byId') {
                const rowId = descriptivePath[i - 1];

                segments.push({
                    translationPath: `hlcfgPathsAdditionals:tables.${hlcfgTableNameByRowId(rowId)}.title`,
                    rowId,
                });
                continue;
            }
            const subPath = descriptiveHlcfgPathToRealPath(descriptivePath.slice(0, i));

            if (i === descriptivePath.length) {
                const isEnum = Boolean(findSchemaByObjectPathAndSchema(subPath, schema)?.enum);
                const staticTranslationPath = isEnum ? findTranslationByHlcfgPath(subPath) + '.title' :
                    findTranslationByHlcfgPath(subPath);
                segments.push({
                    staticTranslationPath,
                });
                if (forCluster && [ NODE_A_ID, NODE_B_ID, NODE_SHARED ].includes(descriptivePath[i - 1])) {
                    segments.push({ staticTranslationPath: clusterSelectorTranslationMap[descriptivePath[i - 1]] });
                }
                continue;
            }

            const additionalTranslationPath = findTranslationByHlcfgPathGeneric(subPath) + '.title';
            if (getValue(hlcfgPathsAdditional, additionalTranslationPath)) {
                segments.push({
                    staticTranslationPath: 'hlcfgPathsAdditionals:' + additionalTranslationPath,
                });
            }
        }
        return segments;
    };


const clusterSelectorTranslationMap = {
    [NODE_A_ID]: 'widgets:network.cluster.clusterNode.nodeA',
    [NODE_B_ID]: 'widgets:network.cluster.clusterNode.nodeB',
    [NODE_SHARED]: 'widgets:network.cluster.clusterNode.shared',
};
