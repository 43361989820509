/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/index.ts';


class PopMessage extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Set the wrapper ref
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    // Event if clicked on outside of component
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClose();
        }
    }

    render() {
        const { children, className, color, isVisible, onClose } = this.props;

        return (
            <div
                className={classNames(className, 'popMessage', {
                    'popMessage--isVisible': isVisible,
                    [`popMessage--${color}`]: color,
                })}
                ref={this.setWrapperRef}
            >
                {children}
                <button
                    className="popMessage__btn btn btn-close btn-transparent"
                    onClick={onClose}
                    type="button"
                >
                    <Icon name="close" />
                </button>
            </div>
        );
    }
}

export default PopMessage;

PopMessage.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};
