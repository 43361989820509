/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Makes a deep copy of the given object, but only of subobjects on given path.
 *
 * @param {object} object
 * @param {string[]} path
 * @returns {object} deep copied object
 */
const deepCopyObjectOnPath = (object = {}, path) => {
    const [ firstKey, ...restOfPath ] = path;
    const result = Array.isArray(object) ?
        [ ...object ] :
        typeof object === 'object' && object ?
            { ...object } :
            {};
    if (typeof firstKey !== 'undefined') {
        result[firstKey] = deepCopyObjectOnPath(object[firstKey], restOfPath);
    }
    return result;
};

export default deepCopyObjectOnPath;
