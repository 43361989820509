/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';


const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 1,

};

const transitionStyles = {
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

const TransitionFade = ({ children, in: inProp, timeout }) => (
    <Transition
        in={inProp}
        timeout={timeout}
    >
        {state => (
            <div style={{
                ...defaultStyle,
                ...transitionStyles[state]
            }}
            >
                {children}
            </div>
        )}
    </Transition>
);


TransitionFade.propTypes = {
    children: PropTypes.node,
    in: PropTypes.bool,
    timeout: PropTypes.number,
};

export default TransitionFade;
