/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    UseQueryOptions,
} from '@tanstack/react-query';

import { backendDataGet } from '~frontendLib/backendApiCalls.ts';


export const systemQueries = {
    networkInfo: {
        queryKey: [ 'networkInfo' ],
        queryFn: backendDataGet('/system/getNetworkInfo'),
    },
    expectedSystemComponentsList: {
        queryKey: [ 'expectedSystemComponentsList' ],
        queryFn: backendDataGet('/components/expectedList'),
    },
    systemComponentsList: {
        queryKey: [ 'systemComponentsList' ],
        queryFn: backendDataGet('/components/list'),
    },
    licenseInfo: {
        queryKey: [ 'licenseInfo' ],
        queryFn: backendDataGet('/utils/getLicensePermissions'),
    },
} satisfies Record<string, UseQueryOptions>;
