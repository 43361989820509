/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';

import { orderOfScene, TypeOfScene } from '~sharedConstants/index.ts';

// initial state
const initialStateCreator = () => {
    const init = {};
    for (const type in orderOfScene) {
        init[type] = false;
    }
    return init;
};

const initialState = initialStateCreator();


type changeHeaderProps = {
    toClose: TypeOfScene,
    value: boolean
}

const activeDiffersCards = createSlice({
    name: 'ak/activeDiffersCards',
    initialState,
    reducers: {
        changeHeader: (state, { payload }: {payload: changeHeaderProps}) => {
            state[payload.toClose] = payload.value;
        },
        setInitDiffCards: () => {
            return initialState;
        }

    },
});

export const {
    changeHeader,
    setInitDiffCards,
} = activeDiffersCards.actions;

export default activeDiffersCards.reducer;


const getRootState = state => state.activeDiffersCards;

export const getActiveDiffCardHook = (type: TypeOfScene) => (state) => getRootState(state)[type];
