/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';
/**
 * This file exists to help avoid import cycles.
 * This file should not have any non-node_modules imports to avoid getting into that situation again.
 * This allows external files to import glcfgGetters without all the dependencies of the main file.
 */


export const getState = rootState => rootState.hlcfgEditor;
const getGlcfgTree = rootState => getState(rootState).glcfgTree;
export const getGlcfgNodeValue = (rootState, key) => getValue(getGlcfgTree(rootState), key);
export const getGlcfgValue = (rootState, glcfgKey) => getGlcfgNodeValue(rootState, glcfgKey);
export const getIsCluster = (state) => getGlcfgValue(state, 'isCluster');
