/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const isNamedObject = obj => {
    if (typeof obj === 'string') {
        return [ 'netaddrScalar', 'netaddrVector' ].includes(obj.split(':')[0]);
    }
    return !!obj?.__namedObjectReference;
};

export const namedObjectToString = obj => {
    if (typeof obj === 'string') {
        return obj;
    }
    return obj?.__namedObjectReference || '';
};

export const namedObjectStringToObject = strNamedObject => {
    if (typeof strNamedObject === 'string') {
        return {
            __namedObjectReference: strNamedObject,
        };
    }
};
