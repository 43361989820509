/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const DEVEL_DASHBOARD_ID = 'develope-c0bf-4de3-b621-7d0ba7bdcuuu';

export const SYSTEM_HEALTH_DASHBOARD_ID = 'ca2ea660-eb73-41fb-b4fc-1394a4179556';
export const CPU_HEALTH_DASHBOARD_ID = '3bfb44f5-1f3f-4ee6-a279-6d0f9bb14232';
export const MEMORY_HEALTH_DASHBOARD_ID = '10e9e087-fb32-4769-88f0-ea9f360dab18';
export const SPECIFIC_DISK_HEALTH_DASHBOARD_ID = '94ef26a0-232b-495a-8c52-c6437f1c1871';
export const DISK_HEALTH_DASHBOARD_ID = '03be4c65-a188-4db0-9798-6752fc7ad75f';
export const DISK_DASHBOARD_ID = 'c9cb585f-07ba-4b3b-8672-9ac7610ac1d1';


export const NETWORK_REPORT = '755a7cc1-2a29-4132-8749-c7ce075af8dd';
export const NETWORK_REPORT_FAST = '16f1e0e8-0a6e-4cc1-8df1-61f1c1aef1e6';
export const NETWORK_CARD_REPORT = '933bf751-65e1-4a8c-a522-8fcb07b2516d';
export const NETWORK_CARD_DROP_PACKETS_REPORT = 'aa192788-0f26-4e0e-8ab6-77ec6acd2fe4';


export const PROXY_REPORT_FAST = '4744da46-cea9-498b-9357-99829befff7d';
export const PROXY_REPORT = 'b03b967d-ae18-4a9b-b4ac-094e8d81362c';

export const NFT_REPORT = '6a1d92f1-44c9-478e-a1f6-6995b2ae1051';
export const NFT_REPORT_FAST = '51e17bb0-910d-4077-999e-ab4bd43f096c';

export const ADAPTIVE_FIREWALL_DASHBOARD_ID = 'f8aeadcd-4fc3-4970-a584-fce3d9821b6c';
export const IPS_DASHBOARD_ID = '48da4963-e7f7-4505-b859-32a40094919d';
