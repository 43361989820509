/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';

import { I18_TRANSLATION_STRING_REGEX } from '~sharedConstants/index.ts';

import Message from '../../../Message/index.js';


const InputMessageContent = ({ data, t, activeItem, id, itemTag, handleOnClick }) => {
    const ItemTag = itemTag || 'li';

    if (!data) {
        return null;
    }

    if (!Array.isArray(data)) {
        return typeof data === 'object' ? data : <Message message={data} />;
    }

    return (
        <React.Fragment>
            <div
                className="form-control__counter"
                data-tooltip={t(
                    'components:InputMessage.Tooltip',
                    { activeItem: activeItem, maxItem: data.length }
                )}
                onClick={handleOnClick}
            >
                <span>{activeItem}/{data.length}</span>
            </div>
            <ol className="form-control__messageList">
                {data.map((item, index) => {
                    return (
                        <ItemTag
                            className={classNames(
                                'form-control__message',
                                { 'form-control__message--active': index + 1 === activeItem },
                            )}
                            id={`${id}__messageId${index + 1}`}
                            key={index}
                            onClick={handleOnClick}
                        >
                            {typeof item === 'object' || item.match(I18_TRANSLATION_STRING_REGEX) ?
                                item :
                                <Message message={item} />
                            }
                        </ItemTag>
                    );
                })}
            </ol>
        </React.Fragment>
    );
};

InputMessageContent.propTypes = {
    activeItem: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    data: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    id: PropTypes.string.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    t: PropTypes.func,
    itemTag: PropTypes.string,
};

@withTranslation()
class InputMessage extends Component {
    static get propTypes() {
        return {
            activeItem: PropTypes.string,
            className: PropTypes.string,
            children: PropTypes.node,
            data: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.string,
                PropTypes.array,
            ]),
            id: PropTypes.string.isRequired,
            t: PropTypes.func,
            tag: PropTypes.string,
            itemTag: PropTypes.string,
            small: PropTypes.bool,
            datacy: PropTypes.string,
        };
    }

    static defaultProps = {
        tag: 'div',
    };

    constructor(props) {
        super(props);
        const { activeItem, data } = this.props;
        this.state = {
            activeItem: activeItem || 1,
            data: data,
        };
    }

    componentDidUpdate(prevProps) {
        const { activeItem, data } = this.props;
        if (activeItem !== prevProps.activeItem) {
            this.setState({
                activeItem: activeItem
            });
        }
        if (data !== prevProps.data) {
            this.setState({
                data: data
            });
        }
    }

    handleOnClick = () => {
        const { activeItem, data } = this.state;
        if (activeItem < data.length) {
            this.setState(
                { activeItem: activeItem + 1 },
            );
        } else {
            this.setState(
                { activeItem: 1 },
            );
        }
    };

    render() {
        const { children, className, t, tag, id, itemTag, small, datacy } = this.props;
        const { activeItem, data } = this.state;
        const MessageTag = tag;
        return (
            <MessageTag
                className={classNames(
                    { 'form-control__messageWrap': Array.isArray(data) },
                    { 'form-control__message': !Array.isArray(data) },
                    { 'form-control__message--smaller': !Array.isArray(data) && small },

                    className,
                )}
                data-cy={datacy + 'Message'}
            >
                <InputMessageContent
                    activeItem={activeItem}
                    data={data}
                    handleOnClick={this.handleOnClick}
                    id={id}
                    itemTag={itemTag}
                    t={t}
                />

                {children}

            </MessageTag>
        );
    }
}

export default InputMessage;
