/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Active directory entity name.
// eslint-disable-next-line max-len
// const RE_USERS = /^([a-z][a-z0-9-]*)=(?![ #])(((?![\\="+,;<>]).)|(\\[ \\#="+,;<>])|(\\[a-f0-9][a-f0-9]))*(,([a-z][a-z0-9-]*)=(?![ #])(((?![\\="+,;<>]).)|(\\[ \\#="+,;<>])|(\\[a-f0-9][a-f0-9]))*)*$/;

// Taken from node 'net' module and adjusted for our needs
// IPv4 Segment
const v4Seg = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
const v4Str = `(${v4Seg}[.]){3}${v4Seg}`;
const IPv4Reg = new RegExp(`${v4Str}`);

// IPv6 Segment
const v4MappedNotSupported = 'replace with v4Str when adding support';
const v6Seg = '(?:[0-9a-fA-F]{1,4})';
const IPv6Reg = new RegExp(
    '(' +
    `(?:${v6Seg}:){7}(?:${v6Seg}|:)|` +
    `(?:${v6Seg}:){6}(?:${v4MappedNotSupported}|:${v6Seg}|:)|` +
    `(?:${v6Seg}:){5}(?::${v4MappedNotSupported}|(:${v6Seg}){1,2}|:)|` +
    `(?:${v6Seg}:){4}(?:(:${v6Seg}){0,1}:${v4MappedNotSupported}|(:${v6Seg}){1,3}|:)|` +
    `(?:${v6Seg}:){3}(?:(:${v6Seg}){0,2}:${v4MappedNotSupported}|(:${v6Seg}){1,4}|:)|` +
    `(?:${v6Seg}:){2}(?:(:${v6Seg}){0,3}:${v4MappedNotSupported}|(:${v6Seg}){1,5}|:)|` +
    `(?:${v6Seg}:){1}(?:(:${v6Seg}){0,4}:${v4MappedNotSupported}|(:${v6Seg}){1,6}|:)|` +
    `(?::((?::${v6Seg}){0,5}:${v4MappedNotSupported}|(?::${v6Seg}){1,7}|:))` +
    ')' /* + '(%[0-9a-zA-Z-.:]{1,})?' // device mapped not supported */
);
// / Taken from node 'net' module


/** A single IPv4 address */
export const BASE_IP4_REGEX = IPv4Reg;
/** An IPv4 mask */
const BASE_MASK = /(3[0-2]|[12][0-9]|[0-9])/;

/** An IPv6 address */
const BASE_IP6 = IPv6Reg;
/** An IPv6 prefix */
const BASE_PREFIX = /([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8])/;
/**
 * A single domain name, i.e. name of an entity in a network, does not have to contain dots (because it is possible to
 * configure resolving in such way that it automatically appends network name; also "localhost" is a valid domain name)
 */
const RE_DOMAIN = /^([a-zA-Z0-9_][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9_]*[a-zA-Z_][a-zA-Z0-9-_]*$/;
const RE_DOMAIN_PATTERN = /^(\*\.){0,1}([a-zA-Z0-9_][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9_]*[a-zA-Z_][a-zA-Z0-9-_]*$/;
/**
 * Domains in string
 */

const RE_DOMAIN_IN_STRING = /([a-zA-Z0-9_][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9_]*[a-zA-Z_][a-zA-Z0-9-_]*/;
const RE_DOMAIN_PATTERN_IN_STRING = /(\*\.){0,1}([a-zA-Z0-9_][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9_]*[a-zA-Z_][a-zA-Z0-9-_]*/;
/** A single hostname, i.e. a name of a computer, without dots */
const RE_HOSTNAME = /^[a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9-]*$/;

export const netaddrRegexes = {
    address: new RegExp('^(' + BASE_IP4_REGEX.source + '|' + BASE_IP6.source + ')$'),
    addressGlobal: new RegExp(
        '(\\b' + BASE_IP4_REGEX.source + '/' + BASE_MASK.source + '\\b|\\b' +
        BASE_IP4_REGEX.source + '\\b|\\b' +
        BASE_IP6.source + '/' + BASE_PREFIX.source + '\\b|\\b' +
        BASE_IP6.source + '\\b|' + RE_DOMAIN_PATTERN_IN_STRING.source + '\\b|\\b' +
        RE_DOMAIN_IN_STRING.source + '\\b)', 'g',
    ),
    ip4Basic: new RegExp('^' + BASE_IP4_REGEX.source + '$'),
    ip4Range: new RegExp('^' + BASE_IP4_REGEX.source + ' *- *' + BASE_IP4_REGEX.source + '$'),
    ip4WithMask: new RegExp('^' + BASE_IP4_REGEX.source + '/' + BASE_MASK.source + '$'),
    ip6Basic: new RegExp('^' + BASE_IP6.source + '$'),
    ip6WithPrefix: new RegExp('^' + BASE_IP6.source + '/' + BASE_PREFIX.source + '$'),
    hostname: RE_HOSTNAME,
    domain: RE_DOMAIN,
    domainInString: RE_DOMAIN_IN_STRING,
    domainWithPattern: RE_DOMAIN_PATTERN,
};
