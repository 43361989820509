/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Module that provides utility functions for array.
 */


import assert from 'assert';

import { WithChildrenOfSelf } from './types.ts';

/**
 * Removes the first occurrence of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
export const removeItem = (array, item) => {
    return removeAtIndex(array, array.indexOf(item));
};

/**
 * Move item in array on new position and pushing rest
 *
 * @param {Array<*>} array
 * @param {number} oldIndex
 * @param {number} newIndex
 * @returns {Array} new array
 */
export const moveItemOnIndex = (array, oldIndex, newIndex) => {
    if (newIndex < 0) {
        newIndex = 0;
    }
    const copyArray = array.slice(0);
    const item = copyArray.splice(oldIndex, 1)[0];
    copyArray.splice(newIndex, 0, item);
    return copyArray;
};

/**
 * Function to add before index in array.
 *
 * @param {Array} array
 * @param {number} index - index to place after
 * @param {*} newItem - new item to place in array
 * @returns {Array}
 */
export const addBefore = (array, index, newItem) => {
    return [
        ...array.slice(0, index > 0 ? index : 0),
        newItem,
        ...array.slice(index > 0 ? index : 0)
    ];
};

export const addAfter = (array, index, newItem) => {
    const newArr = [ ...array ];
    newArr.splice(index + 1, 0, newItem);
    return newArr;
};

type addOrDeleteBeforeNormalizeType = {
    array: string[],
    uuidToAddBefore: string,
    value: string,
    addingAfter: boolean,
    dontDelete?: boolean,
}

export const addOrDeleteBeforeNormalize = ({ array = [], uuidToAddBefore, value, addingAfter, dontDelete,
}: addOrDeleteBeforeNormalizeType) => {
    if (array.find(item => item === value)) {
        if (dontDelete) {
            return array;
        }
        return array.filter(item => item !== value);
    }
    const index = array.indexOf(uuidToAddBefore) + (addingAfter ? 1 : 0);
    return addBefore(array,
        index,
        value);
};

export const reorder = <T>(list: T[], startIndex, endIndex): T[] => {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    if (!removed) {
        return [];
    }
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Removes all occurrences of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} number of times the item was in the array
 */
export const removeAllItems = (array, item) => {
    let nOccurrences = 0;
    while (removeItem(array, item) !== -1) {
        ++nOccurrences;
    }
    return nOccurrences;
};

/**
 * Replace item in array on index with value
 *
 * @returns new array
 */
export const replaceItemAtIndex = <T>(array: T[], rowIndex: number, rowValue: T): T[] => {
    array = [
        ...array.slice(0, rowIndex),
        rowValue,
        ...array.slice(rowIndex + 1),
    ];
    return array;
};

/**
 * Returns an array where given item is repeated given number of times.
 */
export const repeat = <T>(length: number, item: T): T[] =>
    Array(length).fill(item);

/**
 * Removes the item at given index from given array. Does not do anything if
 * the index is negative.
 *
 * @param {Array<*>} array
 * @param {number} index
 * @returns {number} the index
 */
export const removeAtIndex = (array, index) => {
    if (index < 0) {
        return index;
    }
    array.splice(index, 1);
    return index;
};

/**
 * Replaces the first occurrence of given item from given array. Does not do
 * anything if the item is not in the array.
 *
 * @param {Array<*>} array
 * @param {*} oldItem
 * @param {*} newItem
 * @returns {number} index the item placed at, -1 if the item was not present
 * in the array
 */
export const replaceItem = (array, oldItem, newItem) => {
    const index = array.indexOf(oldItem);
    if (index === -1) {
        return index;
    }
    array[index] = newItem;
    return index;
};

/**
 * Moves the first occurrence of given item in given array one position
 * to the front. Does not do anything if the item is not in the array or if
 * the item is at the beginning of the array.
 *
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
export const moveItemFrontward = (array, item) => {
    const index = array.indexOf(item);
    if (index <= 0) {
        return index;
    }
    array[index] = array[index - 1];
    array[index - 1] = item;
    return index;
};

/**
 * Moves the first occurrence of given item in given array one position to
 * the back. Does not do anything if the item is not in the array or if the
 * item is at the end of the array.
 *
 * @param {Array<*>} array
 * @param {*} item
 * @returns {number} index the item was at, -1 if the item was not present in
 * the array
 */
export const moveItemBackward = (array, item) => {
    const index = array.indexOf(item);
    if (index === -1 || index === array.length - 1) {
        return index;
    }
    array[index] = array[index + 1];
    array[index + 1] = item;
    return index;
};

/**
 * Swaps items at given indices.
 *
 * @param {Array<*>} array
 * @param {number} leftIndex
 * @param {number} rightIndex
 */
export const swapItems = (array, leftIndex, rightIndex) => {
    const temp = array[leftIndex];
    array[leftIndex] = array[rightIndex];
    array[rightIndex] = temp;
};

/**
 * Returns a function that pushes an item to the array.
 * This function exists because the result of array.push.bind(array) cannot
 * be passed to functions such as Array.prototype.push
 *
 * @param {Array<*>} array
 * @returns {Function}
 */
export const getPush = (array) => {
    return function(item) {
        array.push(item);
    };
};

/**
 * Changes given array accordingly to changes from two given arrays.
 *
 * @param {Array<*>} oldArray - the old state array
 * @param {Array<*>} newArray - the new state array
 * @param {Array<*>} changeArray - array to change
 * @param {Function} fnCreate - function that creates a new item
 */
export const changeAccordingToAnotherArray = (oldArray, newArray, changeArray, fnCreate) => {
    oldArray = oldArray.slice(0);
    newArray = newArray.slice(0);
    let changed = true;
    let maxIterations = Math.max(oldArray.length, newArray.length);
    while (changed && maxIterations > 0) {
        --maxIterations;
        changed = false;
        for (let iOld = 0, lOld = oldArray.length; iOld < lOld; ++iOld) {
            const iNew = newArray.indexOf(oldArray[iOld]);
            if (iNew === -1) {
                // removed
                removeAtIndex(oldArray, iOld);
                removeAtIndex(changeArray, iOld);
                changed = true;
                break;
            } else if (iOld !== iNew) {
                // moved
                swapItems(oldArray, iOld, iNew);
                swapItems(changeArray, iOld, iNew);
                changed = true;
                break;
            }
        }
        for (let jNew = 0, lNew = newArray.length; jNew < lNew; ++jNew) {
            const jOld = oldArray.indexOf(newArray[jNew]);
            if (jOld === -1) {
                // added
                oldArray.splice(jNew, 0, newArray[jNew]);
                changeArray.splice(jNew, 0, fnCreate(newArray[jNew], jNew));
                changed = true;
                break;
            }
        }
    }
};


export const range = (size, startAt = 0) => {
    // eslint-disable-next-line id-length
    return [ ...Array(size).keys() ].map(i => i + startAt);
};


export const mergeSort = comparator => {
    const sorter = <T>(array: T[]) => {
        if (array.length <= 1) {
            return array;
        }
        const midPoint = Math.floor(array.length / 2);
        const sortedL = sorter(array.slice(0, midPoint));
        const sortedR = sorter(array.slice(midPoint));
        const result = [] as T[];
        let i = 0;
        let j = 0;
        while (i + j !== sortedL.length + sortedR.length) {
            if (!sortedR[j]) {
                result.push(sortedL[i]!);
                ++i;
                continue;
            }
            if (!sortedL[i] || comparator(sortedR[j], sortedL[i]) < 0) {
                result.push(sortedR[j]!);
                ++j;
                continue;
            }
            result.push(sortedL[i]!);
            ++i;
        }
        return result;
    };

    return sorter;
};

export const stableSort = <T>(arr: T[], compare: (arg1: T, arg2: T) => number|boolean) => arr
    .map((item, index) => ({ item, index }))
    .sort((fst, snd) => Number(compare(fst.item, snd.item)) || fst.index - snd.index)
    .map(({ item }) => item);


type groupAdjacentByObjectKeysSignature = (keys: string[]) => {
    <T>(messages: T[], getFirstGroupOnly: true): T[]
    <T>(messages: T[], getFirstGroupOnly?: false): T[][]
}

export const groupAdjacentByObjectKeys: groupAdjacentByObjectKeysSignature =
        keys => <T>(messages: T[], getFirstGroupOnly = false) => {
            const firstMsg = messages[0];
            if (firstMsg === undefined) {
                return [];
            }
            let currentGroup: T[] = [ firstMsg ];
            const groups = [ currentGroup ];
            for (const message of messages.slice(1)) {
                const everySpecifiedKeyIsSameForThisMessageAsForCurrentGroup = keys.every(
                    key => {
                        const lastMessage = currentGroup.at(-1);
                        return lastMessage && message[key] === lastMessage[key];
                    }
                );
                if (everySpecifiedKeyIsSameForThisMessageAsForCurrentGroup) {
                    currentGroup.push(message);
                } else {
                    if (getFirstGroupOnly) {
                        return currentGroup;
                    }
                    currentGroup = [ message ];
                    groups.push(currentGroup);
                }
            }
            return getFirstGroupOnly ? currentGroup : groups;
        };

type GroupKey = PropertyKey;
type grouperResultItem<G> = {
    group: GroupKey,
    grouperInfo: G
}
type grouperResult = GroupKey | GroupKey[];

/**
 * Used to group array into object of arrays keyed by key provided by grouper.
 * Note that grouper may also return array of keys to add item to multiple groups.
 */
export const groupItems = <Type, G extends grouperResult>(
    arrayToGroup: Type[],
    grouper : (item: Type) => G | grouperResultItem<G>,
): Record<GroupKey, Type[]> => {
    return groupAndModify(arrayToGroup, grouper);
};

/**
 * Used to group array into object of arrays keyed by key provided by grouper.
 * And also modify the items at the same time.
 * Note that grouper may also return array of keys to add item to multiple groups.
 * If you do not need the modification behavior, use {@link groupItems} instead for cleaner code.
 */
export const groupAndModify = <Type, G extends grouperResult, ModifierResult = Type>(
    arrayToGroup: Type[],
    grouper: (item: Type) => G | grouperResultItem<G>,
    modifier: (item: Type, grouperInfo: G) => ModifierResult = item => item as unknown as ModifierResult
): { [resultingGroup:GroupKey]: ModifierResult[] } => {
    const grouped = {} as { [resultingGroup:GroupKey]: ModifierResult[] };

    const addToGroup = (group, item, grouperInfo: G) => {
        const theGroup = grouped[group] ??= [];
        theGroup.push(modifier(item, grouperInfo));
    };

    const processGrouperResultItem = (grouperResultItem, item) => {
        if (grouperResultItem === undefined) {
            return;
        }
        if (typeof grouperResultItem === 'object' && grouperResultItem !== null) {
            const { group, grouperInfo } = grouperResultItem;
            return addToGroup(group, item, grouperInfo);
        }
        addToGroup(grouperResultItem, item, '' as G);
    };

    arrayToGroup.forEach(item => {
        const fnRes = grouper(item) as ReturnType<typeof grouper> | ReturnType<typeof grouper>[];
        if (Array.isArray(fnRes)) {
            return fnRes.forEach(res => {
                processGrouperResultItem(res, item);
            });
        }
        processGrouperResultItem(fnRes, item);
    });
    return grouped;
};

export const forEveryPair = <Type>(arr: Type[], doFn: (arg1: Type, arg2: Type) => void) => {
    const comparedArr = [ ...arr ];
    while (comparedArr.length) {
        const fst = comparedArr.shift();
        if (!fst) {
            continue; // satisfy TS without breaking any linting...
        }
        comparedArr.forEach((snd) => {
            doFn(fst, snd);
        });
    }
};

/** @deprecated since Typescript 5.5 "Inferred type predicates". Instead, use .filter(value => value !== undefined) */
export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined;
};

type Falsy = false | 0 | '' | null | undefined;
/** @deprecated since Typescript 5.5 "Inferred type predicates". Instead, use .filter(value => !!value) */
export const notFalsey = <TValue>(value: TValue | Falsy): value is TValue => {
    return !!value;
};

export const stringify = <TValue extends {toString: () => any}>(value: TValue): ReturnType<TValue['toString']> => {
    return value.toString();
};

export const objectArrayToObjectByProp = <
    T extends string,
    T2 extends Record<T, string | number>,
    >(array: T2[], prop: T): Record<T2[T], T2> => {
    return array.reduce((acc, item) => {
        return {
            ...acc,
            [item[prop]]: item,
        };
    }, {} as Record<T2[T], T2>);
};

export const createForestFromArrayWithItemsReferencingParent = <
    SELF_ID_KEY extends string,
    PARENT_ID_KEY extends string,
>(selfIdKey: SELF_ID_KEY, parentIdKey: PARENT_ID_KEY) => {
    return <
        ITEM extends Record<SELF_ID_KEY, string | number> &
            { [P in PARENT_ID_KEY]?: string | number | undefined } &
            Omit<Record<string, any>, SELF_ID_KEY | PARENT_ID_KEY>,
        ITEM_WITH_CHILDREN extends WithChildrenOfSelf<ITEM>
    >(items: ITEM[]): ITEM_WITH_CHILDREN[] => {
        if (!items.length) {
            return [];
        }
        const byId = items.reduce((acc, item) => {
            return {
                ...acc,
                [item[selfIdKey]]: { ...item, children: <ITEM_WITH_CHILDREN[]>[] },
            };
        }, <Record<string, ITEM_WITH_CHILDREN>>{});

        items.forEach(item => {
            if (item[parentIdKey]) {
                const pushTo = byId[item[parentIdKey]];
                const pushWhat = byId[item[selfIdKey]];
                assert(pushTo !== undefined && pushWhat !== undefined);
                pushTo.children.push(pushWhat);
            }
        });

        return Object.values(byId).filter(item => !item[parentIdKey]);
    };
};

export const flattenTree = <
    CHILDREN_KEY extends string,
    ITEM extends Partial<Record<CHILDREN_KEY, ITEM[]>> & Omit<Record<string, any>, CHILDREN_KEY>
    > (childrenKey: CHILDREN_KEY, root: ITEM): ITEM[] => {
    const items: ITEM[] = [];
    const recurse = (item: ITEM) => {
        items.push(item);
        item[childrenKey]?.forEach(item => {
            recurse(item);
        });
    };
    recurse(root);

    return items;
};

export const arrShallowEq = (arr1: any[], arr2: any[]) => {
    return arr1.length === arr2.length && arr1.every((item, idx) => item === arr2[idx]);
};

type BinarySearchBaseResult = {
    lastLesserIdx: number,
    lastGreaterIdx: number,
    idx: number,
}
export const binarySearchBase = <T>(
    arr: T[], element: T, comparator: (a: T, b: T) => number
): BinarySearchBaseResult => {
    let current = 0;
    let left = 0;
    let right = arr.length - 1;
    const res: BinarySearchBaseResult = {
        lastLesserIdx: -1,
        lastGreaterIdx: -1,
        idx: -1,
    };
    while (left <= right) {
        current = Math.floor((left + right) / 2);
        const cmp = comparator(arr[current]!, element);
        if (cmp < 0) {
            res.lastLesserIdx = current;
            left = current + 1;
        } else if (cmp > 0) {
            res.lastGreaterIdx = current;
            right = current - 1;
        } else {
            res.idx = current;
            res.lastLesserIdx = current - 1;
            res.lastGreaterIdx = current + 1 === arr.length ? -1 : current + 1;
            return res;
        }
    }
    return res;
};
export const binarySearch = <T>(arr: T[], element: T, comparator: (a: T, b: T) => number): number => {
    const res = binarySearchBase(arr, element, comparator);
    return res.idx;
};

export const unique = <T>(array: T[]): T[] =>
    [ ...new Set(array) ];

type ArrayFilterFn<ItemType> = (item: ItemType, idx: number, array: ItemType[]) => any;

/**
 * Splits array to two arrays based on the filter function.
 */
export const splitByFilter = <T>(array: T[], filter: ArrayFilterFn<T>): [truthy: T[], falsey: T[]] => {
    const truthy: T[] = [];
    const falsey: T[] = [];
    array.forEach((item, idx) => {
        if (filter(item, idx, array)) {
            truthy.push(item);
        } else {
            falsey.push(item);
        }
    });

    return [ truthy, falsey ];
};

/**
 * For when you need to iterate over large array and only process smaller segments of it at once.
 */
export const asyncForEachBatch = async <T>(
    array: T[],
    batchSize: number,
    executor: (batch: T[]) => Promise<void>
): Promise<void> => {
    for (let i = 0; i < array.length; i += Math.floor(batchSize)) {
        await executor(array.slice(i, i + batchSize));
    }
};

/**
 * Attemt at creating https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set/symmetricDifference
 * for arrays.
 */
export const arraySymmetricDifference = <T>(arr1: T[], arr2: T[]): T[] => {
    const items = new Set([ ...arr1, ...arr2 ]);
    const difference: T[] = [];
    for (const item of items) {
        if (!arr1.includes(item) || !arr2.includes(item)) {
            difference.push(item);
        }
    }
    return difference;
};
