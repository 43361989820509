/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { checkPropTypes, Requireable, Validator } from 'prop-types';


export interface Isable<T> extends Requireable<T | undefined | null> {
    isOptionalButNotNull: Validator<T | undefined>;
    isRequiredButNullable: Validator<T | null>;
}
/**
 * https://github.com/ivangabriele/better-prop-types
 *
 * @param validate PropTypes value
 */

export default function createBetterChainableTypeChecker<T>(validate: Requireable<T>): Isable<T> {
    const makeCheckType =
      (is?: 'NULLABLE' | 'REQUIRED' | 'UNNULLABLE') =>
          (
              props: { [key: string]: any },
              propName: string,
              componentName: string,
              location: string,
              propFullName: string | null,
          ): Error | null => {
              const finalPropFullName = propFullName !== null ? propFullName : propName;
              const propValue = props[propName];

              if (propValue !== undefined && propValue !== null) {
                  return checkPropTypes({ [propName]: validate }, props, componentName, location) as any;
              }

              switch (is) {
              case 'NULLABLE':
                  if (propValue === null) {
                      return null;
                  }

                  return new Error(
                      `The ${location} \`${finalPropFullName}\` is marked as nullable in ` +
                `\`${componentName}\`, but its value is \`undefined\`.`,
                  );

              case 'REQUIRED':
                  if (propValue === null) {
                      return new Error(
                          `The ${location} \`${finalPropFullName}\` is marked as required ` +
                  `in \`${componentName}\`, but its value is \`null\`.`,
                      );
                  }

                  return new Error(
                      `The ${location} \`${finalPropFullName}\` is marked as required in ` +
                `\`${componentName}\`, but its value is \`undefined\`.`,
                  );

              case 'UNNULLABLE':
                  if (propValue === null) {
                      return new Error(
                          `The ${location} \`${finalPropFullName}\` is marked as NOT null ` +
                  `in \`${componentName}\`, but its value is \`null\`.`,
                      );
                  }

                  return null;

              default:
                  return null;
              }
          };

    const chainedCheckType: Isable<T> = Object.assign(makeCheckType(), {
        isOptionalButNotNull: makeCheckType('UNNULLABLE'),
        isRequired: makeCheckType('REQUIRED'),
        isRequiredButNullable: makeCheckType('NULLABLE'),
    });

    return chainedCheckType;
}
