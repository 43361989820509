/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import arrayUtils from './arrayUtils.js';
import filterContainerManipulator from './filterContainerManipulator.js';
import kUtils from './kUtils.js';
import objectKeysToSnakeCase from './objectKeysToSnakeCase.js';


const ReportType = function(opts) {
    for (const key in opts) {
        this[key] = opts[key];
    }
    this.generateCfg = function() {
        return objectKeysToSnakeCase(opts.generateCfg.apply(opts, arguments));
    };

    this.mapFields = kUtils.arrayToMap(this.fields, 'id');
    this.fields.forEach((field) => {
        if (field.columnRefs) {
            field.columnRefs.forEach((refId) => {
                if (!this.mapFields[refId].columnReffedBy) {
                    this.mapFields[refId].columnReffedBy = [];
                }
                this.mapFields[refId].columnReffedBy.push(field.id);
            });
        }
    });
};

const COLUMN_TYPES = {
    category: {
        types: { column: true, columns: true },
        columnTypes: { category: true }
    },
    metric: {
        types: { column: true, columns: true },
        columnTypes: { metric: true, summable: true }
    },
    orderBy: {
        fields: { orderBy: true }
    }
};

/**
 * Returns array of categories and metrics for given report params.
 *
 * @memberof ReportType
 * @param reportParams {object}
 * @returns {string[]}
 */
ReportType.prototype.getAllColumns = function(reportParams) {
    const result = [];
    const addToColumns = arrayUtils.getPush(result);
    this.getColumnsOfType('category', reportParams).forEach(addToColumns);
    this.getColumnsOfType('metric', reportParams).forEach(addToColumns);
    return result;
};

ReportType.prototype.getMetricCols = function(reportParams) {
    return this.getColumnsOfType('metric', reportParams);
};

ReportType.prototype.getCategoryCols = function(reportParams) {
    return this.getColumnsOfType('category', reportParams);
};

/**
 * Returns array of categories or metrics of given report params.
 *
 * @memberof ReportType
 * @param {string} columnType - 'category' or 'metric'
 * @param reportParams
 * @returns {string[]}
 */
ReportType.prototype.getColumnsOfType = function(columnType, reportParams) {
    if (!columnType || !COLUMN_TYPES[columnType] || !reportParams) {
        return [];
    }
    // [].concat.apply([], [ 1, [ 2, 3 ] ]) evaluates to [ 1, 2, 3 ]
    const tempColType = COLUMN_TYPES[columnType];
    return [].concat.apply([], this.fields.filter(function(field) {
        return (
            !tempColType.types ||
            field.type in tempColType.types
        ) && (
            !tempColType.columnTypes ||
            field.columnType in tempColType.columnTypes
        ) && reportParams[field.id];
    }).map(function(field) {
        return reportParams[field.id];
    }));
};

/**
 * Returns all parameters of type filters.
 *
 * @memberof ReportType
 * @param reportParams
 * @returns an object where key is field ID, value is filterContainer
 */
ReportType.prototype.getFilters = function(reportParams) {
    const result = {};
    this.fields.filter(function(field) {
        return field.type === 'filters';
    }).forEach(function(field) {
        result[field.id] = reportParams[field.id];
    });
    return result;
};

/**
 * Returns true when given column is viable for given field.
 *
 * @memberof ReportType
 * @static
 * @param reportParams
 * @param {object} field
 * @param {string} columnName
 * @returns {boolean}
 */
ReportType.prototype.isColumnReferencable = function(reportParams, field, columnName) {
    if (!field.columnRefs) {
        return true;
    }
    return field.columnRefs.reduce(function(temp, fieldId) {
        if (temp) {
            return temp;
        }
        const value = reportParams[fieldId];
        if (value === columnName) {
            return true;
        }
        return Array.isArray(value) &&
            value.reduce(function(temp, item) {
                return temp ||
                    item === columnName ||
                    (item && item.col === columnName);
            }, false);
    }, false);
};

const getColumnValue = function(reportParams, field) {
    return reportParams[field.id];
};

const getColumnsValue = function(reportParams, field, index) {
    return reportParams[field.id][index];
};

const getOrderByValue = function(reportParams, field, index) {
    return reportParams[field.id][index] &&
        reportParams[field.id][index].col;
};

const setColumnValue = function(reportParams, field, newColumnName) {
    reportParams[field.id] = newColumnName;
};

const setColumnsValue = function(reportParams, field, newColumnName, index) {
    reportParams[field.id][index] = newColumnName;
};

const setOrderByValue = function(reportParams, field, newColumnName, index) {
    reportParams[field.id][index].col = newColumnName;
};

const isColumnInTable = function(reporterTemplates, reportParams, field,
    tableName, columnName)
{
    return columnName in reporterTemplates.tables[tableName].mapColumns;
};

const isColumnRefConsistent = function(reportParams, field, columnName) {
    return ReportType.prototype.isColumnReferencable(reportParams, field, columnName);
};

const checkSingleColumn = function(reporterTemplates, reportParams, field,
    tableName, newColumnName, getValue, setValue, index)
{
    const columnName = getValue(reportParams, field, index);
    if (!isColumnInTable(reporterTemplates, reportParams, field, tableName, columnName)) {
        setValue(reportParams, field, null, index);
    } else if (!isColumnRefConsistent(reportParams, field, columnName)) {
        setValue(reportParams, field, newColumnName || null, index);
    }
};

const checkAllColumns = function(reporterTemplates, reportParams, field, tableName, newColumnName, getValue, setValue) {
    for (let index = 0, len = reportParams[field.id].length; index < len; ++index) {
        checkSingleColumn(
            reporterTemplates, reportParams, field, tableName,
            newColumnName, getValue, setValue, index
        );
    }
};

/**
 * Ensures column consistency for reportParams. Two things are ensured
 * currently:
 *   - All columns are from the selected table.
 *   - Fields that reference other fields have selected columns that are
 *     referenced.
 *
 * @memberof ReportType
 * @param reporterTemplates
 * @param reportParams
 * @param {string} tableName
 * @param {string} [newColumnName] - if the column is not consistent, it
 * will be changed to this one
 */
ReportType.prototype.ensureColumnsConsistence = function(
    reporterTemplates, reportParams, tableName, newColumnName
) {
    this.fields.forEach(function(field) {
        if (field.type === 'column') {
            checkSingleColumn(
                reporterTemplates, reportParams, field, tableName,
                newColumnName, getColumnValue, setColumnValue
            );
        } else if (field.type === 'columns') {
            checkAllColumns(
                reporterTemplates, reportParams, field, tableName,
                newColumnName, getColumnsValue, setColumnsValue
            );
        } else if (field.type === 'orderBy') {
            checkAllColumns(
                reporterTemplates, reportParams, field, tableName,
                newColumnName, getOrderByValue, setOrderByValue
            );
        }
    });
};

/**
 * Fixes the reportParams upon loading.
 *
 * @memberof ReportType
 * @param reporterTemplates
 * @param reportParams
 */
ReportType.prototype.fix = function(reporterTemplates, reportParams) {
    const paramsFilters = this.getFilters(reportParams);
    Object.keys(paramsFilters).forEach(function(key) {
        filterContainerManipulator.fix(
            reporterTemplates, paramsFilters[key]
        );
    });
};

export default ReportType;
