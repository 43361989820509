/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { getObjectSubnode } from '~commonLib/objectUtils.ts';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';

import deepCopySetValue from '../../../shared/lib/deepCopySetValue.js';
import { getGetGlcfgSetterActionType } from '../glcfgUtils.js';


const hlcfgSchemaNodeToGlcfgSchema = (hlcfgSchemaRoot, glcfgKey) => {
    const glcfgDef = glcfgDefinition[glcfgKey];
    assert(glcfgDef, `key ${glcfgKey} is missing in paths`);
    let lastAttr;
    let parent;
    const result = glcfgDef.hlcfgPath.split('.').reduce(
        (obj, attr) => {
            parent = obj;
            lastAttr = attr;
            return obj && obj.properties && obj.properties[attr];
        },
        hlcfgSchemaRoot
    );
    return {
        hlcfgPath: glcfgDef.hlcfgPath,
        ...result,
        isRequired: parent && parent.required && parent.required.includes(lastAttr),
    };
};

export const schemaHlcfgToGlcfg = (hlcfgSchema) => {
    const glcfgSchema = {};
    for (const glcfgKey in glcfgDefinition) {
        glcfgSchema[glcfgKey] = hlcfgSchemaNodeToGlcfgSchema(hlcfgSchema, glcfgKey);
    }
    return glcfgSchema;
};

export const computeGlcfgSetters = (prefix) => {
    const glcfgSetters = {};
    for (const glcfgKey in glcfgDefinition) {
        const { setter } = glcfgDefinition[glcfgKey];
        const keySet = getGetGlcfgSetterActionType(prefix)(glcfgKey);
        assert(
            !glcfgSetters[keySet],
            `Duplicate setter at "${
                keySet
            }" - trying to set "${
                glcfgKey
            }" but it already has value of "${
                glcfgSetters[keySet]
            }"`
        );
        glcfgSetters[keySet] = { glcfgKey, setter };
    }
    return glcfgSetters;
};

const computeComprehensibleHlcfg = () => {
    const comprehensibleHlcfg = {};
    if (glcfgDefinition) {
        Object.values(glcfgDefinition).forEach(({ hlcfgPath }) => {
            const leafComprehensibleHlcfg = hlcfgPath.split('.').reduce(
                (nodeComprehensibleHlcfg, key) => {
                    if (!nodeComprehensibleHlcfg[key]) {
                        nodeComprehensibleHlcfg[key] = {};
                    }
                    return nodeComprehensibleHlcfg[key];
                },
                comprehensibleHlcfg
            );
            leafComprehensibleHlcfg.isLeaf = true;
        });
    }
    return comprehensibleHlcfg;
};


const getGlcfgNodeSubtree = (treeHlcfg, key) => {
    assert(glcfgDefinition[key], `key ${key} is missing in paths`);
    return getObjectSubnode(treeHlcfg, glcfgDefinition[key].hlcfgPath.split('.'));
};

const noop = item => item;

const comprehensibleHlcfg = computeComprehensibleHlcfg();

const getIncomprehensibleHlcfg = (incomprehensibleHlcfgNodes, nodeHlcfg, nodeComprehensibleHlcfg, paths = []) => {
    if (!nodeHlcfg || typeof nodeHlcfg !== 'object') {
        return;
    }
    Object.keys(nodeHlcfg).forEach(keyHlcfg => {
        if (!nodeComprehensibleHlcfg[keyHlcfg]) {
            incomprehensibleHlcfgNodes.push({
                path: [ ...paths, keyHlcfg ],
                value: nodeHlcfg[keyHlcfg],
            });
            return;
        }
        if (nodeComprehensibleHlcfg[keyHlcfg].isLeaf) {
            return;
        }
        getIncomprehensibleHlcfg(
            incomprehensibleHlcfgNodes,
            nodeHlcfg[keyHlcfg],
            nodeComprehensibleHlcfg[keyHlcfg],
            [ ...paths, keyHlcfg ]
        );
    });
};

export const transformHlcfgToGlcfg = (treeHlcfg) => {
    const glcfgTree = {};
    for (const glcfgKey in glcfgDefinition) {
        const transformHlcfgNodeToGlcfg = glcfgDefinition[glcfgKey].hlcfgToGlcfg || noop;
        glcfgTree[glcfgKey] = transformHlcfgNodeToGlcfg(getGlcfgNodeSubtree(treeHlcfg, glcfgKey));
    }
    const incomprehensibleHlcfgNodes = [];
    getIncomprehensibleHlcfg(incomprehensibleHlcfgNodes, treeHlcfg, comprehensibleHlcfg);
    return {
        glcfgTree,
        incomprehensibleHlcfgNodes,
    };
};

/**
 * Creates hlcfg tree from glcfg tree nodes.
 *
 * @param {object} glcfgTree
 * @returns {object} hlcfgTree
 */
const copyGlcfgTreeToHlcfgTree = (glcfgTree) => {
    const hlcfgTree = {};
    for (const glcfgKey in glcfgDefinition) {
        // transform glcfg to hlcfg
        const transformGlcfgNodeToHlcfg = glcfgDefinition[glcfgKey].glcfgToHlcfg || noop;
        const value = transformGlcfgNodeToHlcfg(glcfgTree[glcfgKey]);
        const arrKeys = glcfgDefinition[glcfgKey].hlcfgPath.split('.');
        arrKeys.reduce(
            (subtree, keyRaw, iKey, allKeys) => {
                if (value === null || value === undefined) {
                    return;
                }
                const nextKeyIsArrayIndex = allKeys[iKey + 1] ?
                    `${parseInt(allKeys[iKey + 1])}` === allKeys[iKey + 1] : false;

                const keyInt = parseInt(keyRaw);
                const key = `${keyInt}` === keyRaw ? keyInt : keyRaw;

                if (iKey === arrKeys.length - 1) {
                    subtree[key] = value === null ? undefined : value;
                } else if (!subtree[key]) {
                    subtree[key] = nextKeyIsArrayIndex ? [] : {};
                }
                return subtree[key];
            },
            hlcfgTree
        );
    }
    return hlcfgTree;
};

/**
 * Adds hlcfg nodes that are not comprehensible by GUI.
 *
 * @returns {object} hlcfgTree
 */
const copyIncomprehensibleNodesToHlcfgTree = (hlcfgTree, incomprehensibleHlcfgNodes) => {
    let resultHlcfgTree = hlcfgTree;
    incomprehensibleHlcfgNodes.forEach(({ path, value }) => {
        if (!path.length) {
            throw new Error('The entire hlcfg is incomprehensible');
        }
        resultHlcfgTree = deepCopySetValue(resultHlcfgTree, path, value);
    });
    return resultHlcfgTree;
};

export const transformGlcfgToHlcfg = (glcfgTree, incomprehensibleHlcfgNodes) => {
    const hlcfgTree = copyGlcfgTreeToHlcfgTree(glcfgTree);
    return copyIncomprehensibleNodesToHlcfgTree(hlcfgTree, incomprehensibleHlcfgNodes);
};
