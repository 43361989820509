/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const ACTION_SEQUENCE_MANAGEMENT_PRIV = 'ACTION_SEQUENCE_MANAGEMENT_PRIV';
export const BACKEND_SURICATA_MANAGEMENT_PRIV = 'BACKEND_SURICATA_MANAGEMENT_PRIV';
export const CLEANUP_ACTIONS_PRIV = 'CLEANUP_ACTIONS_PRIV';
export const COMPILATION_PRIV = 'COMPILATION_PRIV';
export const COMPONENT_INFO_PRIV = 'COMPONENT_INFO_PRIV';
export const COMPONENT_MANAGEMENT_PRIV = 'COMPONENT_MANAGEMENT_PRIV';
export const CONFIGURATION_INFO_PRIV = 'CONFIGURATION_INFO_PRIV';
export const CONFIGURATION_MANAGEMENT_PRIV = 'CONFIGURATION_MANAGEMENT_PRIV';
export const DEVICE_MANAGEMENT_PRIV = 'DEVICE_MANAGEMENT_PRIV';
export const DHCP_MANAGEMENT_PRIV = 'DHCP_MANAGEMENT_PRIV';
export const DUMMY_PRIV = 'DUMMY_PRIV';
export const I18N_LOG_PRIV = 'I18N_LOG_PRIV';
export const PING_PRIV = 'PING_PRIV';
export const REPORTER_INFO_PRIV = 'REPORTER_INFO_PRIV';
export const REPORTER_MANAGEMENT_PRIV = 'REPORTER_MANAGEMENT_PRIV';
export const REPORTER_USAGE_PRIV = 'REPORTER_USAGE_PRIV';
export const SELF_ACCOUNT_MANAGEMENT_PRIV = 'SELF_ACCOUNT_MANAGEMENT_PRIV';
export const SESSION_INFO_PRIV = 'SESSION_INFO_PRIV';
export const SESSION_MANAGEMENT_PRIV = 'SESSION_MANAGEMENT_PRIV';
export const SSO_MANAGEMENT_PRIV = 'SSO_MANAGEMENT_PRIV';
export const SURICATA_INFO_PRIV = 'SURICATA_INFO_PRIV';
export const SURICATA_MANAGEMENT_PRIV = 'SURICATA_MANAGEMENT_PRIV';
export const SYSTEM_UPGRADE_INFO_PRIV = 'SYSTEM_UPGRADE_INFO_PRIV';
export const SYSTEM_UPGRADE_MANAGEMENT_PRIV = 'SYSTEM_UPGRADE_MANAGEMENT_PRIV';
export const TIME_INFO_PRIV = 'TIME_INFO_PRIV';
export const TIME_MANAGEMENT_PRIV = 'TIME_MANAGEMENT_PRIV';
export const USER_INFO_PRIV = 'USER_INFO_PRIV';
export const USER_MANAGEMENT_PRIV = 'USER_MANAGEMENT_PRIV';
export const SOCKET_PRIV = 'SOCKET_PRIV';
export const TEST_THAT_NO_ONE_HAS_PRIV = 'TEST_THAT_NO_ONE_HAS_PRIV';
