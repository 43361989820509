/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader/root.js';
import { withTranslation } from 'react-i18next';

import { Icon } from '~frontendComponents/Generic/index.js';
import { getIsHelp, setHelp } from '~frontendDucks/layout/ducks/navBarLeft.js';
import { setChangeDiffOpen, getHlcfgDiffOpen } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { setActiveCard } from '~frontendRoot/ducks/activeCards/index.js';


@withTranslation()
@hot
@connect(
    (state) => ({
        isHelp: getIsHelp(state),
        isDifferOpen: getHlcfgDiffOpen(state),

    }),
    {
        doSetHelp: setHelp,
        doSetChangeDiffOpen: setChangeDiffOpen,
        setActiveCard: setActiveCard

    },
)
class Help extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            doSetHelp: PropTypes.func,
            isHelp: PropTypes.bool,
            doSetChangeDiffOpen: PropTypes.func,
            isDifferOpen: PropTypes.bool,
            setActiveCard: PropTypes.func,
        };
    }

    setHelp = () => {
        const { doSetHelp } = this.props;
        doSetHelp();
        setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    };

    setDiffer = () => {
        const { doSetChangeDiffOpen, isDifferOpen, setActiveCard } = this.props;
        doSetChangeDiffOpen(!isDifferOpen, true);
        setActiveCard({ type: 'changes', value: 'changes' });
        setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    };

    render() {
        const { t, isHelp, isDifferOpen } = this.props;
        return (
            <div className="bookmarkRibbon--position">
                <div  className={'bookmarkRibbon'}>
                    <div
                        className={isDifferOpen  ?
                            'bookmarkRibbon--toggle bookmarkRibbon--active' : 'bookmarkRibbon--toggle'}
                        data-cy="differToggle"
                        onClick={this.setDiffer}
                    >

                        <Icon
                            className="bookmarkRibbon--help"
                            name="edit"
                            size="sm"
                        />
                        <span className="bookmarkRibbon--text">
                            {t('widgets:changes.title')}
                            <Icon
                                className="bookmarkRibbon--icon"
                                name={isDifferOpen ? 'chevron-right' : 'chevron-left'}
                                size="sm"
                            />
                        </span>

                    </div>
                    <div
                        className={isHelp ? 'bookmarkRibbon--toggle mt-1 bookmarkRibbon--active' :
                            ' bookmarkRibbon--toggle mt-1'}
                        data-cy="helpToggle"
                        onClick={this.setHelp}

                    >

                        <Icon
                            className="bookmarkRibbon--help"
                            name="help"
                            size="sm"
                        />


                        <span className="bookmarkRibbon--text">
                            {t('navbar:leftNav.help.title')}
                            <Icon
                                className="bookmarkRibbon--icon"
                                name={isHelp ? 'chevron-right' : 'chevron-left'}
                                size="sm"
                            />
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

export default Help;
