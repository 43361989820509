/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export class CustomCompareMap<Key, Val> extends Map<Key, Val> {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected customCompare(val1: Key, val2: Key): boolean {
        throw new Error('Not Implemented');
    }

    private findExistingKey(key: Key): Key | undefined {
        return [ ...super.keys() ].find(item => this.customCompare(key, item));
    }

    get(key: Key): Val | undefined {
        const existingKey = this.findExistingKey(key);
        if (existingKey) {
            return super.get(existingKey);
        }
    }

    set(key: Key, value: Val): this {
        const existingKey = this.findExistingKey(key);
        if (existingKey) {
            super.set(existingKey, value);
        } else {
            super.set(key, value);
        }
        return this;
    }

    has(key: Key) {
        return !!this.findExistingKey(key);
    }

    delete(key: Key): boolean {
        const existingKey = this.findExistingKey(key);
        if (existingKey) {
            return super.delete(existingKey);
        }
        return false;
    }
}
