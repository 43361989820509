/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ADAPTIVE_FIREWALL_EVENTS_REPORT,
    ADAPTIVE_FIREWALL_EVENTS_REPORT_OUT, ADAPTIVE_FIREWALL_TOP_ADDRESS,
    ADAPTIVE_FIREWALL_TOP_PORT, ADAPTIVE_FIREWALL_TOP_LOCAL_ADDRESSES, ADAPTIVE_FIREWALL_TOP_LOCAL_PORT,
    ADAPTIVE_FIREWALL_REPORT_PER_HOUR,
} from '~frontendConstants/index.js';

import { addTimeFilterCustom, getTwoWeeksTimeStaticFilter,
    addTimeFilterNotAllowed } from '../reportsFilters/index.js';
import { LEGEND } from '../reportsUtils.js';


export const adaptiveFirewallReports = [
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'type': 'line',
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'plotPoints': false,
                    }
                }
            },
        ],
        'report': {
            'id': ADAPTIVE_FIREWALL_EVENTS_REPORT_OUT,
            'type': 'universal',
            'name': {
                'cs': 'Spojení na nebezpečné adresy',
                'en': 'Connections to dangerous addresses',
            },
            'params': {
                'database': 'reporter',
                'table': 'net',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'net.alerted_af_events',
                    'net.blocked_af_events',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilterCustom({ columnName: 'nft_adaptivity.good_evil', value: 'DST' })
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'type': 'line',
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'plotPoints': false,
                        'className': 'mt-2'
                    }
                }
            },
        ],
        'report': {
            'id': ADAPTIVE_FIREWALL_EVENTS_REPORT,
            'type': 'universal',
            'name': {
                'cs': 'Spojení z nebezpečných adres',
                'en': 'Connections from dangerous addresses',
            },
            'params': {
                'database': 'reporter',
                'table': 'net',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'net.alerted_af_events',
                    'net.blocked_af_events',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilterCustom({ columnName: 'nft_adaptivity.good_evil', value: 'SRC' }),
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'
                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastější nebezpečné adresy',
                'en': 'The most common dangerous addresses',
            },
            'id': ADAPTIVE_FIREWALL_TOP_ADDRESS,
            'type': 'universal',
            'params': {
                'paginated': false,
                'database': 'reporter',
                'table': 'baftables',
                'categories': [
                    'nft_adaptivity.evil_ip',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilterNotAllowed({ columnName: 'net.network_action' }),
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'
                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastěji napadané porty',
                'en': 'The most frequently attacked ports',
            },
            'id': ADAPTIVE_FIREWALL_TOP_PORT,
            'type': 'universal',
            'params': {
                'database': 'reporter',
                'table': 'baftables',
                'categories': [
                    'net.dest_port',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilterCustom({ columnName: 'nft_adaptivity.good_evil', value: 'SRC' }),
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'

                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastější adresy spojující se na nebezpečné adresy',
                'en': 'The most common addresses connecting to dangerous addresses',
            },
            'id': ADAPTIVE_FIREWALL_TOP_LOCAL_ADDRESSES,
            'type': 'universal',
            'params': {
                'database': 'reporter',
                'table': 'baftables',
                'categories': [
                    'net.src_ip',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilterCustom({ columnName: 'nft_adaptivity.good_evil', value: 'DST' }),
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'

                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastější porty ve spojení na nebezpečné adresy',
                'en': 'The most common ports in connections to dangerous addresses',
            },
            'id': ADAPTIVE_FIREWALL_TOP_LOCAL_PORT,
            'type': 'universal',
            'params': {
                'database': 'reporter',
                'table': 'baftables',
                'categories': [
                    'net.dest_port',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilterCustom({ columnName: 'nft_adaptivity.good_evil', value: 'DST' }),
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'heatmap',
                    'overridingParameters': {
                        'legend': {
                            'enabled': false,
                        },
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'colors': [],
                        'fillOpacity': 0.5,
                        'title': 'sum',
                        'sortBy': 'action',
                        'col': '12',
                        'className': 'mt-2',
                        'traffic': true
                    }
                }
            },
        ],
        'report': {
            'id': ADAPTIVE_FIREWALL_REPORT_PER_HOUR,
            'type': 'universal',
            'name': {
                'cs': 'Provoz zachycený adaptivním firewallem',
                'en': 'Traffic captured by an adaptive firewall',
            },
            'params': {
                'database': 'reporter',
                'table': 'baftables',
                'categories': [
                    'event.date',
                    'event.hour_of_day',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.date',
                        'dir': 'asc'
                    },
                ],
                'filters': getTwoWeeksTimeStaticFilter()
            }
        },
    },
];
