/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import type { GetResponseType } from '~commonLib/apiUtils.ts';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { backendGet } from '~frontendLib/backendApiCalls.ts';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { FirstParam } from '~commonLib/types.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';

import { createRequest } from '../ducksUtils.ts';


export type StatusType = GetResponseType<BackendApiDefinitionType['/ipsec/status']>;
interface InitialStateType {
    status?: StatusType,
    error?: any,
    isLoading: boolean
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
};


const ipsec = createSlice({
    name: 'ak/ipsec',
    initialState,
    reducers: {
        ipsecStatusRequest: createRequest<typeof initialState, GetIpsecStatusType>(
            () => {
                return { isLoading: true };
            }
        ),
        ipsecStatusSuccess: (state, { payload }: PayloadAction<StatusType>) => {
            state.status = payload;
            state.isLoading = false;
            delete state.error;
        },
        ipsecStatusError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },

    },
});

export const {
    ipsecStatusRequest,
    ipsecStatusSuccess,
    ipsecStatusError
} = ipsec.actions;

export default ipsec.reducer;


const getRootState = (state): InitialStateType => state.ipsec;

export const getStatus = (state) => getRootState(state).status;
export const getIsLoading = (state) => getRootState(state).isLoading;

export const getStatusUuid = createSelector(
    (state, uuid: string) => getStatus(state)?.[uuid],
    (status) => status || EMPTY_IMMUTABLE_OBJ
);


const getIpsecStatus = backendGet('/ipsec/status');
type GetIpsecStatusType = FirstParam<typeof getIpsecStatus>;

export const workerGetIpsecStatus = function* () {
    try {
        const { data } = yield* callSaga(getIpsecStatus);
        yield put(ipsecStatusSuccess(data));

    } catch (error) {
        yield put(ipsecStatusError(getApiError(error)));
    }
};

export const sagas = [
    takeEvery(ipsecStatusRequest.type, workerGetIpsecStatus)
];
