/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const chartTypes = {};

/**
 * A single report as used somewhere (e.g. on a dashboard). It is send to
 * the server as-is, thus it should at all times be a plain old JS object.
 *
 * @typedef {object} ChartTypeObject
 * @property {string} key - chartType
 * @property {number} minMetrics - required minimum number of numeric
 * columns
 * @property {number} minCategories - required minimum number of categorical
 * columns
 * @property {Object<string, boolean>} reportTypes - set of reportType names
 * that are supported by this chart
 * @property {boolean} [isLogarithmic=false] - when true, this chart can be
 * logarithmic
 * @property {number} [maxRows] - when given, the chart is shown only with
 * the maxRows number of points and a button is displayed that allows all
 * points to be shown
 */

chartTypes.CHART_TYPE_BUTTONS = {
    'table': '<span class="glyphicon glyphicon-th-list"></span>',
    'drilldown': '<span class="glyphicon glyphicon-th-list"></span>',
    'polar_line': '<span class="ion-asterisk"></span>',
    'polar_area': '<b>PA</b>',
    'polar_column': '<b>PC</b>',
    'line': '<span class="ion-drag"></span>',
    'area': '<span class="ion-android-image"></span>',
    'scatter': '<span class="ion-grid"></span>',
    'pie': '<span class="ion-pie-graph"></span>',
    'dpie': '<span class="ion-pie-graph"></span>',
    'bar': '<span class="glyphicon glyphicon-align-left"></span>',
    'stacked_bar': '<span class="glyphicon glyphicon-align-left"></span>',
    'column': '<span class="ion-stats-bars"></span>',
    'stacked_column': '<span class="ion-stats-bars"></span>',
    'heatmap': '<b>H</b>',
    'heatmap_large': '<b>HL</b>',
    'bubble': '<b>B</b>',
    'drilldown_anomalies': '<b>A</b>',
    'stacked_area': '<b>Stacked area</b>',
    'default': '<b>?</b>'
};

chartTypes.POLAR_CHART_TYPES = [
    'polar_line'
    //'polar_column',
    //'polar_area'
];

// 'universal', 'hitparada', 'heatmap', 'drilldown', 'userHttpActivity'

chartTypes.CHART_TYPE_ARRAY = [
    {
        key: 'table',
        minMetrics: 0,
        minCategories: 0,
        reportTypes: { 'universal': true, 'hitparada': true, 'userHttpActivity': true }
    },
    { key: 'drilldown', minMetrics: 1, minCategories: 2, reportTypes: { 'drilldown': true } },
    { key: 'drilldown_anomalies', minMetrics: 1, minCategories: 2, reportTypes: { 'drilldown': true } },
    {
        key: 'line',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'scatter',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'area',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'polar_line',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true,
        maxRows: 300
    },
    {
        key: 'bar',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'stacked_bar',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'column',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'stacked_column',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    { key: 'pie', minMetrics: 1, minCategories: 1, reportTypes: { 'universal': true, 'hitparada': true } },
    { key: 'dpie', minMetrics: 1, minCategories: 2, reportTypes: { 'drilldown': true }, maxRows: 100 },
    { key: 'heatmap', minMetrics: 1, minCategories: 2, reportTypes: { 'heatmap': true } },
    {
        key: 'stacked_area',
        minMetrics: 1,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true
    },
    {
        key: 'bubble',
        minMetrics: 3,
        minCategories: 1,
        reportTypes: { 'universal': true, 'hitparada': true },
        isLogarithmic: true,
        maxRows: 300
    }
];

chartTypes.HIGHCHARTS_TYPES = {
    area: true,
    bar: true,
    bubble: true,
    column: true,
    dpie: true,
    line: true,
    heatmap: true,
    pie: true,
    polar_line: true,
    scatter: true,
    stacked_area: true,
    stacked_bar: true,
    stacked_column: true,
};

chartTypes.CHART_TYPES = {};
chartTypes.CHART_TYPE_KEYS = [];
chartTypes.CHART_TYPE_ARRAY.forEach(function(chartType) {
    chartTypes.CHART_TYPE_KEYS.push(chartType.key);
    chartTypes.CHART_TYPES[chartType.key] = chartType;
});

chartTypes.STACKABLE_TYPES = {
    area: true,
    bar: true,
    column: true,
};

chartTypes.PERCENTAGE_TYPES = {
    area: true,
    bar: true,
    column: true,
};

chartTypes.PARAAXIS_TYPES = {
    area: true,
    line: true,
    polar_line: true
};

chartTypes.MULTIAXIS_TYPES = {
    line: true,
    area: true,
    scatter: true,
    bar: true,
    stacked_bar: true,
    column: true,
    stacked_area: true,
    stacked_column: true,
    polar_line: true,
    polar_column: true,
    polar_area: true,
};

chartTypes.LOGARITHMIC_TYPES = {
    bubble: true,
    line: true,
    area: true,
    scatter: true,
    bar: true,
    stacked_area: true,
    stacked_bar: true,
    column: true,
    stacked_column: true,
    polar_line: true,
    polar_column: true,
    polar_area: true,
};

chartTypes.POLAR_CHART_TYPES.forEach(function(chartType) {
    chartTypes.LOGARITHMIC_TYPES[chartType] = true;
});

chartTypes.BANNED_CHART_TYPES = (function() {
    const result = {
        drilldown: {
            'pie': 'dpie'
        }
    };
    chartTypes.CHART_TYPE_KEYS.forEach(function(chartType) {
        if (chartType !== 'dpie' &&
            chartType !== 'pie' &&
            chartType !== 'drilldown' &&
            chartType !== 'drilldown_anomalies')
        {
            result.drilldown[chartType] = 'drilldown';
        }
    });
    [
        'universal', 'hitparada', 'heatmap', 'userHttpActivity'
    ].forEach(function(reportType) {
        result[reportType] = {
            'drilldown': 'table',
            'drilldown_anomalies': 'table',
            'dpie': 'pie'
        };
    });
    return result;
})();

chartTypes.ensureCompatibleChartType = function(
    reportUsageChartObj, selectedChartType, availableChartTypes
) {
    if (availableChartTypes.length &&
        !availableChartTypes.filter(function(chartType) {
            return chartType.key === selectedChartType;
        }).length)
    {
        reportUsageChartObj.config.type = availableChartTypes[0].key;
    }
};

chartTypes.getAvailableChartTypes = function(reportReportType, nCats, nMets) {
    const availableChartTypes = chartTypes.CHART_TYPE_ARRAY.filter(
        function(chartType) {
            return reportReportType in chartType.reportTypes &&
                nCats >= chartType.minCategories &&
                nMets >= chartType.minMetrics;
        }
    );
    return availableChartTypes;
};

/**
 * Returns the chartType chosen for this report.
 *
 * @memberof chartTypes
 * @param {string} reportDefinitionChartType - chartType defined in
 * {@link ReportDefinition}
 * @param {string} reportUsageChartType - chartType defined in
 * {@link ReportUsage}
 * @returns {string}
 */
chartTypes.getChartType = function(reportDefinitionChartType, reportUsageChartType) {
    return reportDefinitionChartType || reportUsageChartType;
};

export default chartTypes;
