/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getApiError = error => {
    const response = error?.response || {};
    const apiError = response.data || error;
    if (!apiError.code && response.status) {
        apiError.code = response.status;
        apiError.type = 'HttpError';
    }
    return apiError;
};

export const isApiErrorClientError = apiError =>
    apiError && apiError.code >= 400 && apiError.code < 500;

export const isApiErrorServerError = apiError =>
    apiError && apiError.code >= 500 && apiError.code < 600;

export const EMPTY_API_ERROR = {};
