/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '~frontendLib/scenes.js';

import Message from '../Message/index.js';
import { Scene } from '../Scene/index.js';


const NotFound = () => (
    <Scene>
        <Message message="navigation:not_found.title" />
    </Scene>
);

export const NOT_FOUND_SCENE = createScene({
    component: NotFound,
    path: '',
});

export default NotFound;
