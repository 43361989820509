/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';


/**
 * Creates an instance of i18next and initializes it.
 *
 * @param {Array} plugins - an array of plugins
 * @param {object} options - i18next options
 * @returns {object}
 */
const initializeI18n = (plugins, options, doCreateInstance = true) => {
    let instance = i18next;
    if (doCreateInstance) {
        instance = instance.createInstance();
    }
    for (const plugin of plugins) {
        instance = instance.use(plugin);
    }
    instance = instance.use(intervalPlural);
    instance.init(options);
    return instance;
};

export default initializeI18n;
