/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetportBase } from '~sharedLib/Netport/NetportBase.ts';
import {
    NetportRangeData, NetportRangeString
} from '~sharedLib/types.ts';
import { isNetportSimpleData, NetportSimple } from '~sharedLib/Netport/NetportSimple.ts';


export class NetportRange extends NetportBase {
    from: NetportSimple;
    to: NetportSimple;

    constructor(range: NetportRangeString | NetportRangeData) {
        super();

        if (isNetportRangeString(range)) {
            const [ from, to ] = range.split('-').map(port => parseInt(port));
            this.from = new NetportSimple(from);
            this.to = new NetportSimple(to);
            return;
        }

        if (isNetportRangeData(range)) {
            this.from = new NetportSimple(range.from);
            this.to = new NetportSimple(range.to);
            return;
        }
        throw new Error(`Invalid port passed to NetportRange: ${JSON.stringify(range)}`);
    }

    toString() {
        return `${this.from}-${this.to}`;
    }

    isValid() {
        return this.from.isValid() && this.to.isValid() && this.from.port < this.to.port;
    }
    isRange(): this is NetportRange {
        return true;
    }

    getFromPortNum(): number {
        return this.from.port;
    }
    getToPortNum(): number {
        return this.to.port;
    }

    containsPortNum(portNum: number) {
        return this.from.port <= portNum && this.to.port >= portNum;
    }

    overlapsWith(port: NetportSimple|NetportRange) {
        if (port.isSimple()) {
            return this.containsPortNum(port.getPort());
        } else {
            return this.containsPortNum(port.getFromPortNum()) || this.containsPortNum(port.getToPortNum());
        }
    }
}


const NETPORT_RANGE_REGEX = /^[0-9]+-[0-9]+$/;

export const isNetportRangeData = (data): data is NetportRangeData => {
    return 'from' in data && isNetportSimpleData(data.from) &&
        'to' in data && isNetportSimpleData(data.to);
};
export const isNetportRangeString = (strPort): strPort is NetportRangeString => {
    return typeof strPort === 'string' && NETPORT_RANGE_REGEX.test(strPort);
};
