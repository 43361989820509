/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '~frontendLib/scenes.js';
import { createLazy } from '~frontendLib/createLazy.tsx';
import { QOS_PATH } from '~frontendConstants/index.js';


const Dns = createLazy(() => import('./Dns/index.js'));
const Routing = createLazy(() => import('./Routing/index.js'));
const Network = createLazy(() => import('./Network/index.js'));
const QoS = createLazy(() => import('./QoS/index.js'));


export const NETWORK_INTERFACES_PATH = '/configuration/network/interfaces';
export const NETWORK_DNS_PATH = '/configuration/network/resolver';

const NETWORK_INTERFACES_SCENE = createScene({
    path: NETWORK_INTERFACES_PATH,
    component: Network,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Interfaces.title',
});

const NETWORK_DNS_SCENE = createScene({
    path: '/configuration/network/resolver',
    component: Dns,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Resolver.title',
});

const NETWORK_ROUTING_SCENE = createScene({
    path: '/configuration/network/routing',
    component: Routing,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Routing.title',
});

const NETWORK_QOS_SCENE = createScene({
    path: QOS_PATH,
    component: QoS,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.QoS.title',
});


const scenes = () => {
    return [
        NETWORK_INTERFACES_SCENE,
        NETWORK_DNS_SCENE,
        NETWORK_ROUTING_SCENE,
        NETWORK_QOS_SCENE
    ];
};

export default scenes;
