/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const upToDateData = {
    currSys: {},
};

// TODO: Did someone say server time? Nope.
Object.defineProperty(upToDateData.currSys, 'serverTime', {
    get: () => {
        return new Date();
    }
});

export default upToDateData;
