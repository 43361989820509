/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM } from '~commonLib/constants.ts';
import { getRouterPath } from '~frontendDucks/userAuthentication/userAuthentication.js';
import { useUpgradeConfirmMutation, useUpgradeStateIsOfTypeQuery } from '~frontendQueries/upgrade/hooks.ts';
import { PASSTHROUGH_URLS } from '~sharedConstants/constants.ts';


const PostLoginTasksNoMem = () => {
    useUpgradeConfirmTask();
    useUrlsPassthrough();
    return null;

};
const useUrlsPassthrough = () => {
    const currentUrl = useSelector(getRouterPath);
    if (PASSTHROUGH_URLS.includes(currentUrl)) {
        window.location.reload();
    }
};
const useUpgradeConfirmTask = () => {
    const {
        data: awaitingConfirm, isFetching: isLoadingState
    } = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM);
    const { mutate: upgradeConfirm, isLoading: isConfirming } = useUpgradeConfirmMutation();
    const shouldConfirm = awaitingConfirm && !isConfirming && !isLoadingState;
    useEffect(() => {
        if (shouldConfirm) {
            upgradeConfirm({});
        }
    }, [ shouldConfirm, upgradeConfirm ]);
};

export const PostLoginTasks = React.memo(PostLoginTasksNoMem);
