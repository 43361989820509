/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/** Possible overrides for debugging the color choosing. Not implemented here. */
const overrides = {};

const colorToHex = function(color) {
    color = Math.floor(color * 255).toString(16);
    if (color.length < 2) {
        color = '0' + color;
    }
    return color;
};

const genColor = function(iColors, nColors, sat, overrideValue) {
    let red, green, blue;
    let hue = (360 + (overrides.hue || 240) - 360 / nColors * iColors) % 360;
    const saturation = overrides.saturation || sat;
    const value = overrides.value || overrideValue;
    hue /= 60; // sector 0 to 5
    const sector = Math.floor(hue);
    const fact = hue - sector; // factorial part of h
    const pVal = value * (1 - saturation);
    const qVal = value * (1 - saturation * fact);
    const tVal = value * (1 - saturation * (1 - fact));
    switch (sector) {
    case 0:
        red = value;
        green = tVal;
        blue = pVal;
        break;
    case 1:
        red = qVal;
        green = value;
        blue = pVal;
        break;
    case 2:
        red = pVal;
        green = value;
        blue = tVal;
        break;
    case 3:
        red = pVal;
        green = qVal;
        blue = value;
        break;
    case 4:
        red = tVal;
        green = pVal;
        blue = value;
        break;
    default:
        red = value;
        green = pVal;
        blue = qVal;
    }
    return '#' + colorToHex(red) + colorToHex(green) + colorToHex(blue);
};

/** The number that was decided to be considered decently magical after a common consensus. */
const MAGIC_NUMBER = 3;

/**
 * Generates colors. It uses a heuristic to create a pretty color scheme.
 *
 * @param {number} nColors
 * @returns {string[]} an array of colors, each color is in hexadecimal format, i.e. '#b44d09'
 */
const generateColors = function(nColors) {
    const colors = [];
    const nBigger = Math.ceil(nColors / MAGIC_NUMBER) * MAGIC_NUMBER;
    const quotient = Math.floor(nBigger / MAGIC_NUMBER);
    let sat = 0.6;
    let val = 1.0;
    for (let iOuter = 0; iOuter < quotient; ++iOuter) {
        for (let iInner = 0; iInner < MAGIC_NUMBER; ++iInner) {
            const iColors = iOuter + quotient * iInner;
            if (iColors < nColors) {
                colors.push(genColor(iColors, nColors, 0.3 + sat, 0.8 - val / 3));
            }
        }
        sat *= overrides.magicSat || 0.9;
        val *= overrides.magicVal || 0.3;
    }
    return colors;
};

export default generateColors;
