/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    GetResponseType,
    PostRequestBodyType,
    PostResponseType
} from '~commonLib/apiUtils.ts';
import type { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';


type ApiDef = BackendApiDefinitionType
type PostApi = { [P in keyof ApiDef as ApiDef[P] extends { post: object } ? P : never]: ApiDef[P] };
type GetApi = { [P in keyof ApiDef as ApiDef[P] extends { get: object } ? P : never]: ApiDef[P] };

export const backendPost = <T extends keyof PostApi>(apiCall: T, config?: AxiosRequestConfig) => {
    return async (args: PostRequestBodyType<PostApi[T]>) => {
        return axios.post<T, AxiosResponse<PostResponseType<PostApi[T]>>>('/api' + apiCall, args, config);
    };
};
export const backendDataPost = <T extends keyof PostApi>(apiCall: T, config?: AxiosRequestConfig) => {
    const call = backendPost(apiCall, config);
    return async (args: PostRequestBodyType<PostApi[T]>) => {
        const { data } = await call(args);
        return data;
    };
};

export const backendGet = <T extends keyof GetApi>(apiCall: T, config?: AxiosRequestConfig) => {
    return async () => {
        return axios.get<T, AxiosResponse<GetResponseType<GetApi[T]>>>('/api' + apiCall, config);
    };
};
export const backendDataGet = <T extends keyof GetApi>(apiCall: T, config?: AxiosRequestConfig) => {
    const call = backendGet(apiCall, config);
    return async () => {
        const { data } = await call();
        return data;
    };
};
