/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { compress } from 'compress-json';


const KB = 1024;
const dataLengthToCompress = 90 * KB;

// TODO AK-1909: systematical handling of custom encoding on backend, encoding determined by header,
//  usage of this instance everywhere,
//  axios getters for customized axios instances extending base configuration
export const axiosInstanceWithCompression = axios.create({
    transformRequest: [
        ...axios.defaults.transformRequest,
        (data, headers) => {
            if (typeof data === 'string' && data.length > dataLengthToCompress) {
                // headers['Content-Encoding'] = 'comporess-json';
                const newData = JSON.stringify(compress(JSON.parse(data)));
                return newData;
            } else {
                delete headers['Content-Encoding'];
                return data;
            }
        }
    ]
});
