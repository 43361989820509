/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createQueryHook } from '~frontendQueries/utils.ts';
import { SECOND } from '~commonLib/constants.ts';

import { systemQueries } from './queries.ts';


export const useExpectedSystemComponentsListQuery = createQueryHook({
    ...systemQueries.expectedSystemComponentsList,
    staleTime: Infinity,
});

export const useExpectedSystemComponentQuery = (component: string) =>
    useExpectedSystemComponentsListQuery({ select: it => it[component] });

export const useSystemComponentsListQuery = createQueryHook({
    ...systemQueries.systemComponentsList,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

export const useNetworkInfoQuery = createQueryHook({
    ...systemQueries.networkInfo,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

export const useLicenseInfo = createQueryHook({
    ...systemQueries.licenseInfo,
    staleTime: Infinity,
});

export const useNetworkInterfacesQuery = () => useNetworkInfoQuery({ select: it => it.networkInterfaces });

export const useSystemComponentStatusQuery = (component: string) =>
    useSystemComponentsListQuery({ select: it => it[component]?.status });

export const useSystemComponentActionsQuery = (component: string) =>
    useSystemComponentsListQuery({ select: it => it[component]?.actions });
