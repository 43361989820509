/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useState } from 'react';
import classNames from 'classnames';

import Icon from '../Icon/index.ts';


interface CloseWithLockProps {
    onClick: () => void,
    datacy: string
}

const CloseWithLock = ({ onClick, datacy }: CloseWithLockProps) => {
    const [ open, setOpen ] = useState(false);
    const onClickWithLock = () => {
        if (open) {
            onClick();
        }
        setOpen(!open);
    };
    return (
        <>
            <Icon
                className="icon--clicable dontShowInModal close closeWithLock__close"
                datacy={datacy}
                name="close-circle-outline"
                onClick={onClickWithLock}
                size="sm"
            />
            <Icon
                className={classNames('m-0 closeWithLock__lock', 'dontShowInModal',
                    { 'closeWithLockExit': open })}
                name={open ? 'lock-open-outline' : 'lock-outline'}
                size="sx"
            />
        </>
    );
};

export default CloseWithLock;
