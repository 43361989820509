/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import tFilter from './tFilter.js';


/**
 * Various utility functions shared between client and server. Note that this
 * service should be kept as small as possible -- coherent pieces of
 * functionality are to be moved to a new service.
 *
 * @name kUtils
 */
const kUtils = {};

kUtils.errorToString = function(error) {
    return (error && error.i18n && tFilter(error.i18n)) ||
        (error && error.message) ||
        (error && error.textContent) ||
        error;
};

kUtils.getIncrementedIp = function(ip4addr) {
    return ip4addr.split('.').map(function(part, index) {
        return index === 3 ? Number(part) + 1 : part;
    }).join('.');
};

/**
 * Returns a function that can be used by Array.prototype.sort(). The
 * comparison is done by String.prototype.localeCompare()
 *
 * @memberof kUtils
 * @param {boolean} [caseSensitive=true]
 * @param {Function} fn
 * @returns {Function}
 */
kUtils.getSortByString = function(caseSensitive, fn) {
    if (!fn && typeof caseSensitive === 'function') {
        fn = caseSensitive;
        caseSensitive = true;
    }
    return function(left, right) {
        let fnLeft = (fn ? fn(left) : left) || '';
        let fnRight = (fn ? fn(right) : right) || '';
        if (!caseSensitive) {
            if (fnLeft.toLocaleLowerCase) {
                fnLeft = fnLeft.toLocaleLowerCase();
                fnRight = fnRight.toLocaleLowerCase();
            } else {
                fnLeft = fnLeft.toLowerCase();
                fnRight = fnRight.toLowerCase();
            }
        }
        if (fnLeft.localeCompare) {
            return fnLeft.localeCompare(fnRight);
        }
        if (fnLeft > fnRight) {
            return 1;
        }
        if (fnLeft < fnRight) {
            return -1;
        }
        return 0;
    };
};

/**
 * Transforms an array to an object.
 *
 * @memberof kUtils
 * @param {Array} array - an array, the values should be unique
 * @param {string} [indexBy] - when given, the resulting object is indexed
 * by values of attributes of objects in given array, else it is indexed by
 * the values of given array
 * @returns {object}
 */
kUtils.arrayToMap = function(array, indexBy) {
    const result = {};
    array.forEach(function(value) {
        result[indexBy ? value[indexBy] : value] = value;
    });
    return result;
};

export default kUtils;
