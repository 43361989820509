/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root.js';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getHlcfgSchema } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import DevelStatusBar from '~frontendComponents/DevelStatusBar/index.js';
import RecoveryStatusBar from '~frontendComponents/RecoveryStatusBar/index.js';
import { getRouterPath } from '~frontendDucks/userAuthentication/index.js';
import ModalWindows from '~frontendComponents/ModalWindows/index.js';
import { getIsProductionMode } from '~frontendDucks/constantInformation/index.js';
import { getGlcfgValue, getHlcfgDiffOpen, getIsTreeDirty } from '~frontendDucks/hlcfgEditor/index.js';
import { getPrunedSceneTree, getFirstScene } from '~frontendScenes/index.js';
import { getShowTodos } from '~frontendDucks/dynamicSettings/index.js';
import { getIsHelp } from '~frontendDucks/layout/ducks/navBarLeft.js';
import GlobalNotifications from '~frontendComponents/GlobalNotifications/GlobalNotifications.ts';
import { WrongGuiVersionBar } from '~frontendComponents/WrongGuiVersionBar.tsx';
import { getHostname } from '~frontendDucks/systemInfo/systemInfo.js';

import Content from './components/Content/index.js';
import ErrorBoundary from './components/ErrorBoundary/index.js';
import NavBarLeft from './components/NavBarLeft/index.js';
import NavBarTop, { getTranslationFromReactPath } from './components/NavBarTop/index.js';
import Help from './components/Help/index.js';
import HelpToggle from './components/Help/HelpToggle.js';
import Differ from './components/Differs/Differs.tsx';
import { PostLoginTasks } from './PostLoginTasks.tsx';


@hot
@connect(
    state => ({
        schema: getHlcfgSchema(state),
    }),

)
class ApplicationContent extends Component {
    static get propTypes() {
        return {
            deployment: PropTypes.string,
            isTreeDirty: PropTypes.bool,
            prunedSceneTree: PropTypes.array.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            isHelp: PropTypes.bool,
            isDifferOpen: PropTypes.bool,
            schema: PropTypes.any,
        };
    }

    render() {
        const {
            deployment, isTreeDirty,
            prunedSceneTree, setActiveRoute, isHelp,
            isDifferOpen, schema
        } = this.props;
        return (
            <>
                <header className="header">
                    <NavBarTop
                        isTreeDirty={isTreeDirty}
                    />
                </header>

                <div className="content">
                    <aside className="aside">
                        <NavBarLeft
                            prunedSceneTree={prunedSceneTree}
                            setActiveRoute={setActiveRoute}
                        />
                    </aside>
                    <main className="main">
                        <Content
                            deployment={deployment}
                        />
                        <HelpToggle />
                    </main>


                    {isHelp ?
                        <div className="helpDiv">
                            <Help
                                schema={schema}
                            />
                        </div> :
                        null}
                    {isDifferOpen ?
                        <div className="differs ">
                            <Differ />
                        </div> :
                        null}
                </div>
            </>
        );
    }
}

/**
 * Renders the entire application.
 */
@hot
@withRouter
@connect(
    state => ({
        deployment: getGlcfgValue(state, 'deployment'),
        isProductionMode: getIsProductionMode(state),
        isTreeDirty: getIsTreeDirty(state),
        showTodos: getShowTodos(state),
        isHelp: getIsHelp(state),
        isDifferOpen: getHlcfgDiffOpen(state),
        schema: getHlcfgSchema(state),
        routerPath: getRouterPath(state),
    }),

) class Application extends Component {
    static get propTypes() {
        return {
            // from withRouter:
            history: PropTypes.object,

            // from connect:
            deployment: PropTypes.string,
            isProductionMode: PropTypes.bool,
            isTreeDirty: PropTypes.bool,
            showTodos: PropTypes.bool,
            isHelp: PropTypes.bool,
            isDifferOpen: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        const { deployment, isProductionMode,  showTodos } = this.props;
        this.state = {
            // Computing the prunedSceneTree only when the deployment is changed is a performance optimization.
            prunedSceneTree: getPrunedSceneTree(deployment, isProductionMode, showTodos),
        };
    }

    pushToHistory = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    setActiveRoute = (objRoute) => {
        const firstScene = getFirstScene(objRoute);
        const activeRoute = firstScene.route.key;
        this.pushToHistory(activeRoute);
    };

    componentDidUpdate(prevProps) {
        const { deployment, isProductionMode, showTodos } = this.props;
        if (deployment !== prevProps.deployment ||
            isProductionMode !== prevProps.isProductionMode ||
            showTodos !== prevProps.showTodos
        ) {
            this.setState({
                prunedSceneTree: getPrunedSceneTree(deployment, isProductionMode, showTodos),
            });
        }
    }

    render() {
        const { prunedSceneTree } = this.state;
        const { deployment, isTreeDirty, isHelp, isDifferOpen } = this.props;
        return (
            <ErrorBoundary>
                <DevelStatusBar />
                <DocumentTitleUpdater />
                <WrongGuiVersionBar />
                <RecoveryStatusBar />
                <ApplicationContent
                    deployment={deployment}
                    isDifferOpen={isDifferOpen}
                    isHelp={isHelp}
                    isTreeDirty={isTreeDirty}
                    prunedSceneTree={prunedSceneTree}
                    setActiveRoute={this.setActiveRoute}
                />
                <ModalWindows />
                <GlobalNotifications />
                <PostLoginTasks />
            </ErrorBoundary>
        );
    }
}

const DocumentTitleUpdaterNoMemo = () => {
    const routerPath = useSelector(getRouterPath);
    const hostname = useSelector(getHostname);
    const { t } = useTranslation();
    document.title = `${hostname} - ${getTranslationFromReactPath(routerPath, t)}`;
    return null;
};
const DocumentTitleUpdater = React.memo(DocumentTitleUpdaterNoMemo);

export default Application;
