/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { upgradeQueries, upgradeMutations } from './upgrade/queries.ts';
import { systemQueries } from './system/queries.ts';


export const queries = {
    upgrade: upgradeQueries,
    system: systemQueries,
};

export const mutations = {
    upgrade: upgradeMutations
};
