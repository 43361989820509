/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import type { PostResponseType } from '~commonLib/apiUtils.ts';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';
import { NetaddrDomain } from '~sharedLib/Netaddr/NetaddrDomain.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';
import { createSelectorArrayOfObjectsShallow } from '~frontendRoot/lib/reduxUtils.ts';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { ApiCallerPayload } from '~frontendRoot/constants/types.ts';

import { getCategoriesTranslation } from '../policy/index.ts';


interface InitialStateType {
    data: PostResponseType<BackendApiDefinitionType['/utils/cwdbCheckDomainCategories']>['categories']
    error?: any,
    domainToFind?: NetaddrDomain,
    isLoading: boolean
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
    data: [],
};


type IsInCategoriesRequest = {
    domain: NetaddrDomain,
}

const isInCategories = createSlice({
    name: 'ak/isInCategories',
    initialState,
    reducers: {
        isInCategoriesRequest: (state, action: PayloadAction<IsInCategoriesRequest>) => {
            state.domainToFind = action.payload.domain;
            state.isLoading = Boolean(action.payload.domain);
            state.data = [];
            delete state.error;

        },
        isInCategoriesSuccess: (state, action: ApiCallerPayload<typeof getisInCategories>) => {
            state.data = action.payload.categories.map(item => item.replaceAll('-', '_'));
            state.isLoading = false;
            delete state.error;
        },
        isInCategoriesError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },

    },
});

export const {
    isInCategoriesRequest,
    isInCategoriesSuccess,
    isInCategoriesError
} = isInCategories.actions;

export default isInCategories.reducer;


const getRootState = (state): InitialStateType => state.isInCategories;

export const getDataHook = createSelectorArrayOfObjectsShallow(
    (state) => (getRootState(state).data || [])
        .map(item => getCategoriesTranslation(state, item)?.title).filter(Boolean),
    (data): string[] => data
);
export const getIsLoadingHook = (state) => getRootState(state).isLoading;
export const getDomainToFindHook = (state) => stringifyAddress(getRootState(state).domainToFind, true);
export const getError = (state) => getRootState(state).error;


const postIsInCategories = backendPost('/utils/cwdbCheckDomainCategories');
const getisInCategories = async (domain: NetaddrDomain) => {
    return postIsInCategories({ domain: domain.toString() });
};

export const workerGetIsIpInAf = function* (action: PayloadAction<IsInCategoriesRequest>) {
    try {
        if (action.payload.domain) {
            const { data } = yield* callSaga(getisInCategories, action.payload.domain);
            yield put(isInCategoriesSuccess(data));
        }
    } catch (error) {
        yield put(isInCategoriesError(getApiError(error)));
        createNotification({
            title: getApiError(error)?.title,
            type: 'danger',
            desc: getApiError(error)?.message
        });
    }
};

export const sagas = [
    takeEvery(isInCategoriesRequest.type, workerGetIsIpInAf)
];
