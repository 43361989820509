/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '~frontendLib/scenes.js';
import { createLazy } from '~frontendLib/createLazy.tsx';


const Changes = createLazy(() => import('./Changes/index.js'));


const SYSTEM_CHANGES_SCENE = createScene({
    path: '/monitoring/system/changes',
    component: Changes,
    title: 'scenes:scenes.Monitoring.scenes.System.scenes.Changes.title',
});


const scenes = () => {
    return [
        SYSTEM_CHANGES_SCENE,
    ];
};

export default scenes;
