/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMutation } from '@tanstack/react-query';

/**
 * Use mutation that can only run one at a time. If mutation is pending, subsequent calls are ignored.
 * Similiar to "takeLeading" of redux-saga
 */
export const useSyncMutation: typeof useMutation = (...useMutParams) => {
    //@ts-expect-error typescript is little confused here, does not work well with overloads
    const mutationResults = useMutation(...useMutParams);
    // react-query does some very clever things with having properties as getters to optimize performance
    // and only trigger render on property change if it has been get
    // I override mutate here with debounced function, using "origMutate" on the object to preserve "this"
    // Typescript does not like it, so i silence him with ts-expect-error
    //
    //@ts-expect-error doing little hacking
    mutationResults.origMutate = mutationResults.mutate;
    mutationResults.mutate = (...params) => {
        if (!mutationResults.isLoading) {
            //@ts-expect-error doing little hacking here
            mutationResults.origMutate(...params);
        }
    };

    return mutationResults;
};
