/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// import classNames from 'classnames';
import { MDBDropdown } from 'mdbreact';
import PropTypes from 'prop-types';


class DropdownWrap extends MDBDropdown {

    constructor(props) {
        super(props);
    }

    setState() {
        // MDBDropdown sets state to closed all the time causing renders on every click in GUI.
        // And this is a way to prevent that from happening along with getDerivedStateFromProps,
        // which allows us to control opening of dropdown by using prop
    }
    static getDerivedStateFromProps(props, state) {
        if (typeof props.isOpen === 'undefined') {
            return null;
        }
        if ('isOpen' in state && state.isOpen === props.isOpen) {
            return state;
        }
        return {
            ...state, isOpen: props.isOpen,
        };
    }
}

DropdownWrap.propTypes = {
    className: PropTypes.string,
    // icon: PropTypes.string,
    // message: PropTypes.string,
    children: PropTypes.node,
    // isCollapsed: PropTypes.bool,
    // doTempOpenNavBar: PropTypes.func,
};

export default DropdownWrap;
