/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const ip2bin = (ip): string => {
    return ip.split('.').map(group => parseInt(group).toString(2).padStart(8, '0')).join('');
};

export const bin2ip = bits => {
    const groups: string[] = [];
    for (let i = 0; i < 4; i++) {
        const start = i * 8;
        const group = bits.slice(start, start + 8);
        groups.push(parseInt(group, 2).toString(10));
    }
    return groups.join('.');
};

export const getBitMaskIpv4 = (len) => {
    return '1'.repeat(len).padEnd(32, '0');
};

const clampMask = mask => mask < 0 ? 0 : mask > 32 ? 32 : mask;

export const makeBroadcastIp4ByIpAndMask = (ip: string, mask?: number) => {
    if (!mask && mask !== 0) {
        return ip;
    }
    const ipBin = ip2bin(ip);
    return bin2ip(ipBin.slice(0, clampMask(mask)).padEnd(32, '1'));
};

export const applyMaskToIpv4 = (ip: string, mask?: number) => {
    if (!mask && mask !== 0) {
        return ip;
    }

    return bin2ip(ip2bin(ip).slice(0, clampMask(mask)).padEnd(32, '0'));
};

export const int2ip = (ipInt: number) => {
    return (ipInt >>> 24) + '.' + (ipInt >> 16 & 255) + '.' + (ipInt >> 8 & 255) + '.' + (ipInt & 255);
};

export const ip2int = (ip: string) => {
    return ip.split('.').reduce((ipInt, octet) => (ipInt << 8) + parseInt(octet, 10), 0) >>> 0;
};
