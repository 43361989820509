/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBDropdownToggle, MDBWaves } from 'mdbreact';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getIsRoutePrefixGetter } from '~frontendDucks/userAuthentication/userAuthentication.js';

import { getIsTempOpen, getIsCollapsed, collapseNavBar, unCollapseNavBar, tempOpenNavBar, tempCloseNavBar } from '../../../../../../ducks/layout/ducks/navBarLeft.js';
import Message from '../../../../../../components/Message/index.js';
import { Icon } from '../../../../../../components/Generic/index.js';


const makeMapStateToProps = () => {
    const getIsRoutePrefix = getIsRoutePrefixGetter();
    const mapStateToProps = (state, { objRoute }) => {
        return {
            isCollapsed: getIsCollapsed(state),
            isTempOpen: getIsTempOpen(state),
            isRoutePrefix: getIsRoutePrefix(state, objRoute.route.key),
        };
    };
    return mapStateToProps;
};
@connect(
    makeMapStateToProps,
    {
        doCollapseNavBar: collapseNavBar,
        doUnCollapseNavBar: unCollapseNavBar,
        doTempOpenNavBar: tempOpenNavBar,
        doTempCloseNavBar: tempCloseNavBar,
    },
) class MenuDropdownToggle extends Component {
    static get propTypes() {
        return {
            // from connect:
            doTempCloseNavBar: PropTypes.func,
            doTempOpenNavBar: PropTypes.func,
            isTempOpen: PropTypes.bool,
            isCollapsed: PropTypes.bool,
            isRoutePrefix: PropTypes.bool.isRequired,

            // from props:
            children: PropTypes.node,
            className: PropTypes.string,
            icon: PropTypes.string,
            message: PropTypes.oneOfType([
                PropTypes.object,
                PropTypes.string,
            ]),
            setActiveRoute: PropTypes.func,
            objRoute: PropTypes.object,
            caret: PropTypes.bool,
        };
    }

    static defaultProps = {
        caret: true
    };

    constructor(props) {
        super(props);
        this.state = {
            cursorPos: {}
        };
    }

    handleClick = (event) => {
        // Waves - Get Cursor Position
        const cursorPos = {
            top: event.clientY,
            left: event.clientX,
            time: Date.now() // time indicates particular clicks
        };

        this.setState({ cursorPos: cursorPos });
    };

    getIsOpen() {
        const { isCollapsed, isTempOpen, isRoutePrefix } = this.props;
        let isOpen = false;
        if (isTempOpen || !isCollapsed) {
            if (isRoutePrefix) {
                isOpen = true;
            }
        }
        return isOpen;
    }

    dropdownToggleClick = (event) => {
        event.stopPropagation();
        const {
            doTempOpenNavBar, isCollapsed, doTempCloseNavBar, setActiveRoute, objRoute
        } = this.props;
        if (isCollapsed) {
            doTempOpenNavBar();
        }
        const isOpen = this.getIsOpen();
        if (isOpen) {
            doTempCloseNavBar();
        } else {
            doTempOpenNavBar();
            if (setActiveRoute) {
                setActiveRoute(objRoute);
            }
        }
    };

    render() {
        const { icon, message, children, className, caret, objRoute } = this.props;
        return (
            <MDBDropdownToggle
                caret={caret}
                className={classNames('waves-effect', className)}
                data-cy={objRoute.route.key}
                nav
                onClick={this.dropdownToggleClick}
                onMouseUp={this.handleClick}
                onTouchStart={this.handleClick}
                tag="button"
            >
                <div className="nav-link__inner">
                    {icon ?
                        <Icon
                            name={icon}
                            size="sm"
                        /> :
                        null
                    }
                    {message ?
                        typeof message === 'object' ?
                            message :
                            <span><Message message={message} /></span> :
                        null
                    }
                    {children}
                    <MDBWaves cursorPos={this.state.cursorPos} />
                </div>
            </MDBDropdownToggle>
        );
    }
}

export default MenuDropdownToggle;
