/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetportDataObj, NetportRangeData, NetportRangeString, NetportSimpleData, NetportSimpleString
} from '~sharedLib/types.ts';
import { isNetportRangeData, isNetportRangeString, NetportRange } from '~sharedLib/Netport/NetportRange.ts';
import { isNetportSimpleData, isNetportSimpleString, NetportSimple } from '~sharedLib/Netport/NetportSimple.ts';


export class InvalidPortError extends Error {
    constructor(port) {
        super(`Invalid port: "${port}"`);
        this.name = 'InvalidPortError';
    }
}

export type Netport = NetportRange | NetportSimple;

export type netportReturn<T> =
    T extends NetportRangeData|NetportRangeString ? NetportRange :
    T extends NetportSimpleData|NetportSimpleString|number ? NetportSimple :
    T extends string ? Netport :
    never;

export type NetportParams = NetportDataObj | string | number;

/**
 * Returns an object representing one of:
 * - a single network port
 * - a single network port range
 * - the fact that no network port is specified
 *
 * A network port (aka network service) is a term used in TCP and UDP protocols.
 */
export const netport = <T extends NetportParams>(port: T): netportReturn<T> => {
    type ItIsOkType = netportReturn<T>;

    if (typeof port === 'number') {
        return new NetportSimple(port) as ItIsOkType;
    }

    if (isNetportSimpleString(port)) {
        return new NetportSimple(port) as ItIsOkType;
    }
    if (isNetportRangeString(port)) {
        return new NetportRange(port) as ItIsOkType;
    }
    if (typeof port === 'string') {
        throw new InvalidPortError(port);
    }

    if (typeof port === 'object' && port !== null) {
        if (isNetportRangeData(port)) {
            return new NetportRange(port) as ItIsOkType;
        }
        if (isNetportSimpleData(port)) {
            return new NetportSimple(port) as ItIsOkType;
        }
    }
    throw new InvalidPortError(port);
};

export const isNetportData = port => {
    return isNetportSimpleData(port) || isNetportRangeData(port);
};
export const isNetportString = port => {
    return isNetportSimpleString(port) || isNetportRangeString(port);
};
