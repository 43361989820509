/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext } from 'react';
import className from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { descriptiveHlcfgPathToTranslationSegment } from '~frontendLib/hlcfg/descriptiveHlcfgPathToTranslationSegment.ts';
import translationSegmentResolver,
{ isNamedTranslation,
    isStaticTranslation, TranslationSegmentResolved } from '~frontendLib/hlcfg/translationSegmentResolver.ts';
import { HlcfgPath } from '~sharedLib/types.ts';
import { clicableItems, setActiveCard } from '~frontendDucks/activeCards/index.js';
import { hlcfgTableNameByRowId, HlcfgTableName, HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { VPN_PATH, DHCP_PATH, QOS_PATH, IPSEC_PATH, PROTECTION_PROXY_PROFILES_SCENE_PATH } from '~frontendConstants/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/index.js';

import { HlcfgContext } from './Differs.tsx';


export const rowToInit: Partial<Record<HlcfgTableName, {type: string, route: string}>> = {
    'openvpnRas': { type: 'vpn', route: VPN_PATH },
    'openvpnClient': { type: 'vpn', route: VPN_PATH },
    'dhcpServer': { type: 'dhcpd', route: DHCP_PATH },
    'dhcpRelay': { type: 'dhcpd', route: DHCP_PATH },
    'tcQueue': { type: 'qos', route: QOS_PATH },
    'ipsec': { type: 'ipsec', route: IPSEC_PATH },
    'profile': { type: 'profiles', route: PROTECTION_PROXY_PROFILES_SCENE_PATH },
};

interface DiffersPathProps {
    paths: HlcfgPath | HlcfgPath[],
    cutLast?: boolean
    notClicable?: boolean
}

const DiffersPath = ({ paths, cutLast, notClicable }: DiffersPathProps) => {
    const { hlcfg, revisionHlcfg, schema } = useContext(HlcfgContext);
    const dispatch  = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const isCluster = useSelector(getIsCluster);

    const click = (rowId: HlcfgRowId) => {
        dispatch(setActiveCard({ value: rowId, type: rowToInit[hlcfgTableNameByRowId(rowId)].type }));
        history.push(rowToInit[hlcfgTableNameByRowId(rowId)]?.route);
    };

    return (
        <div className="differs__path">
            {(Array.isArray(paths[0]) ? paths : [ paths ]).map(
                (item): React.ReactNode => {
                    const row: TranslationSegmentResolved[] = translationSegmentResolver(
                        descriptiveHlcfgPathToTranslationSegment(item as HlcfgPath, schema, isCluster),
                        hlcfg, revisionHlcfg, t, cutLast
                    );

                    return (
                        <div
                            className="differs__error__path"
                            key={String(item)}
                        >
                            {row.map((value, rowIndex): React.ReactNode => {
                                const slash = rowIndex ? (
                                    <div className="differs__error__pathItem differs__error__pathItem--slash">
                                        /
                                    </div>
                                ) : null;

                                if (isStaticTranslation(value)) {
                                    return (
                                        <React.Fragment key={value.staticTranslation}>
                                            {slash}
                                            <div className="differs__error__pathItem">
                                                {value.staticTranslation}
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                                if (isNamedTranslation(value)) {
                                    const isActive =
                                        clicableItems.includes(hlcfgTableNameByRowId(value.rowId)) &&
                                        !notClicable;
                                    return (
                                        <React.Fragment key={value.rowId}>
                                            {slash}
                                            <div
                                                className={className(
                                                    'differs__error__pathItem',
                                                    'differs__error__pathItem--name ',
                                                    { 'differs__error__pathItem--name--active ': isActive }
                                                )}
                                                onClick={() => {
                                                    if (clicableItems.includes(
                                                        hlcfgTableNameByRowId(value.rowId)
                                                    ) && !notClicable) {
                                                        click(value.rowId);
                                                    }}}
                                            >
                                                {value.name}
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <React.Fragment key={value.default}>
                                        {slash}
                                        <div className="differs__error__pathItem">
                                            {value.default}
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    );
                }
            )}
        </div>
    );
};

export default DiffersPath;
