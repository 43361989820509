/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const isConnectionLossError = (error) => {
    if (
        [ 'ECONNREFUSED', 'EHOSTUNREACH', 'ECONNRESET' ].includes(error.code) ||
            (error.message === 'Network Error' && error.isAxiosError) // axios is not always providing nice error :(
    ) {
        return true;
    }
    return false;

};
export const expectConnectionLossPromiseWrap = async <T>(promise: Promise<T>): Promise<T|void> => {
    try {
        return await promise;
    } catch (error) {
        if (isConnectionLossError(error)) {
            return;
        }
        throw error;
    }
};
