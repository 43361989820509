/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { TFunction } from 'i18next';

import { IPV4, IPV6 } from '~commonLib/constants.ts';
import { INTERFACE_COLOR, NODE_A_ID } from '~frontendConstants/index.js';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import {
    ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES,
    ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS,
    ADDRESSES_SELECTOR_TYPE_NETWORK,
    createAddressesSelectorByIfaceId,
    createAddressesSelectorByIfaceType,
} from '~sharedLib/addressesSelectorUtils.ts';
import {
    STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES,
    STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS,
    STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN,
} from '~sharedLib/staticHlcfgReferenceUtils.js';
import { InvalidNetaddrError, netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_NETADDR } from '~sharedLib/schemaTypes.ts';
import { PLACEHOLDER_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';

import { addressesSelectorToString, stringifyAddress } from './addressUtils.ts';


const getAddressFromInterfaceOrVpn = ({
    item, withoutMask, isCluster,
    myNode = '', otherNode = '', t, primaryAddr, isIpv6 = false
}: {
    primaryAddr?: boolean, item: any, withoutMask?: boolean, isCluster?: boolean, myNode?: string,
    otherNode?: string, t: TFunction, isIpv6?: boolean
}) => {
    const stringAddr = (val) => {
        try {
            const addr = netaddr(val);
            if (withoutMask && addr.isIp()) {
                return addr.noMask().toString();
            }
            assert(addr.isIp());
            return addr.applyMaskToIp().toString();
        } catch (err) {
            if (err instanceof InvalidNetaddrError) {
                return '';
            }
            throw err;
        }
    };

    /**
     * Accepts interface addressValue or vpnAddressValue and returns stringified value
     */
    const stringifyVal = (val) => {
        if (!val) {
            return val;
        }
        if (val.shared) {
            //function returns undefined if undefined is passed or empty array if empty array is passed
            const shared = stringifyVal(primaryAddr ? val.shared[0] : val.shared);
            const result = shared && shared.length ? [ shared ] : [];
            if (isCluster) {
                //function returns undefined if undefined is passed or empty array if empty array is passed
                const myNodeAddr = stringifyVal(primaryAddr ? val[myNode]?.[0] : val[myNode]);
                if (myNodeAddr && myNodeAddr.length) {
                    result.push(myNodeAddr);
                }
                //function returns undefined if undefined is passed or empty array if empty array is passed
                const otherNodeAddr = stringifyVal(primaryAddr ? val[otherNode]?.[0] : val[otherNode]);
                if (otherNodeAddr && otherNodeAddr.length) {
                    //other node should be in () to be clear is not on this machine
                    result.push(`( ${otherNodeAddr} )`);
                }
            }
            return result;
        }
        if (Array.isArray(val)) {
            return val.map(stringAddr);
        }
        return [ stringAddr(val) ];
    };


    if (item?.dhcp) {
        if (!item.dhcpValue?.length) {
            return [ t('widgets:network.selector.dhcpUnknown') ];
        }
        return item.dhcpValue.map(item => {
            const addr = netaddr(item.address);
            if (withoutMask && addr.isIp()) {
                return addr.noMask().toString();
            }
            assert(addr.isIp());
            return addr.setMask(item.mask).applyMaskToIp().toString();
        });
    }
    if (isIpv6) {
        return stringifyVal(item.address6);
    }

    return stringifyVal(item.address) || stringifyVal(item.vpnAddress);
};


const localSchemaCanBeNetworkAddress = localSchema => {
    // return !localSchema['x-netaddr']?.cannotBeNetworkAddress; // Original return for testing...
    if (!localSchema || localSchema.cannotBeNetworkAddress) {
        return false;
    }
    return localSchema.mustBeNetworkAddress ||
        localSchema.mask ||
        localSchema.optionalMask;
};

const INTERFACE_DEVICE_STATIC_REFERENCES = [
    STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS,
    STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN,
    STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES,
];

const getInterfaceIcon = item => {
    switch (item?.type) {
    case 'hw': return  'expansion-card-variant';
    case 'vlan': return 'lan';
    case 'bridge': return 'bridge';
    default: return '';
    }
};

export const interfaceDeviceOptions = ({
    networkInterfaces,
    schema,
    options: propsOptions,
    fake,
    wpad,
    guiHTTPS,
    honeypot,
    t,
    exceptions,
    isCluster,
    myNode,
    otherNode
}) => {
    const options: any[] = [];
    if (!schema) {
        return options;
    }
    const allInternalInterfacesAddresses = networkInterfaces.filter(item => item.isInternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: true, isCluster, myNode, otherNode, t })
    ).flat();
    const allInternalInterfacesNetworks = networkInterfaces.filter(item => item.isInternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t })
    ).flat();

    const allExternalInterfacesAddresses = networkInterfaces.filter(item => item.isExternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: true,  isCluster, myNode, otherNode, t })
    ).flat();
    const allExternalInterfacesNetworks = networkInterfaces.filter(item => item.isExternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t })
    ).flat();

    const allInternalIpv6InterfacesAddresses = networkInterfaces.filter(item => item.isInternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: true, isCluster, myNode, otherNode, t,
            isIpv6: true })
    ).flat();
    const allInternalIpv6InterfacesNetworks = networkInterfaces.filter(item => item.isInternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t,
            isIpv6: true })
    ).flat();
    const allExternalIpv6InterfacesAddresses = networkInterfaces.filter(item => item.isExternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: true,  isCluster, myNode, otherNode, t,
            isIpv6: true })
    ).flat();
    const allExternalIpv6InterfacesNetworks = networkInterfaces.filter(item => item.isExternal).map(
        item => getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t,
            isIpv6: true })
    ).flat();
    const resolveAddresses = {
        'isExternal_ipv4_network': allExternalInterfacesNetworks,
        'isExternal_ipv4_address': allExternalInterfacesAddresses,
        'isInternal_ipv4_network': allInternalInterfacesNetworks,
        'isInternal_ipv4_address': allInternalInterfacesAddresses,

        'isExternal_ipv6_network': allExternalIpv6InterfacesNetworks,
        'isExternal_ipv6_address': allExternalIpv6InterfacesAddresses,
        'isInternal_ipv6_network': allInternalIpv6InterfacesNetworks,
        'isInternal_ipv6_address': allInternalIpv6InterfacesAddresses
    };

    const isArray = schema.type === 'array' || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST];
    const localSchema = schema.items || schema;
    const localNetaddr = localSchema[SCHEMA_TYPE_NETADDR] || localSchema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST];
    networkInterfaces.forEach(item => {
        const interfaceNetwork = createAddressesSelectorByIfaceId({
            ifaceId: item.id,
            ipVersion: IPV4,
            addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
        });
        const interfaceAddress = createAddressesSelectorByIfaceId({
            ifaceId: item.id,
            ipVersion: IPV4,
            addressType: isArray ? ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES :
                ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS
        });
        resolveAddresses[addressesSelectorToString(interfaceNetwork)] =
        getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t });
        resolveAddresses[addressesSelectorToString(interfaceAddress)] =
        getAddressFromInterfaceOrVpn({ item, withoutMask: false, isCluster, myNode, otherNode, t });

    });
    networkInterfaces.forEach(item => {
        if ((exceptions || []).includes(item.type)) {
            return;
        }
        if (localSchema[SCHEMA_TYPE_ROW_ID] &&
             !localSchema[SCHEMA_TYPE_ROW_ID].includes(hlcfgTableNameByRowId(item.id))) {
            return;
        }
        if (!localNetaddr) {
            options.push({
                value: item.id,
                label: item.name || item.device?.[NODE_A_ID],
                color: item.color || INTERFACE_COLOR,
                tooltipValues: getAddressFromInterfaceOrVpn(
                    { item, withoutMask: false, isCluster, myNode, otherNode, t }
                ),
                iconName: getInterfaceIcon(item)
            });
        } else {
            if (isArray && localSchemaCanBeNetworkAddress(localNetaddr)) {
                const interfaceNetwork = createAddressesSelectorByIfaceId({
                    ifaceId: item.id,
                    ipVersion: IPV4,
                    addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
                });
                options.push({
                    value: addressesSelectorToString(interfaceNetwork),
                    label: t('widgets:network.selector.network', { interface: item.name }),
                    color: item.color,
                    tooltipValues: getAddressFromInterfaceOrVpn(
                        { item, withoutMask: false, isCluster, myNode, otherNode, t }
                    ),
                    objectValue: interfaceNetwork,
                    iconName: getInterfaceIcon(item)
                });
            }
            if (localNetaddr.ip4 && !localSchema.mustBeNetworkAddress) {
                const interfaceAddress = createAddressesSelectorByIfaceId({
                    ifaceId: item.id,
                    ipVersion: IPV4,
                    addressType: isArray ? ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES :
                        ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS
                });
                options.push({
                    value: addressesSelectorToString(interfaceAddress),
                    label: t(isArray ? 'widgets:network.selector.address' :
                        'widgets:network.selector.primaryAddress', { interface: item.name }),
                    color: item.color,
                    tooltipValues: getAddressFromInterfaceOrVpn({
                        item, withoutMask: true, isCluster, myNode, otherNode, t,
                        primaryAddr: !isArray }),
                    objectValue: interfaceAddress,
                    iconName: getInterfaceIcon(item)
                });
            }
            if (isArray && localSchemaCanBeNetworkAddress(localNetaddr) && localNetaddr.ip6) {
                const interfaceIpv6Network = createAddressesSelectorByIfaceId({
                    ifaceId: item.id,
                    ipVersion: IPV6,
                    addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
                });
                options.push({
                    value: addressesSelectorToString(interfaceIpv6Network),
                    label: t('widgets:network.selector.network6', { interface: item.name }),
                    color: item.color,
                    tooltipValues: getAddressFromInterfaceOrVpn(
                        { item, withoutMask: false, isCluster, myNode, otherNode, t, isIpv6: true }
                    ),
                    objectValue: interfaceIpv6Network,
                    iconName: getInterfaceIcon(item)
                });
            }
            if (localNetaddr.ip6 && !localSchema.mustBeNetworkAddress) {
                const interfaceIpv6Address = createAddressesSelectorByIfaceId({
                    ifaceId: item.id,
                    ipVersion: IPV6,
                    addressType: isArray ? ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES :
                        ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS
                });
                options.push({
                    value: addressesSelectorToString(interfaceIpv6Address),
                    label: t(isArray ? 'widgets:network.selector.address6' :
                        'widgets:network.selector.primaryAddress6', { interface: item.name }),
                    color: item.color,
                    tooltipValues: getAddressFromInterfaceOrVpn({
                        item, withoutMask: true, isCluster, myNode, otherNode, t, primaryAddr: !isArray,
                        isIpv6: true }),
                    objectValue: interfaceIpv6Address,
                    iconName: getInterfaceIcon(item),
                });
            }
        }

    });
    if (isArray) {
        if (localNetaddr) {
            if (localNetaddr.ip6  && !localSchema.mustBeNetworkAddress) {
                const allInternalIpv6Addresses = createAddressesSelectorByIfaceType({
                    ifaceType: 'isInternal',
                    ipVersion: IPV6,
                    addressType: ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES
                });
                options.unshift({
                    label: t('widgets:global.allInternal.address6'),
                    value: addressesSelectorToString(allInternalIpv6Addresses),
                    tooltipValues: allInternalIpv6InterfacesAddresses,
                    objectValue: allInternalIpv6Addresses
                });
            }
            if (localSchemaCanBeNetworkAddress(localNetaddr) && localNetaddr.ip6) {
                const allInternalIpv6Networks = createAddressesSelectorByIfaceType({
                    ifaceType: 'isInternal',
                    ipVersion: IPV6,
                    addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
                });
                options.unshift({
                    label: t('widgets:global.allInternal.network6'),
                    value: addressesSelectorToString(allInternalIpv6Networks),
                    tooltipValues: allInternalIpv6InterfacesNetworks,
                    objectValue: allInternalIpv6Networks
                });
            }
            if (localNetaddr.ip6 && !localSchema.mustBeNetworkAddress) {
                const allExternalIpv6Addresses = createAddressesSelectorByIfaceType({
                    ifaceType: 'isExternal',
                    ipVersion: IPV6,
                    addressType: ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES,
                });
                options.unshift({
                    label: t('widgets:global.allExternal.address6'),
                    value: addressesSelectorToString(allExternalIpv6Addresses),
                    tooltipValues: allExternalIpv6InterfacesAddresses,
                    objectValue: allExternalIpv6Addresses
                });
            }
            if (localSchemaCanBeNetworkAddress(localNetaddr) && localNetaddr.ip6) {
                const allExternalIpv6Networks = createAddressesSelectorByIfaceType({
                    ifaceType: 'isExternal',
                    ipVersion: IPV6,
                    addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
                });
                options.unshift({
                    label: t('widgets:global.allExternal.network6'),
                    value: addressesSelectorToString(allExternalIpv6Networks),
                    tooltipValues: allExternalIpv6InterfacesNetworks,
                    objectValue: allExternalIpv6Networks
                });
            }
        }
        if (localNetaddr && !localSchema.mustBeNetworkAddress) {
            const allInternalAddresses = createAddressesSelectorByIfaceType({
                ifaceType: 'isInternal',
                ipVersion: IPV4,
                addressType: ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES
            });
            options.unshift({
                label: t('widgets:global.allInternal.address'),
                value: addressesSelectorToString(allInternalAddresses),
                tooltipValues: allInternalInterfacesAddresses,
                objectValue: allInternalAddresses
            });
        }
        if (localSchemaCanBeNetworkAddress(localNetaddr)) {
            const allInternalNetwork = createAddressesSelectorByIfaceType({
                ifaceType: 'isInternal',
                ipVersion: IPV4,
                addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
            });
            options.unshift({
                label: t('widgets:global.allInternal.network'),
                value: addressesSelectorToString(allInternalNetwork),
                tooltipValues: allInternalInterfacesNetworks,
                objectValue: allInternalNetwork
            });
        }
        if (localNetaddr && !localSchema.mustBeNetworkAddress) {
            const allExternalAddress = createAddressesSelectorByIfaceType({
                ifaceType: 'isExternal',
                ipVersion: IPV4,
                addressType: ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES
            });
            options.unshift({
                label: t('widgets:global.allExternal.address'),
                value: addressesSelectorToString(allExternalAddress),
                tooltipValues: allExternalInterfacesAddresses,
                objectValue: allExternalAddress
            });
        }
        if (localSchemaCanBeNetworkAddress(localNetaddr)) {
            const allExternalNetwork = createAddressesSelectorByIfaceType({
                ifaceType: 'isExternal',
                ipVersion: IPV4,
                addressType: ADDRESSES_SELECTOR_TYPE_NETWORK
            });
            options.unshift({
                label: t('widgets:global.allExternal.network'),
                value: addressesSelectorToString(allExternalNetwork),
                tooltipValues: allExternalInterfacesNetworks,
                objectValue: allExternalNetwork
            });
        }
    }

    if (fake) {
        INTERFACE_DEVICE_STATIC_REFERENCES.forEach(item => {
            options.push({
                label: t(`packetFilter:staticRef.${item}.title`),
                value: item,
                tooltipValues: getTooltipForStaticReferences({
                    type: item,
                    honeypot,
                    wpad,
                    guiHTTPS,
                    resolveAddresses,
                    t
                })
            });
        });

    }

    const ifaceOptions = [ {
        label: t('widgets:network.interfaces'),
        options: options
    } ];
    return propsOptions ? propsOptions.concat(ifaceOptions) : ifaceOptions;
};


const getTooltipForStaticReferences = ({
    type,
    wpad,
    guiHTTPS,
    honeypot,
    resolveAddresses, t }) => {

    const resolveOrPlaceholder = (addresses, schema) => {
        if (addresses?.length) {
            return resolveAddressesToTooltip({
                array: stringifyAddress(addresses),
                resolveAddresses
            });
        } else {
            return [ t(schema?.[PLACEHOLDER_SCHEMA_VALUE]) ];
        }
    };
    switch (type) {
    case STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS:
        return resolveOrPlaceholder(guiHTTPS?.addresses, guiHTTPS?.schema);
    case STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN:
        return resolveOrPlaceholder(wpad?.addresses, wpad?.schema);
    case STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES:
        return resolveOrPlaceholder(honeypot?.addresses, honeypot?.schema);
    default: {
        throw new Error('Unknown static reference: ' + type);
    }

    }
};

const resolveAddressesToTooltip = ({
    array,
    resolveAddresses
}) => {
    return (array || []).map(item => {
        if (resolveAddresses[item]) {
            return resolveAddresses[item];
        }
        return item;
    }).flat();
};
