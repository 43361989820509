/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { INITIAL_DEPLOYMENT_MODE } from '~sharedConstants/index.ts';
import { createScene } from '~frontendLib/scenes.js';
import { createLazy } from '~frontendLib/createLazy.tsx';


const Dashboard = createLazy(() => import('./Dashboard/index.js'));
const ProtectionCharts = createLazy(() => import('./ProtectionCharts/index.js'));
const ServiceCharts = createLazy(() => import('./ServiceCharts/index.js'));
const SystemCharts = createLazy(() => import('./SystemCharts/index.js'));
const SystemLogs = createLazy(() => import('./SystemLogs/index.ts'));


const sceneDashboard = createScene({
    path: '/monitoring/traffic/dashboard',
    component: Dashboard,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.scenes.Dashboard.title',
});

const sceneProtection = createScene({
    path: '/monitoring/traffic/protectioncharts',
    component: ProtectionCharts,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.scenes.Protectioncharts.title',
});

const sceneServices = createScene({
    path: '/monitoring/traffic/servicescharts',
    component: ServiceCharts,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.scenes.Servicescharts.title',
});

const sceneSystem = createScene({
    path: '/monitoring/traffic/systemcharts',
    component: SystemCharts,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.scenes.Systemcharts.title',
});

const sceneSystemLogs = createScene({
    path: '/monitoring/traffic/systemlogs',
    component: SystemLogs,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.scenes.Systemlogs.title',
});


const scenes = ({ deployment, isProductionMode } = {}) => {
    switch (deployment) {
    case INITIAL_DEPLOYMENT_MODE:
        return [];
    default:
        return [
            isProductionMode ? null : sceneDashboard,
            sceneServices,
            sceneProtection,
            sceneSystem,
            sceneSystemLogs,
        ].filter(Boolean);
    }
};

export default scenes;
