/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createReducer, createSelector } from '@reduxjs/toolkit';


const SET_SHOW_HIDDEN = 'ak/networkInterfaces/SET_SHOW_HIDDEN';


// initial state
export const initialState = {
    showHidden: false,
};

// reducer
const reducer = createReducer(initialState, {
    [SET_SHOW_HIDDEN]: (state, action) => {
        state.showHidden = action.payload;
    },

});
export default reducer;

export const getShowHidden = createSelector(
    (state) => state.interfaces.showHidden,
    (showHidden) => showHidden
);


// action creators

export const setShowHidden = (payload) =>
    ({ type: SET_SHOW_HIDDEN, payload });
