/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
const ACTION_SEQUENCES_REQUEST = 'ak/actionSequenceReplayList/ACTION_SEQUENCES_REQUEST';
const ACTION_SEQUENCES_SUCCESS = 'ak/actionSequenceReplayList/ACTION_SEQUENCES_SUCCESS';
const ACTION_SEQUENCES_FAILURE = 'ak/actionSequenceReplayList/ACTION_SEQUENCES_FAILURE';


// initial state
const initialActionSequenceState = {
    isLoading: false,
    loadingError: null,
    data: [],
};

const initialState = {};


// reducer
const reduceActionSequence = (actionSequenceState = initialActionSequenceState, action) => {
    switch (action.type) {
    case ACTION_SEQUENCES_REQUEST:
        return { ...actionSequenceState, isLoading: true };
    case ACTION_SEQUENCES_SUCCESS:
        return { ...actionSequenceState, isLoading: false, loadingError: action.payload };
    case ACTION_SEQUENCES_FAILURE:
        return { ...actionSequenceState, isLoading: false, loadingError: null, data: action.payload };
    default: return actionSequenceState;
    }
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case ACTION_SEQUENCES_REQUEST:
    case ACTION_SEQUENCES_SUCCESS:
    case ACTION_SEQUENCES_FAILURE:
        return {
            ...state,
            [action.actionSequenceType]: reduceActionSequence(state[action.actionSequenceType], action),
        };
    default:
        return state;
    }
};
export default reducer;


// data accessors
const getState = rootState => rootState.actionSequenceReplayList;

const getActionSequenceData = (rootState, actionSequenceType) =>
    getState(rootState)[actionSequenceType] || initialActionSequenceState;

export const getIsLoading = (rootState, actionSequenceType) =>
    getActionSequenceData(rootState, actionSequenceType).isLoading;

export const getLoadingError = (rootState, actionSequenceType) =>
    getActionSequenceData(rootState, actionSequenceType).loadingError;

export const getData = (rootState, actionSequenceType) =>
    getActionSequenceData(rootState, actionSequenceType).data;


// action creators
export const actionSequencesRequest = (actionSequenceType) =>
    ({ type: ACTION_SEQUENCES_REQUEST, actionSequenceType });

export const actionSequencesSuccess = (actionSequenceType, data) =>
    ({ type: ACTION_SEQUENCES_SUCCESS, actionSequenceType, payload: data });

export const actionSequencesFailure = (actionSequenceType, error) =>
    ({ type: ACTION_SEQUENCES_FAILURE, actionSequenceType, payload: error });


// API endpoints


// side effects
export const sagas = [];
