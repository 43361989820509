/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const charts = {};

charts.MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 31);

charts.ROWS_PER_PAGE_OPTIONS = [ 10, 20, 50, 100 ];
const isInOfflineMode = false;
if (isInOfflineMode) {
    charts.ROWS_PER_PAGE_OPTIONS.push(charts.MAX_SAFE_INTEGER);
}
charts.ROWS_PER_PAGE_INITIAL = 10;


charts.toFixed = (number, precision) => {
    return Math.round(Math.pow(10, precision) * (number + Number.EPSILON)) / Math.pow(10, precision);
};

export default charts;
