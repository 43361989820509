/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

let objectI18n;

const REGEX_OBJECT = /^key '.*' returned an object instead of string.$/;

const internationalize = (string, options) =>
    objectI18n ?
        objectI18n.translator.translate(string, options) :
        string;

/**
 * String internationalizator.
 *
 * @param {string} string - identifier of the string to be localized
 * @param {object} [options] - options, see * {@link https://github.com/i18next/ng-i18next}
 * @param {*} [whenNotTranslated] - when given, its value is returned when the translation does not exist
 * @returns {string}
 */
const tFilter = (string, options, whenNotTranslated) => {
    const translated = internationalize(string, options);
    if (REGEX_OBJECT.test(translated)) {
        return '';
    }
    if (typeof whenNotTranslated !== 'undefined' && string.endsWith(translated)) {
        return whenNotTranslated;
    }
    return translated;
};

export const localizeTFilter = instanceI18n => {
    objectI18n = instanceI18n;
};

export default tFilter;
