/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const modals = {};

modals.chartPointClick = (chartObject, row, filterPlacement, filtersToAppendTo, additionalFilters) => {
    console.log( // eslint-disable-line no-console
        'TODO',
        'modals.chartPointClick',
        'chartObject', chartObject,
        'row', row,
        'filterPlacement', filterPlacement,
        'filtersToAppendTo', filtersToAppendTo,
        'additionalFilters', additionalFilters
    );
};

modals.confirm = (params) => {
    console.log('TODO', 'modals.confirm', 'params', params); // eslint-disable-line no-console
    return {
        cancelled: () => {}
    };
};

export default modals;
