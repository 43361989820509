/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/index.js';
import { CWDB_LAST_SUCCESS_AGE_ERROR, CWDB_LAST_SUCCESS_AGE_WARNING } from '~frontendRoot/constants/index.js';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';

// constants
const minutes = 60; // number of seconds in a minute

/** How long ago (in seconds) can suricata rules be successfully updated to mean an error. */
export const SURICATA_RULES_LAST_SUCCESS_AGE_ERROR = 60 * minutes;

/** How long ago (in seconds) can suricata rules be successfully updated to mean a warning. */
export const SURICATA_RULES_LAST_SUCCESS_AGE_WARNING = 20 * minutes;

// actions
const DATABASE_INFO_REQUEST = 'ak/database/PROTECTION_DATABASE_INFO_REQUEST';
const DATABASE_INFO_REQUEST_ERROR = 'ak/database/PROTECTION_DATABASE_INFO_REQUEST_ERROR';
const DATABASE_INFO_REQUEST_SUCCESS = 'ak/database/PROTECTION_DATABASE_INFO_REQUEST_SUCESS';


// initial state
export const initialState = {
    databases: {
        cwdb: {},
        adaptiveFirewall: {},
        suricataRules: {}
    },
    error: {},
    loading: false,
};


// reducer
export default (state = initialState, action) => {
    switch (action.type) {
    case DATABASE_INFO_REQUEST:
        return {
            ...state,
            error: {},
            loading: true
        };
    case DATABASE_INFO_REQUEST_SUCCESS:
        return {
            ...state,
            databases: {
                ...state.databases,
                ...action.payload,
            },
            error: {},
            loading: false
        };
    case DATABASE_INFO_REQUEST_ERROR:
        return {
            ...state,
            error: action.payload,
            loading: false
        };
    default:
        return state;
    }
};


const getRootState = state => state.protectionDatabases;


export const getCwdbDatabaseInfo = state => getRootState(state).databases?.cwdb;
export const getCwdbState = state => {
    const timestamps = getCwdbDatabaseInfo(state);
    const loading = getDatabaseLoading(state);
    if (!timestamps) {
        return '';
    }
    const serverTime = getCurrentServerTimestamp(state);
    const timestampToCheck = timestamps.lastUpdate || timestamps.dbVersion;
    switch (true) {
    case !timestampToCheck && !loading:
        return 'info';
    case serverTime?.diff(timestampToCheck, 'seconds') >= CWDB_LAST_SUCCESS_AGE_ERROR:
        return 'error';
    case serverTime?.diff(timestampToCheck, 'seconds') > CWDB_LAST_SUCCESS_AGE_WARNING:
        return 'warning';
    case serverTime?.diff(timestampToCheck, 'seconds') <= CWDB_LAST_SUCCESS_AGE_WARNING:
        return 'success';
    default:
        return '';
    }
};

export const getSuricataDatabaseInfo = state =>
    getRootState(state).databases?.suricataRules || EMPTY_IMMUTABLE_OBJ;

export const getSuricataRulesUpdateState = state => {
    const timestamps = getSuricataDatabaseInfo(state);
    if (!timestamps) {
        return '';
    }
    const serverTime =  getCurrentServerTimestamp(state);
    const timestampToCheck = timestamps?.updatedTimestamp;
    switch (true) {
    case !timestampToCheck:
        return 'error';
    case serverTime.diff(timestampToCheck, 'seconds') >= SURICATA_RULES_LAST_SUCCESS_AGE_ERROR:
        return 'error';
    case serverTime.diff(timestampToCheck, 'seconds') > SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
        return 'warning';
    case serverTime.diff(timestampToCheck, 'seconds') <= SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
        return 'success';
    default:
        return '';
    }
};

export const getAdaptiveFirewallDatabaseInfo = state =>
    getRootState(state).databases?.adaptiveFirewall || EMPTY_IMMUTABLE_OBJ;

export const getAdaptiveFirewallRulesUpdateState = state => {
    const timestamps = getAdaptiveFirewallDatabaseInfo(state);
    if (!timestamps) {
        return '';
    }
    const serverTime =  getCurrentServerTimestamp(state);
    const timestampToCheck = timestamps.lastUpdate;
    switch (true) {
    case !timestampToCheck:
        return 'error';
    case serverTime.diff(timestampToCheck, 'seconds') >= SURICATA_RULES_LAST_SUCCESS_AGE_ERROR:
        return 'error';
    case serverTime.diff(timestampToCheck, 'seconds') > SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
        return 'warning';
    case serverTime.diff(timestampToCheck, 'seconds') <= SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
        return 'success';
    default:
        return '';
    }
};
export const getDatabaseLoading = state => getRootState(state).loading;
export const getDatabaseError = state => getRootState(state).error;


// action creators

export const databaseInfoRequest = () =>
    ({ type: DATABASE_INFO_REQUEST, });

export const databaseInfoRequestError = (payload) =>
    ({ type: DATABASE_INFO_REQUEST_ERROR, payload });

export const databaseInfoRequestSuccess = (payload) =>
    ({ type: DATABASE_INFO_REQUEST_SUCCESS, payload });


// API endpoints

export const apiCallGetDatabaseInfo = async () => {
    return axios.get('/api/utils/getUpdatesStatus');
};


// side effects
const workerDatabaseInfo = function* () {
    try {
        const { data } = yield call(apiCallGetDatabaseInfo);
        yield put(databaseInfoRequestSuccess(data));

    } catch (error) {
        yield put(databaseInfoRequestError(getApiError(error)));
    }
};


export const sagas = [
    takeLatest(DATABASE_INFO_REQUEST, workerDatabaseInfo),
];
