/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createReducer, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import { call, put, takeLatest, delay } from '~commonLib/reduxSagaEffects.ts';
import { TIME_FORMAT } from '~commonLib/moment.ts';
import {
    DHCPD_RULE_COLUMNS_DRAG,
    DHCPD_RULE_COLUMNS_MAC,
    DHCPD_RULE_COLUMNS_ADDRESS,
    DHCPD_RULE_COLUMNS_MENU,
    DHCPD_RULE_COLUMNS_ENABLED,
    DHCPD_RULE_COLUMNS_RANGEFROM,
    DHCPD_RULE_COLUMNS_RANGETO,
    DHCPD_RULE_COLUMNS_COMMENT,
    DHCPD_RULE_COLUMNS_HOSTNAME,
    DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS,
    DHCPD_RULE_COLUMNS_ACTUAL_MAC
} from '~frontendConstants/constants.ts';
import { getApiError } from '~frontendRoot/lib/apiUtils.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';


// actions
export const SET_COLUMNS = 'ak/dhcpd/SET_COLUMNS';
export const SET_DATA_SUCCESS = 'ak/dhcpd/SET_DATA_SUCCESS';
export const SET_DATA_REQUEST = 'ak/dhcpd/SET_DATA_REQUEST';
export const SET_DATA_ERROR = 'ak/dhcpd/SET_DATA_ERROR';


// initial state
export const initialState = {
    leases: [ //ORDER MATTERS
        { title: DHCPD_RULE_COLUMNS_DRAG,  selected: true, hideName: true  },
        { title: DHCPD_RULE_COLUMNS_ADDRESS,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_MAC,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_COMMENT,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_ENABLED,  selected: true, hideName: true, },
        { title: DHCPD_RULE_COLUMNS_MENU,  selected: true, hideName: true, disabled: true, },
    ],
    pools: [ //ORDER MATTERS
        { title: DHCPD_RULE_COLUMNS_DRAG,  selected: true, hideName: true  },
        { title: DHCPD_RULE_COLUMNS_RANGEFROM,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_RANGETO,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_COMMENT,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_ENABLED,  selected: true, hideName: true, },
        { title: DHCPD_RULE_COLUMNS_MENU,  selected: true, hideName: true, disabled: true, },
    ],
    actualLeases: [
        { title: DHCPD_RULE_COLUMNS_DRAG,  selected: true, hideName: true  },
        { title: DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_ACTUAL_MAC,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_HOSTNAME,  selected: true, hideName: false, },
        { title: DHCPD_RULE_COLUMNS_ENABLED,  selected: true, hideName: true, },
        { title: DHCPD_RULE_COLUMNS_MENU,  selected: true, hideName: true, disabled: true, },
    ],

    data: {},
    loading: false,
    error: null,
};

// reducer
const reducer = createReducer(initialState, {
    [SET_COLUMNS]: (state, action) => {
        state[action.type].forEach(item => {
            if (`${item.title}selected`  === action.payload || item.title  === action.payload) {
                item.selected = action.value !== undefined ? action.value : !item.selected;
            }
        });
    },
    [SET_DATA_SUCCESS]: (state, action) => {
        state.data = action.payload;
        state.loading = false;
    },
    [SET_DATA_REQUEST]: (state) => {
        state.loading = true;
    },
    [SET_DATA_ERROR]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },

});
export default reducer;

export const getIsLoading = (state) => state.dhcpd.loading;

export const getColumns = createSelector(
    (state, type) => state.dhcpd[type],
    columns => columns
);
export const getColumnsHeaders = createSelector(
    (state, type) => state.dhcpd[type],
    (selected) => selected.filter(item => item.selected)
);

export const getDhcpdData = () => {
    return createSelector([
        (state, uuid) => state.dhcpd.data[uuid],
    ], (dhcpLeases) =>  {
        if (!dhcpLeases) {
            return EMPTY_IMMUTABLE_ARR;
        }
        return dhcpLeases.map(item => ({
            ip: [ item.ip ],
            mac: [ item.mac ],
            hostname: item.hostname ? [ item.hostname ] : [],
            __off: true,
            begin: moment(item.begin).format(TIME_FORMAT.userDateTimeFull),
            end: moment(item.end).format(TIME_FORMAT.userDateTimeFull)
        }));
    });
};


// action creators

export const setColumns = (payload, value) =>
    ({ type: SET_COLUMNS, payload, value });

export const setDataSuccess = (payload) =>
    ({ type: SET_DATA_SUCCESS, payload });

export const setDataRequest = () =>
    ({ type: SET_DATA_REQUEST });

export const setDataError = (payload) =>
    ({ type: SET_DATA_ERROR, payload });

export const getApiDhcpdLease = async () => {
    return axios.get('/api/dhcpServer/leasesBySubnet');
};

const getDhcpdLease = function* () {
    try {
        const { data } = yield call(getApiDhcpdLease);
        yield put(setDataSuccess(data));
        yield delay(60000);
        yield put(setDataRequest());
    } catch (error) {
        yield put(setDataError(getApiError(error)));
    }
};


export const sagas = [
    takeLatest(SET_DATA_REQUEST, getDhcpdLease)
];
