/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { typeApiError } from './types.js';


export const typeSystemComponentActions = PropTypes.objectOf(PropTypes.bool);

export const typeSystemComponentStatus = PropTypes.exact({
    code: PropTypes.number.isRequired,
    stderr: PropTypes.string.isRequired,
    stdout: PropTypes.string.isRequired,
    additionalInformation: PropTypes.any, // information specific to a system component
});

export const typeSystemComponents = PropTypes.exact({
    isLoading: PropTypes.bool,
    error: typeApiError,
    data: PropTypes.objectOf(
        PropTypes.exact({
            actions: typeSystemComponentActions.isRequired,
            componentType: PropTypes.string.isRequired,
            componentId: PropTypes.string,
            status: typeSystemComponentStatus.isRequired,
        })
    ),
});
