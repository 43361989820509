/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBDropdownItem, MDBWaves } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { collapseNavBar, getIsCollapsed, getIsTempOpen, tempCloseNavBar, tempOpenNavBar, unCollapseNavBar } from '~frontendDucks/layout/ducks/navBarLeft.js';


@connect(
    state => ({
        isCollapsed: getIsCollapsed(state),
        isTempOpen: getIsTempOpen(state),
    }),
    {
        doCollapseNavBar: collapseNavBar,
        doUnCollapseNavBar: unCollapseNavBar,
        doTempOpenNavBar: tempOpenNavBar,
        doTempCloseNavBar: tempCloseNavBar,
    },
) class MenuDropdownItem extends Component {
    static get propTypes() {
        return {
            active: PropTypes.bool,
            href: PropTypes.string,
            className: PropTypes.string,
            icon: PropTypes.string,
            message: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
            children: PropTypes.node,
            doTempCloseNavBar: PropTypes.func,
            onClick: PropTypes.func,
            isTempOpen: PropTypes.bool,
            iconClassName: PropTypes.string,
            routeKey: PropTypes.string,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            cursorPos: {}
        };
    }

    handleClick = (event) => {
        // Waves - Get Cursor Position
        const cursorPos = {
            top: event.clientY,
            left: event.clientX,
            time: Date.now() // time indicates particular clicks
        };

        this.setState({ cursorPos: cursorPos });
    };

    dropdownItemClick = (event) => {
        event.stopPropagation();
        const {
            doTempCloseNavBar, isTempOpen, onClick
        } = this.props;
        if (onClick) {
            onClick();
        }
        if (isTempOpen) {
            doTempCloseNavBar();
        }
    };

    render() {
        const { icon, message, children, className, active, href, iconClassName, routeKey } = this.props;
        const Tag = href ? Link : MDBDropdownItem;
        return (
            <div
                className={classNames({
                    'dropdown-item__wrap': true,
                    'dropdown-item__wrap--active': active
                })}
            >

                <Tag
                    active={active}
                    className={classNames(
                        {
                            'waves-effect': true,
                            'with-icon': icon,
                        },
                        className,
                    )}
                    data-cy={routeKey}
                    onClick={this.dropdownItemClick}
                    onMouseUp={this.handleClick}
                    onTouchStart={this.handleClick}
                    to={href}
                    toggle={false}
                >
                    {icon ?
                        <Icon
                            className={iconClassName}
                            name={icon}
                            size="sm"
                        /> :
                        null
                    }
                    {message ?
                        typeof message === 'object' ?
                            message :
                            <span><Message message={message} /></span> :
                        null
                    }
                    {children}
                    <MDBWaves cursorPos={this.state.cursorPos} />
                </Tag>
            </div>
        );
    }
}

export default MenuDropdownItem;
