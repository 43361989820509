/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { UseStringFuncType } from '~frontendLib/hooks/defaultHooks.ts';

import Input from '../Input/Input.js';


type InputSearchType = {
    className?: string
    id: string
    setter: UseStringFuncType
    search: string
}

/**
 * Use this component only with useString hook as setter
 */
const InputSearch = ({ className, id, setter, search }: InputSearchType) => {

    const { t } = useTranslation();

    return (
        <Input
            className={className}
            iconName={search ? null : 'magnify'}
            id={id}
            important={search}
            name="searchValue"
            onChange={setter.inputSet}
            placeholder={t('widgets:global.search')}
            value={search}
        />
    );
};

export default InputSearch;
